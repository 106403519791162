import { FC, useMemo } from 'react';

import { Box } from '@mui/material';

import Slider from 'react-rangeslider';

import { useStores } from 'mobx/hooks/useStores';

import { useSymptomsAlertModal } from 'hooks/useSymptomsAlertModal';

import { SymptomsAlertFormFields } from 'views/Modals/SymptomsAlertModals/SymptomsAlertModal.types';

import {
  conditionTypeOptions,
  contactMethodOptions,
  populationTypeOptions,
  severityOptions
} from 'views/Modals/SymptomsAlertModals/SymptomsAlertModals.constants';

import { formatAlert } from 'views/Modals/SymptomsAlertModals/SymptomsAlertModals.utils';

import { FormExclusiveToggleButton } from 'components/UIkit/atoms/Button';
import { FormMultiAutocomplete, FormSelect } from 'components/UIkit/atoms/Dropdown';
import { FormSpecificPatientsAsyncMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/SpecificPatientsAsyncMultiAutocomplete';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export const CreateSymptomsAlertModal: FC<Props> = ({ isOpen, closeModal }) => {
  const { constantsStore, alertsStore } = useStores();

  const {
    isLoading,
    setIsLoading,
    formMethods,
    conditionalFieldsVisibleState,
    loadOptions,
    handleSpecificPatientsSearched,
    isSpecificPatientsSelectMenuOpen,
    handleConditionTypeChanged,
    distressLevelField,
    analyticsEvents
  } = useSymptomsAlertModal('add rule');

  const {
    isDistressSliderVisible,
    isIssuesDropdownVisible,
    isSearchPatientsVisible,
    isThresholdToggleButtonVisible
  } = conditionalFieldsVisibleState;

  const { handleSubmit, setValue, formState } = formMethods;

  const onSubmit = async (formValues: SymptomsAlertFormFields) => {
    setIsLoading(true);
    analyticsEvents.trackSaveAlert();
    const newAlert = formatAlert(formValues);

    try {
      await alertsStore.submitNewClinicianAlert(newAlert);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const options = useMemo(() => {
    return constantsStore.symptomsMap.items
      .filter((symptom) => !symptom.isDeleted)
      .map((symptom) => {
        return {
          value: symptom.id,
          label: symptom.name
        };
      });
  }, [constantsStore.symptomsMap]);

  return (
    <FormModal
      defaultValues={null}
      methods={formMethods}
      isOpen={isOpen}
      title="New Rule"
      confirmActions={[
        {
          onClick: handleSubmit(onSubmit),
          text: 'Save',
          disabled: !formState.isValid || isLoading
        }
      ]}
      closeAction={{
        onClick: () => {
          analyticsEvents.trackCancelChanges();
          closeModal();
        },
        disabled: isLoading
      }}
      resetDataAfterClose={() => setIsLoading(false)}
    >
      <Box mb={20}>
        <FormSelect
          placeholder="Select which patients this rule applies to"
          name="populationType"
          testHook="rule-applies-to-select"
          options={populationTypeOptions}
          label="Rule Applies to:"
          isRequired
          onChange={(_, actionMeta, eventKey) => {
            setValue('selectedPatients', []);
            analyticsEvents.trackPopulationType(actionMeta, eventKey);
          }}
        />
      </Box>

      {isSearchPatientsVisible && (
        <Box mb={20}>
          <FormSpecificPatientsAsyncMultiAutocomplete
            name="selectedPatients"
            loadOptions={loadOptions}
            placeholder="Search by Name, MRN, or DOB."
            openMenuOnClick={false}
            menuIsOpen={isSpecificPatientsSelectMenuOpen}
            isRequired={isSearchPatientsVisible}
            onInputChange={handleSpecificPatientsSearched}
            onChange={(_, actionMeta, eventKey) =>
              analyticsEvents.trackSpecificPatients(actionMeta, eventKey)
            }
          />
        </Box>
      )}

      <Box mb={20}>
        <FormSelect
          placeholder="Select urgency"
          name="contactMethod"
          testHook="urgency-select"
          options={contactMethodOptions}
          sortAlphabetically={false}
          label="Urgency"
          isRequired
          onChange={(_, actionMeta, eventKey) => analyticsEvents.trackUrgency(actionMeta, eventKey)}
        />
      </Box>

      <Box mb={20}>
        <FormSelect
          placeholder="Select trigger type"
          name="conditionType"
          testHook="trigger-type-select"
          options={conditionTypeOptions}
          label="Trigger Type"
          isRequired
          onChange={(_, actionMeta, eventKey) => {
            handleConditionTypeChanged();
            analyticsEvents.trackTriggerType(actionMeta, eventKey);
          }}
        />
      </Box>

      {isDistressSliderVisible && (
        <Box mb={40} mt={28} px={2}>
          <Slider
            min={0}
            max={10}
            step={1}
            onChange={distressLevelField.onChange}
            onChangeComplete={analyticsEvents.trackDistressLevel}
            value={distressLevelField.value}
          />
        </Box>
      )}

      {isIssuesDropdownVisible && (
        <Box mb={20}>
          <FormMultiAutocomplete
            displaySelectedSummary={false}
            name="selectedCauses"
            options={options}
            isRequired={isIssuesDropdownVisible}
            placeholder="Search by Issue"
            onChange={(_, actionMeta, eventKey) =>
              analyticsEvents.trackCauses(actionMeta, eventKey)
            }
          />
        </Box>
      )}

      {isThresholdToggleButtonVisible && (
        <Box mb={40} width="65%">
          <FormExclusiveToggleButton
            options={severityOptions}
            value={1}
            label="Threshold"
            fullWidth
            name="threshold"
            disabled={false}
            required={isThresholdToggleButtonVisible}
            onChange={analyticsEvents.trackThreshold}
          />
        </Box>
      )}
    </FormModal>
  );
};
