// @ts-strict-ignore
import { observable, makeObservable } from 'mobx';

export enum CauseSeverity {
  Mild = 1,
  Moderate = 2,
  Severe = 3,
  Unbearable = 4
}

export enum ReportCauseChange {
  NEW = 'NEW',
  UP = 'UP',
  DOWN = 'DOWN',
  NO_CHANGE = 'NO_CHANGE',
  FIRST_REPORT = 'FIRST_REPORT'
}

export const SeveritiesText = {
  [CauseSeverity.Mild]: 'Mild',
  [CauseSeverity.Moderate]: 'Moderate',
  [CauseSeverity.Severe]: 'Severe',
  [CauseSeverity.Unbearable]: 'Unbearable'
};

export default class ReportedCause {
  @observable causeId: number;

  @observable severity: number;

  @observable change?: ReportCauseChange;

  constructor(id: number, severity: number = null) {
    makeObservable(this);
    this.causeId = id;
    this.severity = severity;
  }

  static getSeverityText(severity: CauseSeverity) {
    return SeveritiesText[severity];
  }
}
