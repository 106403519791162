import React, { FC, useState } from 'react';

import { observer } from 'mobx-react';
import { useMount } from 'react-use';

import Routes from 'Routes';

import ScrollToTop from 'ScrollToTop';

import { useStores } from 'mobx/hooks/useStores';

import { GeneralErrorPopup } from 'views/Modals/GeneralErrorPopup';

import Loading from 'components/Loaders/Loading';

const App: FC = () => {
  const { userStore, uiStore, constantsStore } = useStores();
  const [isAuthenticatingSession, setIsAuthenticatingSession] = useState(true);
  const { error } = uiStore;

  //if this fails:
  //  1. server return 401
  //  2. user will be redirected the login client (rootStore -> initServices -> initErrorHandling -> onLogout)
  useMount(function init() {
    const fetchCurrentClinicianProfile = async () => {
      try {
        await userStore.setupAfterAuthentication();
      } finally {
        setIsAuthenticatingSession(false);
      }
    };

    fetchCurrentClinicianProfile();
  });

  //in case one of the system load's requests was failed
  if (!constantsStore.initialConstantsLoaded && Boolean(error)) {
    return <GeneralErrorPopup />;
  }

  if (isAuthenticatingSession || !constantsStore.initialConstantsLoaded) {
    return <Loading />;
  }

  return (
    <ScrollToTop>
      <Routes />
      <GeneralErrorPopup />
    </ScrollToTop>
  );
};

export default observer(App);
