// @ts-strict-ignore
import { FC } from 'react';

import { Popups } from 'analytics/events/popup';

import { Popup } from 'views/Modals/Popup';
import { useDeleteTicketTypeOrSubType } from 'views/Pages/PracticeManagement/TicketTypes/useDeleteTicketTypeOrSubType';

interface Props {
  ticketSubTypeId: number;
  onAction: () => void;
  onCancel: () => void;
  isOpen: boolean;
  isChildModal?: boolean;
}

export const DeleteTicketSubTypePopup: FC<Props> = ({
  ticketSubTypeId,
  onAction,
  onCancel,
  isOpen,
  isChildModal = false
}) => {
  const { deleteTicketSubType, ticketSubType } = useDeleteTicketTypeOrSubType(
    ticketSubTypeId,
    onAction
  );

  return (
    <Popup
      isOpen={isOpen}
      title={`Delete ${ticketSubType?.name ? `"${ticketSubType?.name}"?` : ''}`}
      description={
        <>
          It will no longer be possible to create new tickets with this ticket type. Tickets that
          have already been created with this ticket type will not be deleted.
        </>
      }
      action={{ actionText: 'Delete', actionCallback: deleteTicketSubType }}
      onCancelClicked={onCancel}
      testHook={`${ticketSubTypeId}${isChildModal ? 'child_modal' : ''}`}
      id={Popups.DeleteTicketSubType}
    />
  );
};
