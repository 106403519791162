import { FC } from 'react';

import { StyledHtmlContentContainer } from 'utils/html.styles';

interface Props {
  children: string;
}

export const HtmlContent: FC<Props> = ({ children }) => (
  <StyledHtmlContentContainer
    dangerouslySetInnerHTML={{
      __html: children
    }}
  />
);
