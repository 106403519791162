import { FC } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Controller } from 'react-hook-form';

import { DAYS_OF_WEEK_ARRAY } from 'utils/DateUtils';

import { ExclusiveToggleButton, MultiToggleButton } from 'components/UIkit/atoms/Button';

import './RHFWeekdaySelector.scss';

interface RFHWeekdaySelectorProps {
  control: any;
  error: boolean;
  className?: string;
  name: string;
  forceSingleDaySelection?: boolean;
}

const optionsForToggle = DAYS_OF_WEEK_ARRAY.map((day, index) => ({ value: index, label: day }));

const RFHWeekdaySelector: FC<RFHWeekdaySelectorProps> = (props) => (
  <Controller
    control={props.control}
    name={props.name}
    defaultValue={[]}
    rules={{
      validate: (value) => {
        if (props.forceSingleDaySelection) {
          return true;
        }

        return Boolean(value.length);
      }
    }}
    render={({ field }) => (
      <div className={classNames('weekday-selector', props.className)}>
        <p className="day-picker-title">Select Day{props.forceSingleDaySelection ? '' : 's'}:</p>
        {props.forceSingleDaySelection ? (
          <ExclusiveToggleButton
            options={optionsForToggle}
            onChange={field.onChange}
            value={field.value?.length ? field.value[0] : field.value}
            fullWidth
          />
        ) : (
          <MultiToggleButton
            options={optionsForToggle}
            onChange={field.onChange}
            value={field.value}
            fullWidth
            isError={Boolean(props.error)}
          />
        )}
      </div>
    )}
  />
);

export default observer(RFHWeekdaySelector);
