import { FC } from 'react';

import { Popups } from 'analytics/events/popup';

import { Popup } from 'views/Modals/Popup';
import { REMOVE_PATHWAY_QUESTION_POPUP_ID } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';

interface Props {
  isOpen: boolean;
  blockUuid: string;
  onRemove: () => void;
  onCancel: () => void;
}

export const RemoveBlockPopup: FC<Props> = ({ blockUuid, onRemove, onCancel, isOpen }) => (
  <Popup
    nodeId={`${REMOVE_PATHWAY_QUESTION_POPUP_ID}-${blockUuid}`}
    id={Popups.RemovePathwayBlock}
    isOpen={isOpen}
    title="Remove from Pathway"
    onCancelClicked={onCancel}
    description={
      <>
        If this is a question with sub-questions, they will also be deleted.
        <br />
        <br />
        This action cannot be undone.
      </>
    }
    action={{
      actionText: 'Remove',
      actionCallback: onRemove
    }}
  />
);
