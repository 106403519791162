// @ts-strict-ignore
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import {
  trackCheckboxInputUsageAnalyticsEvent,
  trackDropdownInputUsageAnalyticsEvent,
  trackInputUsageAnalyticsEvent,
  trackMultiSelectionInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';
import { trackOptOutRemoteMonitoringModalAnalyticsEvent } from 'analytics/events/opt-out-remote-monitoring-modal';
import { Popups } from 'analytics/events/popup';
import {
  SearchHeaderUsageMetric,
  SearchHeaderUsageValue
} from 'analytics/events/search-header-usage';
import { trackSearchUsageAnalyticsEvent } from 'analytics/events/search-usage';
import classNames from 'classnames';
import { ErrorName } from 'errors';
import i18n from 'i18n';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher, { SearchedPatient } from 'fetchers/PatientsFetcher';

import { CustomSentryCategories, reportBreadcrumb } from 'services/sentryService';

import { getTimeInSecondsFromDate, parseDateForInputField } from 'utils/DateUtils';
import { formatInternationalNumber } from 'utils/FormatUtils';
import { phoneTypeOptions } from 'utils/PhoneUtils';
import { showToast } from 'utils/UserMessageUtils';
import * as ValidationUtils from 'utils/ValidationUtils';

import { API_HOST, ENV } from 'constants/config';
import { FEATURES } from 'constants/features';

import Patient, { IPatientUpdateableFields } from 'models/Patient';
import PatientLocation from 'models/PatientLocation';
import { PatientOptOut } from 'models/PatientOptOut';
import PatientProvider from 'models/PatientProvider';
import { PhoneNumberCarrierDetails, PhoneType } from 'models/PhoneNumberDetails';
import ScheduledProtocol, {
  ProtocolName,
  ProtocolOperationMode,
  ProtocolType
} from 'models/ScheduledProtocol';
import UserModel from 'models/UserModel';

import { usePostPhoneInUseError } from 'hooks/usePhoneInUseError';

import { HEADER_HEIGHT } from 'containers/Layout/Layout.constants';

import { PatientOptOutModal } from 'views/Modals/OptOutModal';
import { Popup } from 'views/Modals/Popup';
import ScheduledProtocolModal from 'views/Modals/ScheduledProtocolModal';
import { InvitationFormSection } from 'views/Pages/Invitation/InviitationFormSection';
import EditInfoInputs, { localeOptions, sexOptions } from 'views/Widgets/EditInfoInputs';
import { FinancialAssistanceSelector } from 'views/Widgets/FinancialAssistanceSelector';
import OptOutCheckboxRow from 'views/Widgets/OptOutCheckboxRow';
import { PatientLocationAutocomplete } from 'views/Widgets/PatientLocationInput';
import { PatientProviderAutocomplete } from 'views/Widgets/PatientProviderInput';
import { ReportProtocolInput } from 'views/Widgets/ReportPotocolInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import Loading from 'components/Loaders/Loading';
import { PatientContextProvider } from 'components/Patient/PatientContextProvider';
import { usePatientModel } from 'components/Patient/usePatientModel';
import { AdvancedSearchBar } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar';
import {
  AdvancedSearchBarActions,
  AdvancedSearchFormFields
} from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';
import { getSearchBy } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.utils';
import { SelectedSearchedPatientBox } from 'components/UIkit/atoms/AdvancedSearchBar/SelectedSearchedPatientBox';
import { FilledButton } from 'components/UIkit/atoms/Button';
import { FormLabeledCheckbox } from 'components/UIkit/atoms/Checkbox/FormLabeledCheckbox';
import { FormAutocomplete, FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { InternalLink } from 'components/UIkit/atoms/Link';

import './Invitation.scss';

interface InvitationForm {
  firstName: string;
  lastName: string;
  mrn: string;
  dateOfBirth: string;
  ssn: string;
  sex: ISelectOption<number>;
  language: ISelectOption<string>;
  enrollmentStatus: string;
  provider: ISelectOption<PatientProvider>;
  location: ISelectOption<PatientLocation>;
  tags: ISelectOption<number>[];
  activation: ISelectOption<boolean>;
  phoneType: ISelectOption<PhoneType>;
  serviceConsent: boolean;
  copayConsent: boolean;
  readTerms: boolean;
  phone: string;
  countryCode: string;
  protocol: ScheduledProtocol;
}

const activationOptions = [
  {
    label: 'Send to Awaiting Activation Page',
    isDisabled: false,
    value: false
  },
  { label: 'Activate Now', isDisabled: false, value: true }
];

const Invitation: FC = () => {
  const {
    departmentsStore,
    patientPageStore,
    constantsStore,
    userStore,
    providersStore,
    locationsStore,
    settingsStore
  } = useStores();
  const patientModel = usePatientModel();
  const location = useLocation<{ patientId: string }>();
  const history = useHistory();
  const [editedPatient, setEditedPatient] = useState(null);
  const [protocolModalOpen, setProtocolModalOpen] = useState(false);
  const [selectedProtocolType, setSelectedProtocolType] = useState(null);
  const [showProtocolWarning, setShowProtocolWarning] = useState(false);
  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [optOutInfo, setOptOutInfo] = useState(null);
  const [financialAssistance, setFinancialAssistance] = useState(null);
  const [inviteFlowStartTime, setInviteFlowStartTime] = useState(null);
  const handlePhoneInUseError = usePostPhoneInUseError();

  const defaultValues: InvitationForm = {
    firstName: '',
    lastName: '',
    mrn: '',
    dateOfBirth: '',
    ssn: '',
    sex: null,
    enrollmentStatus: null,
    language: localeOptions.find((option) => option.value === 'en_US'),
    provider: null,
    location: null,
    tags: [],
    activation: null,
    phoneType: null,
    serviceConsent: false,
    copayConsent: false,
    readTerms: false,
    phone: '',
    countryCode: '+1',
    protocol: null
  };

  const methods = useForm<InvitationForm>({
    defaultValues
  });

  const {
    reset,
    register,
    watch,
    trigger,
    formState,
    getValues,
    setValue,
    clearErrors,
    handleSubmit
  } = methods;
  const { errors } = formState;

  const areInputsDisabled = useMemo(
    () => editedPatient === null || invitationSuccess,
    [editedPatient, invitationSuccess]
  );

  const currentEditedPatientModel = useMemo(() => {
    return editedPatient && editedPatient.id ? patientModel : null;
  }, [editedPatient, patientModel]);

  const didEditedPatientOptedOutChanged = useMemo(() => {
    return (
      Boolean(currentEditedPatientModel) &&
      Boolean(editedPatient) &&
      Boolean(currentEditedPatientModel.optOut) !== Boolean(optOutInfo)
    );
  }, [currentEditedPatientModel, editedPatient, optOutInfo]);

  const shouldShowDataLossDialog = useCallback(() => {
    return !areInputsDisabled && didEditedPatientOptedOutChanged;
  }, [areInputsDisabled, didEditedPatientOptedOutChanged]);

  const keepOnPage = useCallback(
    (e: BeforeUnloadEvent) => {
      if (shouldShowDataLossDialog()) {
        e.preventDefault();
        e.returnValue = 'Changes you made may not be saved.';
      }
    },
    [shouldShowDataLossDialog]
  );

  const fillDataForEditedPatient = useCallback(
    (patientModel: Patient) => {
      reportBreadcrumb({
        level: 'info',
        category: CustomSentryCategories.DEBUG,
        message: 'Filling patient data in form',
        data: {
          oldPatient: editedPatient?.id,
          newPatient: patientModel.id
        }
      }); // Remove when EH-2264 solved
      const { phone, phoneType } = patientModel;
      const phoneNumberFromString = phone ? parsePhoneNumberFromString(phone) : null;
      const language =
        localeOptions.find((option) => option.value.includes(patientModel.locale?.substr(0, 2))) ||
        localeOptions.find((option) => option.value === 'en_US');
      reset({
        firstName: patientModel.firstName || '',
        lastName: patientModel.lastName || '',
        ssn: patientModel.socialSecurity ? patientModel.socialSecurity : '',
        dateOfBirth: patientModel.dateOfBirth
          ? parseDateForInputField(patientModel.dateOfBirth)
          : null,
        language,
        mrn: patientModel.mrn || '',
        sex: patientModel.sex
          ? sexOptions.find((option) => option.value === patientModel.sex)
          : null,
        enrollmentStatus: patientModel.enrollmentStatus,
        provider: patientModel.providerId
          ? providersStore.providersForSelect.find(
              (option) => option.value.id === patientModel.providerId
            )
          : null,
        location: patientModel.locationId
          ? locationsStore.locationsForSelect.find(
              (option) => option.value.id === patientModel.locationId
            )
          : null,
        tags: patientModel.tags
          ? constantsStore.activeTagsForSelect.filter((option) =>
              patientModel.tags.some((tag) => tag === option.value)
            )
          : [],
        activation: activationOptions.find((option) =>
          patientModel.activatedAt === undefined
            ? !option.value
            : option.value === Boolean(patientModel.isActive)
        ),
        phoneType: phoneTypeOptions.find((option) => option.value === phoneType),
        serviceConsent: false,
        copayConsent: false,
        readTerms: false,
        countryCode: phoneNumberFromString ? `+${phoneNumberFromString.countryCallingCode}` : '+1',
        phone: phoneNumberFromString ? phoneNumberFromString.nationalNumber.toString() : '',
        protocol: patientModel.scheduledProtocols ? patientModel.scheduledProtocols[0] : null
      });
      setEditedPatient(patientModel);
      setOptOutInfo(patientModel.optOut || null);
      setInvitationSuccess(false);
      setShowProtocolWarning(false);
      setFinancialAssistance(Boolean(patientModel.financialAssistance));
    },
    [constantsStore.activeTagsForSelect, editedPatient?.id, locationsStore, providersStore, reset]
  );

  useEffect(
    function loadPatientFromLocation() {
      const loadPatientIfExistsInLocationState = async () => {
        if (location.state?.patientId) {
          await patientPageStore.loadSinglePatientFromServer(Number(location.state?.patientId));
          if (patientModel) {
            fillDataForEditedPatient(patientModel);
            // clear router state (required only for first load)
            history.replace({
              pathname: location.pathname,
              state: {}
            });
          }
        }
      };

      loadPatientIfExistsInLocationState();
    },
    [
      patientPageStore,
      location.state,
      history,
      fillDataForEditedPatient,
      patientModel,
      location.pathname
    ]
  );

  useEffect(
    function initListeners() {
      window.addEventListener('beforeunload', keepOnPage);
      register('protocol');
      return () => {
        window.removeEventListener('beforeunload', keepOnPage);
      };
    },
    [register, keepOnPage]
  );

  const [phoneType, phone, countryCode, protocol, serviceConsent, copayConsent, readTerms] = watch([
    'phoneType',
    'phone',
    'countryCode',
    'protocol',
    'serviceConsent',
    'copayConsent',
    'readTerms'
  ]);

  const hasPhoneTypeChanged = useCallback(
    (phoneDetails: PhoneNumberCarrierDetails) => {
      return (
        getValues('phoneType')?.value !== phoneDetails.type ||
        (editedPatient?.id && editedPatient.phoneType !== phoneDetails.type)
      );
    },
    [getValues, editedPatient]
  );

  useEffect(
    function triggerValidationOnOptOutChanged() {
      if (formState.isSubmitted) {
        trigger();
      }
    },
    [optOutInfo, formState.isSubmitted, trigger]
  );

  useEffect(
    function lookupPhoneTypeOnPhoneChanged() {
      const internationalPhone = countryCode + phone;
      if (ValidationUtils.isValidPhoneNumber(internationalPhone) && formState.dirtyFields.phone) {
        userStore
          .lookUpPhoneDetails(internationalPhone)
          .then((phoneDetails) => {
            if (phoneDetails) {
              if (hasPhoneTypeChanged(phoneDetails)) {
                setValue(
                  'activation',
                  activationOptions.find((option) => !option.value)
                );
              }
              clearErrors('phoneType');
              setValue(
                'phoneType',
                phoneTypeOptions.find((option) => option.value === phoneDetails.type)
              );
            }
          })
          .catch((e) => {
            console.warn(e);
            clearErrors('phoneType');
            setValue('protocol', null);
            setValue('phoneType', null);
            setValue(
              'activation',
              activationOptions.find((option) => !option.value)
            );
          });
      }
    },
    [
      phone,
      countryCode,
      hasPhoneTypeChanged,
      setValue,
      userStore,
      clearErrors,
      formState.dirtyFields.phone
    ]
  );

  //clear protocol and activation when phone type field is changed to "Landline" and the protocol is "Mobile App"
  useEffect(
    function clearProtocol() {
      if (phoneType?.value === PhoneType.landline && protocol?.type === ProtocolType.mobile) {
        setValue('protocol', null);
        setValue(
          'activation',
          activationOptions.find((option) => !option.value)
        );
      }
    },
    [protocol, phoneType, setValue]
  );

  useEffect(
    function disableAwaitingActivationOnAutoProtocol() {
      const isAutoProtocol = protocol?.operationMode === ProtocolOperationMode.Automatic;
      activationOptions[1].isDisabled = isAutoProtocol;
      if (isAutoProtocol) {
        setValue(
          'activation',
          activationOptions.find((option) => option.value)
        );
      }
    },
    [protocol, setValue]
  );
  const handleEditedPatientChanged = useCallback(
    async (
      patient: SearchedPatient,
      options?: { isNewPatient?: boolean; isKeyboardSource?: boolean }
    ) => {
      if (options?.isNewPatient) {
        trackSearchUsageAnalyticsEvent({
          action: AnalyticEventAction.Select,
          value: 'add new patient',
          source: options?.isKeyboardSource ? 'keyboard' : null
        });
        const user = new UserModel(
          null,
          patient.firstName,
          patient.lastName,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        const parsedPatient = new Patient(user);
        fillDataForEditedPatient(parsedPatient);
        return;
      }

      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Select,
        value: 'search result',
        source: options?.isKeyboardSource ? 'keyboard' : null
      });

      let fullPatient = null;
      const loadPatient = () => {
        reportBreadcrumb({
          level: 'info',
          category: CustomSentryCategories.DEBUG,
          message: 'Edited patient changed'
        }); // Remove when EH-2264 solved

        return patientPageStore.loadSinglePatientFromServer(patient.patientId);
      };

      if (patient.patientId) {
        fullPatient = await loadPatient();
      }

      if (!patient.patientId && patient.emrPatientId) {
        fullPatient = await PatientsFetcher.fetchEmrPatient(patient.emrPatientId);
      }

      // This is so we have the new patient model for the form (search api doesn't return full data)
      // We get all patients (with full data) on page load
      fillDataForEditedPatient(fullPatient);
      setInviteFlowStartTime(new Date());
    },
    [fillDataForEditedPatient, patientPageStore]
  );

  if (!departmentsStore.root) {
    return <Loading primaryColor />;
  }

  const inviteClicked = formState.isSubmitted;

  const handleProtocolOptionChanged = (name: string, type: ProtocolType) => {
    const selectedProtocolLabel = ScheduledProtocol.getProtocolDisplayName(type);

    trackInputUsageAnalyticsEvent({
      action: AnalyticEventAction.Update,
      type: 'dropdown',
      selected_value: selectedProtocolLabel || 'Choose Protocol Later',
      value: 'Report Protocol'
    });

    const protocol = getValues('protocol');
    const isSwitchingType =
      protocol &&
      protocol.type !== type &&
      protocol.name !== name &&
      protocol.name !== ProtocolName.chooseLater;

    if (name !== ProtocolName.chooseLater) {
      setProtocolModalOpen(!isSwitchingType);
      setSelectedProtocolType(type);
      setShowProtocolWarning(isSwitchingType);
    } else {
      setValue('protocol', null);
      setValue(
        'activation',
        activationOptions.find((option) => !option.value)
      );
      setShowProtocolWarning(Boolean(protocol) && isSwitchingType);
    }
  };

  const toggleOptPutModal = () => {
    setShowOptOutModal(!showOptOutModal);
  };

  const handleOptOutSave = (optOutInfo: PatientOptOut) => {
    if (!optOutInfo) {
      toggleOptPutModal();
    } else {
      setOptOutInfo(optOutInfo);
      setShowOptOutModal(false);
      setValue(
        'activation',
        activationOptions.find((option) => !option.value)
      );
    }
  };

  const handleEditedPatientCanceled = () => {
    reset(defaultValues);
    setEditedPatient(null);
    setSelectedProtocolType(null);
    setShowProtocolWarning(false);
    patientPageStore.clearData();
  };

  const handleProtocolSubmitted = (newProtocol: ScheduledProtocol) => {
    newProtocol.type = selectedProtocolType;
    setValue('protocol', newProtocol);
    setValue(
      'activation',
      activationOptions.find((option) => option.value)
    );
    setProtocolModalOpen(false);
  };

  const handleProtocolCanceled = () => {
    const hasPreviousReport = !!(
      editedPatient &&
      editedPatient.scheduledProtocols &&
      editedPatient.scheduledProtocols.length > 0
    );
    setProtocolModalOpen(false);
    setSelectedProtocolType(hasPreviousReport ? editedPatient.scheduledProtocols[0].type : null);
    setValue('protocol', hasPreviousReport ? editedPatient.scheduledProtocols[0] : null);
    if (!hasPreviousReport) {
      setValue(
        'activation',
        activationOptions.find((option) => !option.value)
      );
    }
  };

  const isOptOutFlow = () => optOutInfo !== null;

  const getValidProtocolFromState = () => {
    const protocol = getValues('protocol');
    return protocol ? (protocol.name !== ProtocolName.chooseLater ? protocol : null) : null;
  };

  const getPatientNameOrDefault = () => firstName || 'patient';

  const handleOptOutClick = () => {
    if (!optOutInfo) {
      if (!editedPatient.optOut) {
        trackOptOutRemoteMonitoringModalAnalyticsEvent({ action: AnalyticEventAction.Open });
        toggleOptPutModal();
      } else {
        setOptOutInfo(editedPatient.optOut);
      }
    } else {
      setOptOutInfo(null);
    }
  };

  const isPatientAlreadyOptedOut = () => {
    return Boolean(currentEditedPatientModel) && Boolean(currentEditedPatientModel.optOut);
  };

  const isSubmitButtonDisabled = () => {
    return (
      (inviteClicked && Boolean(Object.keys(errors).length)) ||
      !!(editedPatient && editedPatient.optOut && optOutInfo)
    );
  };

  const getSubmitButtonText = (): string => {
    if (patientPageStore.isLoading) {
      return 'Sending...';
    }

    if (optOutInfo) {
      return isPatientAlreadyOptedOut() ? 'Already Opted Out' : 'Opt Out';
    } else {
      return 'Send Invite';
    }
  };

  const handleSendInvitationClicked = (invitationForm: InvitationForm) => {
    trackActionButtonAnalyticsEvent({
      action:
        submitButtonText === 'Send Invite'
          ? AnalyticEventAction.SendInvite
          : AnalyticEventAction.OptOut,
      value: 'send successfully'
    });

    if (!editedPatient || !editedPatient.id) {
      createNewUserAndInvite(invitationForm);
    } else {
      updateAndInviteExistingPatient(invitationForm);
    }
  };

  const handleOptOutSuccess = () => {
    reset(defaultValues);
    showToast({ message: `Patient ${firstName} ${lastName} Opted Out Successfully` });
    setEditedPatient(null);
    setOptOutInfo(null);
  };

  const handleInviteSuccess = async (patient: Patient, inviteMessage: string) => {
    reset(defaultValues);
    showToast({ message: `Patient ${firstName} ${lastName} Invited Successfully` });

    if (!patient.optOut && patient.phoneType === PhoneType.mobile) {
      await patientPageStore.sendSmsToPatient(patient, inviteMessage);
    }
    patientPageStore.clearData();
    setEditedPatient(null);
    setShowProtocolWarning(false);
    setInvitationSuccess(true);
  };

  const createNewUserAndInvite = async (invitationForm: InvitationForm) => {
    const user = new UserModel(
      null,
      invitationForm.firstName.trim(),
      invitationForm.lastName.trim(),
      null,
      null,
      null,
      null,
      invitationForm.countryCode + invitationForm.phone,
      invitationForm.phoneType.value,
      null
    );

    const patient = new Patient(user);
    patient.dateOfBirth = invitationForm.dateOfBirth;
    patient.remoteMonitoringConsent = invitationForm.serviceConsent ? new Date() : null;
    patient.copayConsentGiven = invitationForm.copayConsent ? new Date() : null;
    patient.patientReadTerms = invitationForm.readTerms ? new Date() : null;
    patient.socialSecurity = invitationForm.ssn;
    patient.sex = invitationForm.sex?.value;
    patient.mrn = invitationForm.mrn;
    patient.sourceId = editedPatient && editedPatient.sourceId;
    patient.optOut = optOutInfo;
    patient.location = invitationForm.location?.value;
    patient.locale = invitationForm.language.value;
    patient.enrollmentStatus = invitationForm.enrollmentStatus;
    patient.scheduledProtocols = [invitationForm.protocol];
    patient.financialAssistance = financialAssistance;
    patient.emrPatientId = editedPatient && editedPatient.emrPatientId;
    patient.providerId = invitationForm.provider?.value.id;
    patient.tags = invitationForm.tags?.map((option) => option.value) || [];
    try {
      const newPatient = await patientPageStore.createAndInvitePatient(
        patient,
        invitationForm.activation.value,
        getTimeInSecondsFromDate(inviteFlowStartTime)
      );
      if (!optOutInfo) {
        await handleInviteSuccess(
          newPatient,
          i18n.t('defaultInviteMessage', {
            lng: invitationForm.language.value,
            name: firstName,
            institution: departmentsStore.root ? departmentsStore.root.name : 'the clinic',
            url: `${API_HOST}/app`,
            phone: ENV === 'production' ? '(833) 288-7683' : '(213) 270-1782'
          })
        );
      } else {
        handleOptOutSuccess();
      }
    } catch (error) {
      if (error.name === ErrorName.PhoneInUse) {
        return handlePhoneInUseError(error);
      }
      throw error;
    }
  };

  const updateAndInviteExistingPatient = async (invitationForm: InvitationForm) => {
    const updatedProtocol = invitationForm.protocol;
    const updateFields: IPatientUpdateableFields = {
      firstName: invitationForm.firstName.trim(),
      lastName: invitationForm.lastName.trim(),
      phone: invitationForm.countryCode + invitationForm.phone,
      phoneType: invitationForm.phoneType.value,
      dateOfBirth: invitationForm.dateOfBirth,
      remoteMonitoringConsent: invitationForm.serviceConsent ? new Date() : null,
      copayConsentGiven: invitationForm.copayConsent ? new Date() : null,
      patientReadTerms: invitationForm.readTerms ? new Date() : null,
      sex: invitationForm.sex?.value,
      socialSecurity: invitationForm.ssn.replace(/ /g, ''),
      // mrn is disabled for edit no need to get it from form data
      // (react hook form wont initialize the value if field is disabled)
      mrn: editedPatient.mrn,
      locale: invitationForm.language.value,
      protocol: updatedProtocol,
      optOut: optOutInfo,
      location: invitationForm.location?.value,
      enrollmentStatus: invitationForm.enrollmentStatus,
      financialAssistance: financialAssistance,
      providerId: invitationForm.provider?.value.id,
      tags: invitationForm.tags?.map((option) => option.value) || []
    };
    try {
      await patientPageStore.updatePatient(
        editedPatient.id,
        updateFields,
        invitationForm.activation.value
      );
      if (!optOutInfo) {
        const { patient } = patientPageStore;
        const { currentDoctor } = userStore;
        await handleInviteSuccess(
          patient,
          i18n.t('defaultSymptomsReminderSms', {
            lng: invitationForm.language.value,
            name: firstName,
            doctor: currentDoctor.fullName,
            institution: departmentsStore.root ? departmentsStore.root.name : 'the clinic',
            url: `${API_HOST}/app`,
            phone: ENV === 'production' ? '(833) 288-7683' : '(213) 270-1782'
          })
        );
      } else {
        handleOptOutSuccess();
      }
    } catch (error) {
      if (error.name === ErrorName.PhoneInUse) {
        return handlePhoneInUseError(error);
      }
      throw error;
    }
  };

  const renderInvitationFields = (disableInputs: boolean) => {
    return (
      <>
        <Row className="consent-row">
          <div className="d-flex align-items-center mb-lg-4" data-test-hook="service-consent-cb">
            <FormLabeledCheckbox
              name="serviceConsent"
              disabled={disableInputs}
              required={!optOutInfo}
              label="Patient consents to the Canopy service (including potential co-pays)"
              onChange={() => {
                trackCheckboxInputUsageAnalyticsEvent(
                  serviceConsent,
                  `Service Consent ${financialAssistance ? 'with' : 'without'} financial assistance`
                );
              }}
            />
            <FinancialAssistanceSelector
              disabled={disableInputs}
              value={financialAssistance}
              onChange={(value) => setFinancialAssistance(value)}
            />
          </div>
          <div className="d-flex align-items-center mb-lg-4" data-test-hook="copay-consent-cb">
            <FormLabeledCheckbox
              name="copayConsent"
              disabled={disableInputs}
              required={Boolean(!optOutInfo)}
              label="Patient consents to calls and/or SMS from their care team."
              onChange={() => trackCheckboxInputUsageAnalyticsEvent(copayConsent, 'Copay Consent')}
            />
          </div>
          <div className="d-flex align-items-center" data-test-hook="patient-terms-cb">
            <FormLabeledCheckbox
              name="readTerms"
              disabled={disableInputs}
              required={Boolean(!optOutInfo)}
              label={
                <>
                  Patient has read and agreed to the Canopy{' '}
                  <InternalLink to="/terms" newTabOnClick size="large">
                    Terms of Service
                  </InternalLink>{' '}
                  and{' '}
                  <InternalLink to="/privacy" newTabOnClick size="large">
                    Privacy Policy
                  </InternalLink>
                </>
              }
              onChange={() => trackCheckboxInputUsageAnalyticsEvent(readTerms, 'Read Terms')}
            />
          </div>
        </Row>
      </>
    );
  };

  const renderActivationDropdown = (): ReactNode => {
    const isDisabled = !getValidProtocolFromState() || Boolean(optOutInfo);
    return (
      <FormAutocomplete
        options={activationOptions}
        isDisabled={isDisabled}
        isRequired
        label="Report Protocol Activation"
        getOptionLabel={(option: ISelectOption<boolean>) =>
          option.value ? (
            'Activate Now'
          ) : (
            <span>
              Send to <i>Awaiting Activation</i> Page
            </span>
          )
        }
        name="activation"
        onChange={(value, actionMeta, eventKey) =>
          trackDropdownInputUsageAnalyticsEvent(
            actionMeta,
            'Report Protocol Activation',
            eventKey === 'Enter'
          )
        }
      />
    );
  };

  const firstName = getValues('firstName');
  const lastName = getValues('lastName');
  const optOutSectionClassNames = classNames({ 'd-none': isOptOutFlow() });
  const submitButtonText = getSubmitButtonText();

  const advancedSearchBarActions: AdvancedSearchBarActions = {
    onPatientClick: handleEditedPatientChanged,
    onClearSearch: () => trackSearchUsageAnalyticsEvent({ action: AnalyticEventAction.Clear }),
    onSearch: (currentSearchTerm: string, options?: { isKeyboardSource?: boolean }) => {
      const searchBy = getSearchBy(currentSearchTerm);

      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Search,
        value: `searched by ${searchBy}` as
          | 'searched by EMPTY'
          | 'searched by MRN'
          | 'searched by NAME'
          | 'searched by DOB',
        source: options?.isKeyboardSource ? 'keyboard' : null,
        type: 'regular search'
      });
    },
    onAdvancedSearch: (metrics: Partial<AdvancedSearchFormFields>) => {
      const formattedFormValuesForAnalytics = Object.keys(metrics).map((metric) =>
        metric.toUpperCase()
      );
      const searchHeaderUsageAnalyticsEventValue: SearchHeaderUsageValue = `searched by ${
        formattedFormValuesForAnalytics.join(' and ') as SearchHeaderUsageMetric
      }`;

      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Search,
        value: searchHeaderUsageAnalyticsEventValue,
        source: null,
        type: 'advanced search'
      });
    },
    onCancelAdvancedSearch: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Cancel
      }),
    onClearAllAdvancedSearch: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.ClearAll
      }),
    onToggleAdvancedSearchMenu: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.AdvancedSearch
      }),
    onOpenAdvancedSearch: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.RefineWithAdvancedSearch
      }),
    onPasteFromClipboard: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.PasteFromClipboard,
        source: null
      })
  };

  return (
    <>
      <div className="invitation-page">
        <Popup
          isOpen={showProtocolWarning && protocol && protocol.name !== ProtocolName.chooseLater}
          onCancelClicked={() => {
            setSelectedProtocolType(protocol.type);
            setShowProtocolWarning(false);
          }}
          title="Delete & Switch Protocols?"
          description={
            protocol
              ? `${firstName} will no longer receive the protocol "${ScheduledProtocol.getProtocolDisplayName(
                  protocol.type
                )}", if you switch to the new protocol "${ScheduledProtocol.getProtocolDisplayName(
                  selectedProtocolType
                )}"`
              : null
          }
          cancelText="Keep Protocol"
          action={{
            actionText: 'Switch Protocol',
            actionCallback: () => {
              setProtocolModalOpen(true);
              setShowProtocolWarning(false);
              setValue('protocol', null);
            }
          }}
          id={Popups.DeleteAndSwitchProtocol}
        />

        <FormProvider {...methods}>
          <PatientOptOutModal
            isOpen={showOptOutModal}
            patientId={editedPatient?.id}
            onClose={handleOptOutSave}
          />
          {!areInputsDisabled && protocolModalOpen && (
            <ScheduledProtocolModal
              onScheduledProtocolSubmitted={handleProtocolSubmitted}
              onScheduledProtocolCanceled={handleProtocolCanceled}
              protocol={getValidProtocolFromState()}
              isOpen={protocolModalOpen}
              type={selectedProtocolType}
            />
          )}
          <Row>
            <Col xs={1} sm={1} lg={1}>
              <div className="section-number">1</div>
            </Col>
            <Col xs={11} sm={11} lg={11}>
              <h3 className="invitation-title first-title">Who would you like to invite today?</h3>

              <div className="searchbar-container">
                {Boolean(editedPatient) ? (
                  <SelectedSearchedPatientBox
                    clearSearch={handleEditedPatientCanceled}
                    variant="light"
                    patient={editedPatient}
                  />
                ) : (
                  <AdvancedSearchBar
                    hasSameWidthModifier
                    actions={advancedSearchBarActions}
                    searchBarBoxProps={{
                      variant: 'light',
                      hasPasteMrnOption: true
                    }}
                    searchBarMenusProps={{
                      offset: [0, 4],
                      variant: 'rounded',
                      patientsMenu: {
                        hasAddNewPatientOption: settingsStore.hasFeature(
                          FEATURES.INVITE_NEW_PATIENT
                        ),
                        maxHeight: `calc(100vh - 155px - ${HEADER_HEIGHT * 2}px)`
                      }
                    }}
                    includeSelf
                  />
                )}
              </div>
            </Col>
          </Row>
          <div className={`step-section-container ${areInputsDisabled ? 'fade-out' : 'fade-in'}`}>
            <InvitationFormSection>
              <Row>
                <h3 className="invitation-title">Enter patient details</h3>
                <OptOutCheckboxRow checked={Boolean(optOutInfo)} onChange={handleOptOutClick} />
              </Row>
              <EditInfoInputs
                disableInputs={areInputsDisabled}
                isExistingPatient={Boolean(editedPatient && editedPatient.id)}
                isOptOutFlow={isOptOutFlow()}
                disableEnrollmentStatus={editedPatient && editedPatient.enrollmentStatus}
              />
            </InvitationFormSection>
            <InvitationFormSection>
              <h3 className="invitation-title">Add or verify phone number</h3>
              <p className="invitation-subtitle">
                Reporting via the Canopy app requires a mobile phone number. The Automated Report
                Line can be used with landline numbers.
              </p>
              <br />
              <Row>
                <Col xs={12} sm={6} lg={6}>
                  <StyledPhoneInput
                    label="Phone Number"
                    error={Boolean(errors.phone)}
                    disabled={areInputsDisabled}
                    onBlur={(event, valueAfterFocus, currentValue) => {
                      trackOpenTextInputUsageAnalyticsEvent(
                        currentValue,
                        valueAfterFocus,
                        'Phone Number'
                      );
                    }}
                  />
                </Col>
                <Col xs={12} sm={6} lg={6}>
                  <FormAutocomplete
                    label="Phone Type"
                    isRequired
                    options={phoneTypeOptions}
                    name="phoneType"
                    onChange={(value, actionMeta, eventKey) =>
                      trackDropdownInputUsageAnalyticsEvent(
                        actionMeta,
                        'Language',
                        eventKey === 'Enter'
                      )
                    }
                  />
                </Col>
              </Row>
            </InvitationFormSection>
            <InvitationFormSection>
              <h3 className="invitation-title">Choose Primary Provider, Location, and Tags</h3>
              <Row>
                <Col xs={12} sm={6} lg={6} data-test-hook="patient-provider">
                  <PatientProviderAutocomplete
                    isRequired={!optOutInfo}
                    disabled={areInputsDisabled}
                    trackAnalyticsEvent={trackDropdownInputUsageAnalyticsEvent}
                  />
                </Col>
                <Col xs={12} sm={6} lg={6} data-test-hook="patient-location">
                  <PatientLocationAutocomplete
                    isRequired
                    disable={areInputsDisabled}
                    trackAnalyticsEvent={trackDropdownInputUsageAnalyticsEvent}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                {/*TODO change to tags hook on server tests*/}
                <Col xs={12} sm={12} lg={12} data-test-hook="care-team-assignment">
                  <FormMultiAutocomplete
                    isDisabled={areInputsDisabled}
                    options={constantsStore.activeTagsForSelect}
                    name="tags"
                    label="Tags (Optional)"
                    sortAlphabetically={false}
                    onChange={(value, actionMeta, eventKey) =>
                      trackMultiSelectionInputUsageAnalyticsEvent(
                        actionMeta,
                        'Tags',
                        eventKey === 'Enter'
                      )
                    }
                  />
                </Col>
              </Row>
            </InvitationFormSection>
            <>
              <InvitationFormSection classNames={optOutSectionClassNames}>
                <h3 className="invitation-title">
                  Add the Canopy Report Line to {getPatientNameOrDefault()}’s contacts
                </h3>
                <p className="invitation-subtitle">
                  Please ensure {getPatientNameOrDefault()} adds the Canopy Report Line as a contact
                  on their phone, and explain that they may submit reports by dialing this number,
                  in addition to the Canopy app.
                </p>
                <br />
                <p className="invitation-subtitle">
                  <b>(833) 288-7683</b>
                </p>
              </InvitationFormSection>
              <InvitationFormSection classNames={optOutSectionClassNames}>
                <h3 className="invitation-title">
                  Choose the type and frequency of {getPatientNameOrDefault()}’s reports
                </h3>
                <p className="invitation-subtitle">
                  {getValues('activation') ? (
                    <span>
                      This patient will be activated now, and is able to submit reports via the app
                      or the Automated Report Line.
                    </span>
                  ) : (
                    <>
                      <span>This patient will appear in the </span>
                      <i>Awaiting Activation</i>
                      <span> page until they are activated.</span>
                    </>
                  )}
                </p>
                <br />
                <Row>
                  <Col xs={12} sm={6} lg={6}>
                    <ReportProtocolInput
                      onProtocolChanged={handleProtocolOptionChanged}
                      disabled={
                        areInputsDisabled ||
                        !ValidationUtils.isValidPhoneNumber(
                          formatInternationalNumber(countryCode, phone)
                        )
                      }
                      error={false}
                      value={protocol}
                      isInvite
                      phoneNumType={phoneType?.value}
                      isOptOutFlow={isOptOutFlow()}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={6}>
                    {renderActivationDropdown()}
                  </Col>
                </Row>
              </InvitationFormSection>
              <InvitationFormSection classNames={optOutSectionClassNames}>
                <h3 className="invitation-title">Terms of Use</h3>
                <div className="ml-1">{renderInvitationFields(areInputsDisabled)}</div>
              </InvitationFormSection>
              <InvitationFormSection classNames={optOutSectionClassNames}>
                <h3 className="invitation-title">Send invite and download the Canopy app</h3>
                <p className="invitation-subtitle">
                  Please ensure {getPatientNameOrDefault()} downloads the Canopy mobile app to his
                  phone. Click below to send {`${getPatientNameOrDefault()} `}
                  an SMS message with the following download link: api.canopycare.us/app
                </p>
              </InvitationFormSection>
            </>
            <Row>
              <Col xs={1} sm={1} lg={1} />
              <Col xs={11} sm={11} lg={11}>
                <div className="bottom-buttons">
                  <div className="bottom-button-text-container" data-test-hook="invite">
                    <FilledButton
                      onClick={handleSubmit(handleSendInvitationClicked, () =>
                        trackActionButtonAnalyticsEvent({
                          action: AnalyticEventAction.SendInvite,
                          value: 'missing fields'
                        })
                      )}
                      disabled={isSubmitButtonDisabled() || patientPageStore.isLoading}
                    >
                      {submitButtonText}
                    </FilledButton>

                    {Boolean(Object.keys(errors).length > 0) && (
                      <h5 className="error" data-test-hook="error">
                        Some fields (highlighted in red) are not properly filled.
                      </h5>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </FormProvider>
      </div>
      <Prompt
        when={shouldShowDataLossDialog()}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </>
  );
};

const ObservedInvitation = observer(Invitation);

const InvitationWithContext = () => (
  <PatientContextProvider>
    <ObservedInvitation />
  </PatientContextProvider>
);

export default InvitationWithContext;
