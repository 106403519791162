// @ts-strict-ignore

import { FC, useEffect, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackScheduledProtocolModalAnalyticsEvent } from 'analytics/events/scheduled-protocol-modal';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { parseDateForInputField, timeOptions, WEEK_IN_MILLISECONDS } from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

import RegimenTemplate from 'models/RegimenTemplate';
import ScheduledProtocol, {
  ProtocolAutomationMode,
  ProtocolName,
  ProtocolOperationMode,
  ProtocolType
} from 'models/ScheduledProtocol';
import { SelectOption } from 'models/SelectOption';

import RegimenEditorModal from 'views/Modals/RegimenEditorModal';
import { endOptions } from 'views/Widgets/EndProtocolOptions';
import OralProtocolFields, { fillLocationOptions } from 'views/Widgets/OralProtocolFields';
import SymptomProtocolFields, {
  hybridIntervalOptions,
  intervalOptions
} from 'views/Widgets/SymptomProtocolFields';
import ToggleBar from 'views/Widgets/ToggleBar';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './ScheduledProtocolModal.scss';

interface ScheduleProtocolModalProps {
  isOpen: boolean;
  type: ProtocolType;
  protocol?: ScheduledProtocol;
  onScheduledProtocolSubmitted: (protocol: ScheduledProtocol) => void;
  onScheduledProtocolCanceled: () => void;
  isLoading?: boolean;
}

interface SymptomProtocolForm {
  selectedStartDate: string;
  selectedStartHour: SelectOption<number>;
  selectedEndOption: SelectOption<number>;
  selectedFrequency: SelectOption<number>;
  hybridFrequency: SelectOption<ProtocolAutomationMode>;
  frequencyMultiplier: number;
  numberOfOccurrences: number;
  selectedEndDate: string;
  weekdays: Set<number>;
  code: number;
  automaticFrequency: boolean;
}

interface OralProtocolForm {
  selectedStartDate: string;
  selectedStartHour: SelectOption<number>;
  weekdays: Set<number>;
  regimenId: SelectOption<number>;
  notes: string;
  fillFrequency: number;
  fillLocation: SelectOption<string>;
  numberOfOccurrences: number;
  selectedEndOption: SelectOption<number>;
  selectedFrequency: SelectOption<number>;
  selectedEndDate: string;
  regimen?: RegimenTemplate;
}

const DEFAULT_PROTOCOL_START_HOUR = 8;

const ScheduleProtocolModal: FC<ScheduleProtocolModalProps> = (props) => {
  const { constantsStore, settingsStore } = useStores();
  const { protocol, isLoading } = props;
  const hasOralOncolytics = settingsStore.hasFeature(
    FEATURES.ORAL_ONCOLYTICS_AND_SYMPTOM_ASSESSMENT_TAB
  );
  const name = protocol?.name || ProtocolName.symptom;
  const defaultTab = protocol?.type === ProtocolType.mobile ? name : ProtocolName.symptom;
  const [selectedProtocolTab, setSelectedProtocolTab] = useState(defaultTab);
  const [isRegimenModalOpen, setIsRegimenModalOpen] = useState(false);

  // currently we use conditional rendering for this modal (in 3 places).
  // need to change to update effect with a condition once we remove the conditional rendering
  // see https://expain.atlassian.net/browse/EH-4671
  useMount(function trackIsOpenOnMount() {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: defaultTab,
      patientId: protocol?.patientId,
      action: AnalyticEventAction.Open
    });
  });

  useEffect(
    function disablePhoneOralOption() {
      if (props.type === ProtocolType.phone) {
        setSelectedProtocolTab(ProtocolName.symptom);
      } else {
        setSelectedProtocolTab(defaultTab);
      }
    },
    [props.type, defaultTab]
  );

  const toggleOptions = useMemo(() => {
    const isMobile = props.type === ProtocolType.mobile;
    const oralOncolyticsOption = hasOralOncolytics
      ? [
          {
            value: ProtocolName.oralOncolytics,
            label: 'Oral Oncolytics & Symptom Assessment',
            disabled: !isMobile,
            tooltipText: !isMobile
              ? 'Oral Oncolytics is currently only available through the Mobile App. Report Line integration is coming soon.'
              : ''
          }
        ]
      : [];
    return [{ value: ProtocolName.symptom, label: 'Symptom Assessment' }, ...oralOncolyticsOption];
  }, [hasOralOncolytics, props.type]);

  const [symptomDefaultValues, oralDefaultValues] = useMemo(() => {
    let timeUnit = WEEK_IN_MILLISECONDS;
    let multiplier = 1;
    if (
      protocol?.name === ProtocolName.symptom &&
      protocol?.operationMode === ProtocolOperationMode.Manual
    ) {
      const result = protocol.getIntervalAsMultiplication();
      timeUnit = result.timeUnit;
      multiplier = result.multiplier;
    }
    const selectedStartHour = timeOptions.find(
      (option) =>
        option.value ===
        (protocol?.info?.startingDate
          ? new Date(protocol.info.startingDate).getHours()
          : DEFAULT_PROTOCOL_START_HOUR)
    );
    const selectedEndOption = endOptions.find(
      (option: SelectOption<number>) =>
        option.value ===
        (protocol?.info?.endType ? protocol.info.endType : ScheduledProtocol.END_TYPE_NEVER)
    );
    const numberOfOccurrences = protocol?.info?.occurrences ? protocol.info.occurrences : 1;
    const selectedStartDate = parseDateForInputField(protocol?.info?.startingDate || new Date());
    const selectedEndDate = protocol?.info?.expirationDate
      ? parseDateForInputField(protocol?.info.expirationDate)
      : null;
    const isSymptom = protocol?.name === ProtocolName.symptom;
    const isOral = protocol?.name === ProtocolName.oralOncolytics;
    return [
      {
        selectedStartDate: selectedStartDate,
        selectedStartHour: selectedStartHour,
        selectedEndOption: selectedEndOption,
        selectedFrequency: intervalOptions.find((option) => option.value === timeUnit),
        hybridFrequency:
          hybridIntervalOptions.find((option) => option.value === protocol?.automationMode) ||
          hybridIntervalOptions[0],
        frequencyMultiplier: multiplier,
        numberOfOccurrences: numberOfOccurrences,
        selectedEndDate: selectedEndDate,
        weekdays:
          protocol?.info?.daysOfWeek && isSymptom
            ? protocol.info.daysOfWeek
            : [new Date().getDay()],
        automaticFrequency: Boolean(protocol?.operationMode === ProtocolOperationMode.Automatic)
      },
      {
        selectedStartDate: selectedStartDate,
        selectedStartHour: selectedStartHour,
        weekdays:
          protocol?.info?.daysOfWeek && isOral ? protocol.info.daysOfWeek : [new Date().getDay()],
        regimenId: constantsStore.regimenTemplatesForSelect.find(
          (option) => option.value === protocol?.info?.regimenId
        ),
        selectedFrequency: { label: 'Week', value: WEEK_IN_MILLISECONDS },
        fillFrequency: protocol?.info?.fillFrequency,
        notes: protocol?.info?.notes,
        fillLocation: fillLocationOptions.find(
          (option) => option.value === protocol?.info?.fillLocation
        ),
        selectedEndOption: selectedEndOption,
        selectedEndDate: selectedEndDate,
        numberOfOccurrences: numberOfOccurrences,
        regimen: protocol?.info?.regimen
      }
    ];
  }, [protocol, constantsStore]);

  const symptomMethods = useForm<SymptomProtocolForm>({
    defaultValues: symptomDefaultValues,
    shouldUnregister: false
  });
  const oralMethods = useForm<OralProtocolForm>({
    defaultValues: oralDefaultValues,
    shouldUnregister: false
  });

  const [regimenId, regimen] = oralMethods.watch(['regimenId', 'regimen']);

  const regimenTemplate = constantsStore.regimens.get(regimenId?.value);
  const finalRegimen = regimen || regimenTemplate;

  const getBaseProtocol = function (code: number, name: ProtocolName) {
    const newProtocol = new ScheduledProtocol();
    newProtocol.id = protocol?.id;
    newProtocol.code = code;
    newProtocol.patientId = protocol?.patientId;
    newProtocol.lastReportIntervalAt = protocol?.lastReportIntervalAt;
    newProtocol.name = name;
    newProtocol.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return newProtocol;
  };

  const handleSubmitSymptomProtocol = (symptomProtocolForm: SymptomProtocolForm) => {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: selectedProtocolTab,
      patientId: protocol?.patientId,
      action: getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace
    });

    const newProtocol = getBaseProtocol(
      symptomProtocolForm.selectedFrequency.value === WEEK_IN_MILLISECONDS
        ? ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_WEEKLY
        : ScheduledProtocol.PROTOCOL_CODE_LINEAR,
      ProtocolName.symptom
    );

    newProtocol.info = {
      expirationDate: symptomProtocolForm.selectedEndDate
        ? new Date(symptomProtocolForm.selectedEndDate)
        : null,
      startingDate: moment(symptomProtocolForm.selectedStartDate)
        .hour(symptomProtocolForm.selectedStartHour.value)
        .toDate(),
      timeBetweenReports:
        symptomProtocolForm.selectedFrequency.value * symptomProtocolForm.frequencyMultiplier,
      endType: symptomProtocolForm.selectedEndOption.value,
      occurrences: symptomProtocolForm.numberOfOccurrences,
      daysOfWeek: symptomProtocolForm.weekdays
    };

    newProtocol.operationMode = symptomProtocolForm.automaticFrequency
      ? ProtocolOperationMode.Automatic
      : ProtocolOperationMode.Manual;
    newProtocol.automationMode =
      protocol?.isFullyAutomated() || !settingsStore.hasFeature(FEATURES.ACTIONS_HYBRID_PROTOCOL)
        ? ProtocolAutomationMode.Full
        : symptomProtocolForm.hybridFrequency.value;
    props.onScheduledProtocolSubmitted(newProtocol);
    clearAllErrors();
  };

  const clearAllErrors = () => {
    oralMethods.clearErrors();
    symptomMethods.clearErrors();
  };

  const handleSubmitOralProtocol = (oralProtocolForm: OralProtocolForm) => {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: selectedProtocolTab,
      patientId: protocol?.patientId,
      action: getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace
    });

    const newProtocol = getBaseProtocol(
      ScheduledProtocol.PROTOCOL_CODE_LINEAR,
      ProtocolName.oralOncolytics
    );
    newProtocol.info = {
      expirationDate: oralProtocolForm.selectedEndDate
        ? new Date(oralProtocolForm.selectedEndDate)
        : null,
      startingDate: moment(oralProtocolForm.selectedStartDate)
        .hour(oralProtocolForm.selectedStartHour.value)
        .toDate(),
      fillFrequency: oralProtocolForm.fillFrequency,
      fillLocation: oralProtocolForm.fillLocation.value,
      occurrences: oralProtocolForm.numberOfOccurrences,
      notes: oralProtocolForm.notes,
      daysOfWeek: oralProtocolForm.weekdays,
      regimenId: oralProtocolForm.regimenId.value,
      reportFrequency: oralProtocolForm.selectedFrequency.value,
      endType: oralProtocolForm.selectedEndOption.value,
      regimen: oralProtocolForm.regimen
    };

    newProtocol.operationMode = ProtocolOperationMode.Manual;
    props.onScheduledProtocolSubmitted(newProtocol);
    clearAllErrors();
  };

  const getProtocolSubmitFunction = () => {
    const action =
      getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace;
    return selectedProtocolTab === ProtocolName.symptom
      ? symptomMethods.handleSubmit(handleSubmitSymptomProtocol, () =>
          trackScheduledProtocolModalAnalyticsEvent({
            tab: selectedProtocolTab,
            patientId: protocol?.patientId,
            action,
            value: 'missing fields'
          })
        )
      : oralMethods.handleSubmit(handleSubmitOralProtocol, () =>
          trackScheduledProtocolModalAnalyticsEvent({
            tab: selectedProtocolTab,
            patientId: protocol?.patientId,
            action,
            value: 'missing fields'
          })
        );
  };

  const getButtonText = () => {
    if (isLoading) {
      return 'Saving…';
    }
    return protocol?.id ? 'Replace' : 'Save';
  };

  const onRegimenSaved = (customRegimen: RegimenTemplate) => {
    oralMethods.setValue('regimen', customRegimen);
    setIsRegimenModalOpen(false);
  };

  return (
    <>
      <FormModal
        defaultValues={
          selectedProtocolTab === ProtocolName.symptom ? symptomDefaultValues : oralDefaultValues
        }
        methods={selectedProtocolTab === ProtocolName.symptom ? symptomMethods : oralMethods}
        isOpen={props.isOpen}
        isInternalModalOpen={isRegimenModalOpen}
        title="Report Protocol"
        confirmActions={[
          { onClick: getProtocolSubmitFunction(), text: getButtonText(), disabled: isLoading }
        ]}
        closeAction={{
          onClick: () => {
            props.onScheduledProtocolCanceled();
            clearAllErrors();
            trackScheduledProtocolModalAnalyticsEvent({
              tab: defaultTab,
              patientId: protocol?.patientId,
              action: AnalyticEventAction.Cancel
            });
          },
          disabled: false
        }}
      >
        <div className="scheduled-protocol-modal">
          {hasOralOncolytics && (
            <div className="toggle-bar-wrapper">
              <ToggleBar
                id="protocol-modal-toggle"
                className="protocol-toggle-bar"
                onOptionSelected={setSelectedProtocolTab}
                options={toggleOptions}
                selected={selectedProtocolTab}
                isSquared
                size="small"
              />
            </div>
          )}

          {selectedProtocolTab === ProtocolName.symptom ? (
            <SymptomProtocolFields type={props.type} isAutomatic={protocol?.isFullyAutomated()} />
          ) : (
            <OralProtocolFields
              type={props.type}
              openRegimenModal={() => setIsRegimenModalOpen(true)}
              isRegimenModalOpen={isRegimenModalOpen}
            />
          )}
        </div>
      </FormModal>

      {regimen && (
        <RegimenEditorModal
          regimen={finalRegimen}
          regimenTemplate={regimenTemplate}
          onSave={onRegimenSaved}
          onCancel={() => setIsRegimenModalOpen(false)}
          isOpen={isRegimenModalOpen}
        />
      )}
    </>
  );
};
export default observer(ScheduleProtocolModal);
