import { FC, ReactNode } from 'react';

import {
  trackDateInputUsageAnalyticsEvent,
  trackDropdownInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent,
  trackToggleButtonInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { parseDateForInputField } from 'utils/DateUtils';
import * as ValidationUtils from 'utils/ValidationUtils';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormExclusiveToggleButton } from 'components/UIkit/atoms/Button';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

import './EditInfoInputs.scss';

interface EditInfoInputsProps {
  disableInputs: boolean;
  isExistingPatient: boolean;
  isOptOutFlow: boolean; // TODO rename to something relevant
  isUninvitedFlow?: boolean; // TODO rename to something relevant
  disableEnrollmentStatus: boolean;
  lastInput?: ReactNode;
}

export const sexOptions = [
  {
    label: 'Male',
    value: 1
  },
  {
    label: 'Female',
    value: 2
  },
  {
    label: 'Other',
    value: 3
  }
];

export const enrolmentStatuses = [
  {
    value: 'CHEMO_TEACH',
    label: 'Chemo Teach'
  },
  {
    value: 'ESTABLISHED',
    label: 'Established'
  }
];

export const localeOptions = [
  {
    label: 'English',
    value: 'en_US'
  },
  {
    label: 'Spanish',
    value: 'es_US'
  },
  {
    label: 'Armenian',
    value: 'hy_US'
  }
];

const EditInfoInputs: FC<EditInfoInputsProps> = ({
  disableInputs,
  isExistingPatient,
  isOptOutFlow,
  isUninvitedFlow,
  disableEnrollmentStatus,
  lastInput
}) => {
  const { register, formState, getValues, watch } = useFormContext();
  const mrnDisabled = disableInputs || isExistingPatient;
  const { errors } = formState;
  const enrollmentStatusDisabled = Boolean(disableInputs || disableEnrollmentStatus);

  const [dateOfBirth, enrollmentStatusValue] = watch(['dateOfBirth', 'enrollmentStatus']);

  return (
    <div className="edit-info-inputs">
      <Row className="mb-3">
        <Col xs={12} sm={6} lg={6} data-test-hook="first-name">
          <RHFStyledInput
            label="First Name"
            name="firstName"
            isRequired
            validate={(v: string) => ValidationUtils.isValidName(v) && !!v.trim()}
            disabled={disableInputs}
            register={register}
            error={Boolean(errors.firstName)}
            onBlur={(_, currentValue, valueAfterFocus) =>
              trackOpenTextInputUsageAnalyticsEvent(currentValue, valueAfterFocus, 'First Name')
            }
            getValues={getValues}
          />
        </Col>
        <Col xs={12} sm={6} lg={6} data-test-hook="last-name">
          <RHFStyledInput
            label="Last Name"
            name="lastName"
            register={register}
            isRequired
            disabled={disableInputs}
            validate={(v: string) => ValidationUtils.isValidName(v) && !!v.trim()}
            error={Boolean(errors.lastName)}
            onBlur={(_, currentValue, valueAfterFocus) =>
              trackOpenTextInputUsageAnalyticsEvent(currentValue, valueAfterFocus, 'Last Name')
            }
            getValues={getValues}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} sm={6} lg={6}>
          <div data-test-hook="mrn">
            <RHFStyledInput
              label="Medical Record Number"
              name="mrn"
              type="text"
              register={register}
              disabled={mrnDisabled}
              isRequired={!mrnDisabled}
              validate={(v: string) => mrnDisabled || ValidationUtils.isValidMrnNumber(v)}
              readOnly={mrnDisabled}
              error={Boolean(errors.mrn)}
              onBlur={(_, currentValue, valueAfterFocus) =>
                trackOpenTextInputUsageAnalyticsEvent(
                  currentValue,
                  valueAfterFocus,
                  'Medical Record Number'
                )
              }
              getValues={getValues}
            />
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6} data-test-hook="date-of-birth">
          <RHFStyledInput
            type="date"
            label="Date of Birth"
            register={register}
            name="dateOfBirth"
            disabled={disableInputs}
            isRequired
            max={parseDateForInputField(new Date())}
            min="1900-01-01"
            error={Boolean(errors.dateOfBirth)}
            onChange={(event, currentValue) => {
              trackDateInputUsageAnalyticsEvent(currentValue, dateOfBirth, 'Date of Birth');
            }}
            getValues={getValues}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} sm={6} lg={6} data-test-hook="sex">
          <FormAutocomplete
            label="Sex"
            name="sex"
            isRequired={!isOptOutFlow && !isUninvitedFlow}
            isClearable={false}
            isDisabled={disableInputs}
            options={sexOptions}
            onChange={(_, actionMeta, eventKey) => {
              trackDropdownInputUsageAnalyticsEvent(actionMeta, 'Sex', eventKey === 'Enter');
            }}
          />
        </Col>
        <Col xs={12} sm={6} lg={6} data-test-hook="language">
          <FormAutocomplete
            label="Language"
            isDisabled={disableInputs}
            isClearable={false}
            name="language"
            options={localeOptions}
            onChange={(_, actionMeta, eventKey) => {
              trackDropdownInputUsageAnalyticsEvent(actionMeta, 'Language', eventKey === 'Enter');
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} sm={6} lg={6}>
          <FormExclusiveToggleButton
            name="enrollmentStatus"
            label="Patient Enrollment Status"
            options={enrolmentStatuses}
            required={!isUninvitedFlow && !enrollmentStatusDisabled}
            disabled={enrollmentStatusDisabled}
            fullWidth
            onChange={() => {
              trackToggleButtonInputUsageAnalyticsEvent(
                enrollmentStatusValue,
                'Patient Enrollment Status'
              );
            }}
          />
        </Col>
        {lastInput && (
          <Col xs={12} sm={6} lg={6}>
            {lastInput}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default observer(EditInfoInputs);
