// @ts-strict-ignore
import { FC } from 'react';

import { Box } from '@mui/material';

import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { useLocationOptions } from 'mobx/hooks/useLocationOptions';
import { useProvidersOptions } from 'mobx/hooks/useProvidersOptions';

import { useStores } from 'mobx/hooks/useStores';

import { Gender } from 'fetchers/QaFetcher';

import { parseDateForInputField } from 'utils/DateUtils';
import * as ValidationUtils from 'utils/ValidationUtils';

import PatientLocation from 'models/PatientLocation';
import PatientProvider from 'models/PatientProvider';

import { PatientLocationAutocomplete } from 'views/Widgets/PatientLocationInput';
import { PatientProviderAutocomplete } from 'views/Widgets/PatientProviderInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { FormInputField } from 'components/Forms';
import FormDateField from 'components/Forms/FormDateField';
import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface EmrRowFormFields {
  patientId?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  patientMrn: string;
  patientSsn: string;
  phone: string;
  countryCode: string;
  provider: ISelectOption<PatientProvider>;
  dob: string;
  gender: ISelectOption<Gender>;
  zipCode: string;
  address: string;
  city: string;
  state: string;
  location: ISelectOption<PatientLocation>;
}

const genderOptions = [
  { value: Gender.Male, label: Gender.Male },
  { value: Gender.Female, label: Gender.Female }
];

export const QaCreateEmrRowModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { qaStore } = useStores();
  const providerOptions = useProvidersOptions();
  const locationOptions = useLocationOptions();
  const methods = useForm<EmrRowFormFields>();

  const {
    handleSubmit,
    formState: { errors }
  } = methods;

  const handleOnSubmit = (emrRowFields: EmrRowFormFields) => {
    const { location, provider, countryCode, phone, gender, ...restFields } = emrRowFields;
    const emrRow = {
      ...restFields,
      gender: gender?.value,
      locationId: location.value.id,
      primaryPhysicianId: provider.value.id,
      phone: countryCode + phone
    };
    qaStore.addEmrRow(emrRow);
    onSubmit();
  };

  return (
    <FormModal
      methods={methods}
      defaultValues={{
        phone: '',
        countryCode: '+1',
        location: locationOptions[0],
        provider: providerOptions[0]
      }}
      title="Create EMR Row"
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(handleOnSubmit), text: 'Create', disabled: false }]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Box mb={40}>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField
              isRequired
              name="firstName"
              label="First Name"
              validate={ValidationUtils.isValidName}
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField
              isRequired
              name="lastName"
              label="Last Name"
              validate={ValidationUtils.isValidName}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <PatientLocationAutocomplete isRequired placeholder="Location..." />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <PatientProviderAutocomplete isRequired placeholder="Provider..." />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <StyledPhoneInput label="Phone Number" error={Boolean(errors.phone)} />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="patientId" label="Patient ID" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="patientMrn" label="Medical Record Number" />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="patientSsn" label="Social Security Number" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormDateField
              name="dob"
              label="Date of Birth"
              max={parseDateForInputField(new Date())}
              min="1900-01-01"
              error={errors.dob}
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormAutocomplete name="gender" label="Gender" options={genderOptions} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="zipCode" label="Zip Code" />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="address" label="Address" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="city" label="City" />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="state" label="State" />
          </Col>
        </Row>
      </Box>
    </FormModal>
  );
};
