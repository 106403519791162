// @ts-strict-ignore
import { get } from 'lodash/fp';
import moment from 'moment';

type TimeUnit = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;
export const WEEK_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 7;
export const MONTH_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 30;

const EPOCH_DATE = new Date(0);
export const DAYS_OF_WEEK_ARRAY = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
export const LONG_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_ONLY_FORMAT = 'M/D/YYYY';

export const dayAndHourTimeFormat = 'dd h:mm A';
export const shortDateTimeFormat = 'M/D/YY, h:mm A';
export const longDateTimeFormat = 'M/D/YYYY [at] h:mm A';

export function getDateMonthString(date: Date, isLong = false): string {
  const intlMonthOption = isLong ? 'long' : 'short';
  return date.toLocaleString('default', { month: intlMonthOption });
}

export function parseDate(dateStr: string) {
  return dateStr ? new Date(dateStr) : null;
}

export function parseDateForInputField(date: Date | string) {
  return formatDate(date, LONG_DATE_FORMAT);
}

export function removeTimeZone(dateString: string) {
  return dateString.replace('Z', '');
}

export function shortDateStringWithoutTimeZone(date: string): string {
  return formatDate(removeTimeZone(date), 'MMM D, YYYY');
}

// TODO: use fdates - but need to compare the formats with moment
export const formatDate = (
  date: string | Date,
  format: string = 'MMMM Do YYYY HH:mm',
  convertUTC: boolean = false
): string => {
  let dateObj = moment(date);
  if (convertUTC) {
    dateObj = moment.utc(date);
  }
  return dateObj.format(format);
};

export const secondsToFormattedTime = (timeInSeconds: number) => {
  const pad = (num: number, size: number) => ('000' + num).slice(size * -1);
  const time = Number(timeInSeconds).toFixed(3);
  const hours = Math.floor((time as any) / 60 / 60);
  const minutes = Math.floor((time as any) / 60) % 60;
  return pad(hours, 2) + ':' + pad(minutes, 2);
};

export const isAfterEpoch = (date: Date) => date > EPOCH_DATE;

export const dateSorter = (dateA: Date, dateB: Date, isAscending: boolean) => {
  let resultAscending;
  if (!dateA && dateB) {
    resultAscending = -1;
  } else if (dateA && !dateB) {
    resultAscending = 1;
  } else if (!dateA && !dateB) {
    return 0;
  } else {
    resultAscending = dateA < dateB ? -1 : 1;
  }

  return isAscending ? resultAscending : -resultAscending;
};

export const getDateSorter = (propertyName: string, isAscending?: boolean) => {
  return (a: any, b: any) => dateSorter(get(propertyName, a), get(propertyName, b), isAscending);
};

export const getTodayFormattedDate = (format = 'MM/DD/YYYY') => formatDate(new Date(), format);

export const getTimeInSecondsFromDate = (fromDate: Date) =>
  fromDate ? (new Date().getTime() - fromDate.getTime()) / 1000 : 0;

export function daysAgo(daysCount: number) {
  const result = new Date();
  result.setDate(result.getDate() - daysCount);

  return result;
}

export function secondsPassed(date: Date) {
  return moment(new Date()).diff(date, 'seconds');
}

// https://stackoverflow.com/questions/8152426/how-can-i-calculate-the-number-of-years-between-two-dates
export function calculateAge(date: Date) {
  var ageDifMs = Date.now() - date.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

// https://gist.github.com/davidrleonard/259fe449b1ec13bf7d87cde567ca0fde
/**
 * Implements all the behaviors of moment.fromNow(). Pass a
 * valid JavaScript Date object and the method will return the
 * time that has passed since that date in a human-readable
 * format. Passes the moment test suite for `fromNow()`.
 * See: https://momentjs.com/docs/#/displaying/fromnow/
 *
 * @example
 *
 *     var pastDate = new Date('2017-10-01T02:30');
 *     var message = fromNow(pastDate);
 *     //=> '2 days ago'
 *
 * @param  date - Native JavaScript Date object
 * @return {string}
 */
const NOW_STR = 'now';

interface FromOptions {
  short?: boolean;
  shortest?: boolean;
  useSeconds?: boolean;
}

export interface FromNowOptions extends FromOptions {
  agoPrefix?: string;
  agoSuffix?: string;
  fromNowPrefix?: string;
  fromNowSuffix?: string;
}

const FROM_NOW_OPTIONS_DEFAULTS: FromNowOptions = {
  fromNowSuffix: 'from now',
  agoSuffix: 'ago'
};

export function fromNow(date: Date, options?: FromNowOptions) {
  const fromDate = date || new Date();
  const now = new Date(Date.now());
  const flipDates = now < fromDate;

  const fromNowOptions = {
    ...FROM_NOW_OPTIONS_DEFAULTS,
    ...options
  };

  const timePhrase = from(now, fromDate, fromNowOptions);

  if (timePhrase === NOW_STR) {
    return timePhrase;
  } else {
    const prefix = flipDates ? fromNowOptions?.fromNowPrefix : fromNowOptions?.agoPrefix;
    const suffix = flipDates ? fromNowOptions?.fromNowSuffix : fromNowOptions?.agoSuffix;

    // remove empty string fragments to avoid unneeded spaces
    const completeTimePhrase = [prefix, timePhrase, suffix].filter((str) => !!str);
    return completeTimePhrase.join(' ');
  }
}

const shortDateDisplayMap: Partial<Record<TimeUnit, string>> = {
  month: 'mon',
  minute: 'min'
};

const shortestDateDisplayMap: Partial<Record<TimeUnit, string>> = {
  day: 'd',
  hour: 'h',
  second: 's',
  minute: 'm',
  year: 'y',
  month: 'mon'
};

function getRelativeTimeString(
  unit: number,
  unitName: TimeUnit,
  { short, shortest }: Omit<FromOptions, 'useSeconds'>
) {
  if (short) {
    const displayNameValue = shortDateDisplayMap[unitName];
    return ` ${unit}${displayNameValue ? ` ${displayNameValue}` : unitName[0]}`;
  }

  if (shortest) {
    const displayNameValue = shortestDateDisplayMap[unitName];
    return ` ${unit}${displayNameValue ? `${displayNameValue}` : unitName[0]}`;
  }

  return `${unit} ${unitName}${unit > 1 ? 's' : ''}`;
}

const fromDefaultOptions: FromOptions = {
  short: false,
  shortest: false,
  useSeconds: false
};

export function from(dateOrigin: Date, dateInPast: Date, options?: FromOptions) {
  const fromOptions = {
    ...fromDefaultOptions,
    ...options
  };
  const flipDates: boolean = dateOrigin < dateInPast;
  const multiplier = flipDates ? -1 : 1;

  const seconds = Math.floor((dateOrigin.getTime() - dateInPast.getTime()) / 1000) * multiplier;
  const years = Math.floor(seconds / 31536000);
  const months = Math.floor(seconds / 2592000);
  const days = Math.floor(seconds / 86400);

  if (days > 365) {
    return getRelativeTimeString(years, 'year', fromOptions);
  }

  if (days >= 30 && days <= 364) {
    return getRelativeTimeString(months, 'month', fromOptions);
  }

  const hours = Math.floor(seconds / 3600);

  if (hours >= 24 && days <= 29) {
    return getRelativeTimeString(days, 'day', fromOptions);
  }

  const minutes = Math.floor(seconds / 60);

  if (minutes >= 60 && hours <= 23) {
    return getRelativeTimeString(hours, 'hour', fromOptions);
  }
  if (seconds >= 60 && minutes <= 59) {
    return getRelativeTimeString(minutes, 'minute', fromOptions);
  }

  if (seconds <= 60 && !fromOptions.useSeconds) {
    return NOW_STR;
  }

  if (seconds >= 5 && seconds <= 60) {
    return getRelativeTimeString(seconds, 'second', fromOptions);
  }

  if (seconds >= 0 && seconds <= 4) {
    return NOW_STR;
  }
}

export function startOfDay(date: Date) {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function endOfDay(date: Date) {
  return new Date(date.setHours(23, 59, 59, 0));
}

export function startOfToday() {
  return startOfDay(new Date());
}

export function isBetweenDates(date: Date, startDate: Date, endDate: Date) {
  return date >= startDate && date <= endDate;
}

export function isToday(date: Date) {
  const now = new Date();
  const startOfDayDate = startOfDay(now);
  const endOfDayDate = endOfDay(now);
  return isBetweenDates(date, startOfDayDate, endOfDayDate);
}

export function isYesterday(date: Date) {
  const now = new Date();
  const yesterday = now.setDate(now.getDate() - 1);
  const yesterdayDate = new Date(yesterday);
  const yesterdayStart = startOfDay(yesterdayDate);
  const yesterdayEnd = endOfDay(yesterdayDate);
  return isBetweenDates(date, yesterdayStart, yesterdayEnd);
}

export function isSameDay(dateA: Date, dateB: Date) {
  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  );
}

// ignore nulls, if non of "timestamps" has valid ms value, return null
export function getEarliestDate(timestamps: number[]): Date | null {
  let date = null;
  const validTimestamps = timestamps.filter((time) => typeof time === 'number');
  if (validTimestamps.length > 0) {
    date = new Date(Math.min(...validTimestamps));
  }
  return date;
}

const getTimeOptions = () => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    const time = moment({ hour, minute: 0 }).format('h:mm A');
    hours.push({ value: hour, label: time });
  }
  return hours;
};

export const timeOptions = getTimeOptions();

export type HoursAndMinutes = { minutes: number; hours: number };
// example: 67 => { hours: 1, minutes: 7}
export const toHoursAndMinutes = (totalMinutes: number): HoursAndMinutes => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { minutes, hours };
};
