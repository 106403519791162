import { FunctionComponent, ReactNode } from 'react';

import { PopupCancelTrigger, PopupCancelTriggerBy } from 'views/Modals/BasePopup';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';

import './Popup.scss';

interface IPopupProps {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  onCancel: (cancelTrigger: PopupCancelTriggerBy) => void;
  id?: string;
}

const PopupContainer: FunctionComponent<IPopupProps> = ({
  isOpen,
  className,
  onCancel,
  children,
  id
}) => (
  <div className={`popup-screen ${isOpen ? 'open' : 'closed'} ${className}`} id={id}>
    <div className="popup-container">
      <IconButton
        className="cancel-btn"
        onClick={(e) => {
          e.stopPropagation();
          onCancel(PopupCancelTrigger.Icon);
        }}
      >
        <Icon.XThick />
      </IconButton>
      {children}
    </div>
  </div>
);

export default PopupContainer;
