// @ts-strict-ignore
import { PureComponent, ReactNode, MouseEvent } from 'react';

import classNames from 'classnames';

import { Testable } from 'utils/TypeUtils';

import PopupContainer from 'views/Widgets/PopupContainer';

import { FilledButton, OutlinedButton } from 'components/UIkit/atoms/Button';

import './DefaultPopup.scss';

export interface IDefaultPopupProps extends Testable {
  isOpen: boolean;
  title: string;
  description?: ReactNode;
  cancelText?: string;
  overrideCloseAction?: (cancelTrigger: PopupCancelTrigger) => void;
  action?: {
    actionText: string;
    actionCallback: (event: MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
  };
  onCancelClicked: (cancelTrigger: PopupCancelTrigger) => void;
  className?: string;
  nodeId?: string;
}

export enum PopupCancelTrigger {
  Icon = 'icon',
  Button = 'button'
}

export type PopupCancelTriggerBy = PopupCancelTrigger.Icon | PopupCancelTrigger.Button;

class BasePopup extends PureComponent<IDefaultPopupProps> {
  render() {
    const onCancelClicked = this.props.onCancelClicked;
    const cancelText = this.props.cancelText || 'Cancel';
    const actionText = this.props.action?.actionText;
    const onActionClicked = this.props.action?.actionCallback;
    const overrideCloseAction = this.props.overrideCloseAction;

    const runCancelAction = (
      event: MouseEvent<HTMLButtonElement>,
      action: (cancelTrigger: PopupCancelTrigger) => void
    ) => {
      event.stopPropagation();
      action(PopupCancelTrigger.Button);
    };

    const runSaveAction = (
      event: MouseEvent<HTMLButtonElement>,
      action: (event: MouseEvent<HTMLButtonElement>) => void
    ) => {
      event.stopPropagation();
      action(event);
    };

    return (
      <PopupContainer
        isOpen={this.props.isOpen}
        onCancel={overrideCloseAction ? overrideCloseAction : onCancelClicked}
        className={this.props.className}
        id={this.props.nodeId}
      >
        <div
          className="error-dialog-container"
          data-test-hook={this.props.isOpen ? this.props.testHook : ''}
        >
          <h3 className="error-title">{this.props.title}</h3>
          {this.props.description && (
            <div className="error-description">{this.props.description}</div>
          )}
          <div
            className={classNames('error-btns-container', {
              'with-action': this.props.action
            })}
          >
            {!Boolean(this.props.action) ? (
              <FilledButton
                onClick={(event) => runCancelAction(event, onCancelClicked)}
                fullWidth
                display="contents"
              >
                {cancelText}
              </FilledButton>
            ) : (
              <OutlinedButton
                onClick={(event) => runCancelAction(event, onCancelClicked)}
                size="medium"
                fullWidth
                mr={16}
                display="contents"
              >
                {cancelText}
              </OutlinedButton>
            )}

            {this.props.action && (
              <FilledButton
                onClick={(event) => runSaveAction(event, onActionClicked)}
                fullWidth
                display="contents"
                testHook={`popup-submit-btn${this.props.testHook ? `-${this.props.testHook}` : ''}`}
                disabled={this.props.action?.isLoading}
              >
                {actionText}
              </FilledButton>
            )}
          </div>
        </div>
      </PopupContainer>
    );
  }
}

export default BasePopup;
