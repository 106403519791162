import { FC, useMemo } from 'react';

import { trackOpenTextInputUsageAnalyticsEvent } from 'analytics/events/input-usage';

import { isArray } from 'lodash/fp';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { ActionMeta } from 'react-select';

import { useStores } from 'mobx/hooks/useStores';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { Testable } from 'utils/TypeUtils';

import { FEATURES } from 'constants/features';

import { OperatorTicketSource, TicketLightweightPathwayAnswer } from 'models/OperatorTicket';

import Patient from 'models/Patient';
import { TicketTypeKind } from 'models/TicketTypes';

import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';

import StyledFieldsForm from 'views/Widgets/StyledFieldsForm';

import { LightweightPathways } from 'components/LightweightPathway/LightweightPathways';
import { useLightweightPathways } from 'components/LightweightPathway/useLightweightPathways';
import FixedLoader from 'components/Loaders/FixedLoader';

import { TICKET_TYPES_SPECIFIC_DROPDOWN_KEY } from 'components/Ticket/TicketForms/OperatorCreateTicketsForm';
import { EDITED_TICKET_FIELD_NAME } from 'components/Ticket/TicketForms/OperatorEditTicketInfoForm';
import { TicketFormField } from 'components/Ticket/TicketForms/ticket.shared';
import {
  defaultNonSymptomUrgencyValue,
  defaultSymptomUrgencyValue,
  getUrgencyOptions,
  useTicketTypesOptions
} from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';

import PatientLocationFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormLocation';
import PatientProviderFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormProvider';
import ClinicianSelectionFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/TicketsInfoFormAssignee';
import {
  FormAutocompleteInlineLabel,
  FormTicketTypeAutocomplete,
  FormTicketTypeMultiAutocomplete
} from 'components/UIkit/atoms/Dropdown';
import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';
import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';
import { FormRichText } from 'components/UIkit/atoms/RichText/FormRichText';
import { getDefaultToolbarListeners } from 'components/UIkit/atoms/RichText/Plugins/ToolbarPlugin/ToolbarPlugin.utils';

interface IProps extends Testable {
  patient: Patient;
  errors: any;
  ticketSource?: OperatorTicketSource;
  categoryId: string;
  ticketSubTypeIds: string[];
  onDropdownChange?: (
    actionMeta: ActionMeta<any>,
    name: 'Provider' | 'Location' | 'Assignee' | 'Urgency',
    isKeyboardSource: boolean,
    hasPreviousValue?: boolean
  ) => void;
  ticketLightweightPathwaysAnswers: TicketLightweightPathwayAnswer[];
}

const OperatorEditTicketInfoFormItem: FC<IProps> = ({
  patient,
  errors,
  ticketSource,
  testHook,
  onDropdownChange = () => null,
  ticketLightweightPathwaysAnswers
}) => {
  const { ticketTypesStore, settingsStore } = useStores();
  const isLightweightPathwaysFeatureEnabled = settingsStore.hasFeature(
    FEATURES.LIGHTWEIGHT_PATHWAYS
  );
  const { lightweightPathways, handleAnswerChanged, isLoading, handleTextQuestionBlur } =
    useLightweightPathways(
      isLightweightPathwaysFeatureEnabled,
      'edit ticket',
      {
        symptomsDropdown: `${EDITED_TICKET_FIELD_NAME}[${TICKET_TYPES_SPECIFIC_DROPDOWN_KEY}]`,
        answers: `${EDITED_TICKET_FIELD_NAME}.lightweightPathwaysAnswers`,
        urgency: `${EDITED_TICKET_FIELD_NAME}.urgency`,
        pathwaySummary: `${EDITED_TICKET_FIELD_NAME}.lightweightPathwaySummaries`
      },
      ticketLightweightPathwaysAnswers
    );

  const isLightweightPathwaysQuestionsVisible =
    isLightweightPathwaysFeatureEnabled && lightweightPathways.length > 0;

  const { watch, setValue } = useFormContext();
  const [locationId, providerId, currentTicketTypeSelectorValue, currentTicket] = watch([
    `${EDITED_TICKET_FIELD_NAME}.locationId`,
    `${EDITED_TICKET_FIELD_NAME}.providerId`,
    `${EDITED_TICKET_FIELD_NAME}[${TICKET_TYPES_SPECIFIC_DROPDOWN_KEY}]`,
    'ticket'
  ]);

  const updateTicketFields = (
    ticketFields: Partial<Record<keyof TicketFormField, ValueOf<TicketFormField>>>
  ) => {
    Object.entries(ticketFields).forEach(([key, value]) =>
      setValue(`${EDITED_TICKET_FIELD_NAME}.${key}`, value)
    );
  };

  const handleMultiSymptomTicketTypeSelect = (selectedOption: TicketSubTypeOption[]) => {
    const categoryNode = ticketTypesStore.getCategoryByParentId(selectedOption[0].parentId);

    const currentTicketUrgency = currentTicket.urgency
      ? currentTicket.urgency
      : defaultSymptomUrgencyValue;

    updateTicketFields({
      parentId: selectedOption[0].parentId,
      categoryId: String(categoryNode.id),
      urgency: currentTicketUrgency,
      ticketSubTypeIds: [
        ...currentTicket.ticketSubTypeIds,
        selectedOption[selectedOption.length - 1].value //we add the id of the last ticket subtype that was added
      ]
    });
  };

  //selectedOption is an object - can be a symptom or non symptom option
  const handleSingleTicketTypeSelect = (selectedOption: TicketSubTypeOption) => {
    const isSymptom = selectedOption.parentKind === TicketTypeKind.symptom;
    const categoryNode = ticketTypesStore.getCategoryByParentId(selectedOption.parentId);

    updateTicketFields({
      parentId: selectedOption.parentId,
      ticketSubTypeIds: [selectedOption.value],
      categoryId: String(categoryNode.id),
      urgency: isSymptom ? defaultSymptomUrgencyValue : currentTicket.urgency
    });
  };

  const handleTicketTypeSelect = (selectedOption: TicketSubTypeOption | TicketSubTypeOption[]) => {
    const isMultiSymptomsTicket = isArray(selectedOption); //selectedOption is an array -> multiple symptoms

    if (isMultiSymptomsTicket) {
      handleMultiSymptomTicketTypeSelect(selectedOption as TicketSubTypeOption[]);
      return;
    }

    handleSingleTicketTypeSelect(selectedOption as TicketSubTypeOption);
  };

  const handleSymptomTicketTypeRemove = (selectedOption: TicketSubTypeOption[] | null) => {
    if (!selectedOption) {
      //selectedOption is null -> last ticket type was removed
      updateTicketFields({
        categoryId: null,
        parentId: null,
        ticketSubTypeIds: null,
        ticketTypeSelectorValue: null,
        urgency: defaultNonSymptomUrgencyValue
      });
      return;
    }

    updateTicketFields({ ticketSubTypeIds: selectedOption.map((symptom) => symptom.value) });
  };

  const onTicketTypeChange = (
    selectedOption: TicketSubTypeOption,
    actionMeta: ActionMeta<ISelectOption<any>>
  ) => {
    if (actionMeta.action === SelectActionMetaName.Select) {
      handleTicketTypeSelect(selectedOption);
    }

    if (actionMeta.action === SelectActionMetaName.Remove) {
      // @ts-ignore
      handleSymptomTicketTypeRemove(selectedOption);
    }
  };

  const isSymptomsTicket =
    isArray(currentTicketTypeSelectorValue) ||
    currentTicketTypeSelectorValue?.parentKind === TicketTypeKind.symptom;

  const ticketTypeOptions = useTicketTypesOptions({
    filterFn: (node) => node.isActive && node.reportableInAll,
    showFullNameForNonSymptomTicketTypes: true
  });

  const groupedTicketTypesOptions = useMemo(() => {
    if (isSymptomsTicket) {
      return ticketTypeOptions.filter((t) => t.kind === TicketTypeKind.symptom);
    }

    return ticketTypeOptions;
  }, [ticketTypeOptions, isSymptomsTicket]);

  const FormTicketTypeSelector = isSymptomsTicket
    ? FormTicketTypeMultiAutocomplete
    : FormTicketTypeAutocomplete;

  const formTicketTypeSelectorAdditionalProps: { noBorderRadiusBottom?: boolean } = {};

  if (isSymptomsTicket) {
    formTicketTypeSelectorAdditionalProps.noBorderRadiusBottom = true;
  }

  const fields = [
    <PatientProviderFormField
      key={`${EDITED_TICKET_FIELD_NAME}.providerId`}
      fieldName={`${EDITED_TICKET_FIELD_NAME}.providerId`}
      isRequired
      isInline
      patient={patient}
      onChange={(_, actionMeta, eventKey) => {
        onDropdownChange(actionMeta, 'Provider', eventKey === 'Enter', Boolean(providerId));
      }}
    />,

    <PatientLocationFormField
      key={`${EDITED_TICKET_FIELD_NAME}.locationId`}
      fieldName={`${EDITED_TICKET_FIELD_NAME}.locationId`}
      isRequired
      isInline
      patient={patient}
      onChange={(_, actionMeta, eventKey) =>
        onDropdownChange(actionMeta, 'Location', eventKey === 'Enter', Boolean(locationId))
      }
    />,

    <ClinicianSelectionFormField
      key={`${EDITED_TICKET_FIELD_NAME}.assignee`}
      fieldName={`${EDITED_TICKET_FIELD_NAME}.assignee`}
      isInline
      placeholder="Unassigned"
      label="Assignee"
      withClearableOption
      onChange={(_, actionMeta, eventKey) =>
        onDropdownChange(actionMeta, 'Assignee', eventKey === 'Enter')
      }
    />,

    <FormAutocompleteInlineLabel
      label="Urgency"
      key={`${EDITED_TICKET_FIELD_NAME}.urgency`}
      name={`${EDITED_TICKET_FIELD_NAME}.urgency`}
      options={getUrgencyOptions(isSymptomsTicket)}
      sortAlphabetically={false}
      defaultValue={defaultNonSymptomUrgencyValue}
      isClearable={false}
      onChange={(_, actionMeta, eventKey) =>
        onDropdownChange(actionMeta, 'Urgency', eventKey === 'Enter')
      }
    />
  ];

  return (
    <>
      {isLoading && <FixedLoader />}

      <StyledFieldsForm
        testHook={testHook}
        mainField={
          <>
            <FormTicketTypeSelector
              isRequired
              options={groupedTicketTypesOptions}
              name={`${EDITED_TICKET_FIELD_NAME}.${TICKET_TYPES_SPECIFIC_DROPDOWN_KEY}`}
              placeholder="Select Ticket Type"
              // @ts-ignore
              onChange={onTicketTypeChange}
              label="Ticket Types"
              testHook="editTicketTypes"
              {...formTicketTypeSelectorAdditionalProps}
            />

            <FormRichText
              name={`${EDITED_TICKET_FIELD_NAME}.notes`}
              placeholder="Add notes here..."
              disabled={ticketSource === OperatorTicketSource.Patient}
              size="medium"
              onBlur={(_, currentValue, valueAfterFocus) => {
                trackOpenTextInputUsageAnalyticsEvent(
                  currentValue,
                  valueAfterFocus,
                  'ticket notes'
                );
              }}
              isPartialBorder
              toolbarListeners={getDefaultToolbarListeners(patient.id)}
              testHook={`${testHook}_ticketNotes`}
            />

            {isLightweightPathwaysQuestionsVisible && (
              <LightweightPathways
                lightweightPathways={lightweightPathways}
                answers={watch(`${EDITED_TICKET_FIELD_NAME}.lightweightPathwaysAnswers`)}
                handleAnswerChanged={handleAnswerChanged}
                handleTextQuestionBlur={handleTextQuestionBlur}
              />
            )}
          </>
        }
        extraFields={fields}
      />
    </>
  );
};

export default observer(OperatorEditTicketInfoFormItem);
