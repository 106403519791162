import { FC } from 'react';

import { Popups } from 'analytics/events/popup';

import { Testable } from 'utils/TypeUtils';

import { Popup } from 'views/Modals/Popup';

interface Props extends Testable {
  isOpen: boolean;
  onAction: () => void;
  onCancel: () => void;
}

export const DisableTicketCategoryPopup: FC<Props> = ({ isOpen, onAction, onCancel, testHook }) => (
  <Popup
    isOpen={isOpen}
    title="Disable Ticket Category for Operators and Patients? "
    description={
      <div>
        Disabling this entire category will no longer allow the creation of ticket types within this
        category, for operators and patients.
      </div>
    }
    action={{ actionText: 'Disable Category', actionCallback: onAction }}
    onCancelClicked={onCancel}
    testHook={testHook}
    id={Popups.DisableTicketCategory}
  />
);
