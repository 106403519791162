// @ts-strict-ignore
import {
  SymptomReportableIn,
  TicketTypeDisplayName,
  TicketTypeCreationAllowance,
  TicketTypeKind
} from 'models/TicketTypes';

/**For types tree leves and names (Categories => Types => Subtypes) see TicketTypeTree.ts
 * Ticket Type Terminoloy
 *  Category - top level node (has no parents)
 *  Deleted Type - a subtype which marked as "deleted". might still be used by tickets. categories and types cannot be deleted.
 *  Allowed Type - a type/subtype which allowed to be used for creating new tickets.
 *  Active Type  - can be used for creating a new ticket. should be "allowed" and NOT "deleted"
 *  Editable Type - a subtype which is institution specific. default types/subtypes are not editable.
 *  In-use Type - a type which is NOT deleted, OR being used by active ticket. should appear on filters.
 *
 *  Note: symptom types cannot be edited/deleted/created, and always active
 */
export class TicketTypeNode {
  id: number;
  name: string;
  kind: TicketTypeKind;
  children: TicketTypeNode[];
  parent: TicketTypeNode | null;
  displaySettings: TicketTypeCreationAllowance[];
  displayNames: TicketTypeDisplayName[];
  isDeleted: boolean;
  isEditable: boolean;
  hasActiveTickets?: boolean;
  reportableIn?: SymptomReportableIn;

  get isInUse(): boolean {
    return this.isActive || this.hasActiveTickets;
  }

  get reportableInAll() {
    if (this.kind === TicketTypeKind.other) {
      return true;
    }

    const isCategory = this.parent === null;
    const isSymptom = this.kind === TicketTypeKind.symptom;
    const reportableInAll = this.reportableIn === SymptomReportableIn.All;

    return isCategory || (isSymptom && reportableInAll);
  }

  get isActive(): boolean {
    return !this.isDeleted && this.isAllowed;
  }

  get isAllowed(): boolean {
    if (!this.displaySettings) {
      return true;
    }

    return this.displaySettings.includes(TicketTypeCreationAllowance.OPERATOR);
  }

  // entire type name (type or type-subtype ), not including category name
  get typeName(): string {
    if (this.parent && this.parent.parent) {
      // node is subtype - return type and subtype names
      return `${this.parent.name} - ${this.name}`;
    }
    return this.name;
  }

  get fullName(): string {
    if (this.parent) {
      return `${this.category.name}: ${this.typeName}`;
    }
    return this.name;
  }

  get category(): TicketTypeNode {
    if (!this.parent) {
      return this;
    }
    let category = this.parent;
    while (category.parent) {
      category = category.parent;
    }
    return category;
  }

  get isOperatorsCheckboxChecked(): boolean {
    return !this.isCategoryEnabled
      ? false
      : this.displaySettings?.includes(TicketTypeCreationAllowance.OPERATOR);
  }

  get isPatientsCheckboxChecked(): boolean {
    return !this.isCategoryEnabled
      ? false
      : this.displaySettings?.includes(TicketTypeCreationAllowance.PATIENT) &&
          this.isOperatorsCheckboxChecked;
  }

  get isCategoryEnabled(): boolean {
    return this.category.displaySettings.length > 0;
  }

  static createFromNode(node: TicketTypeNode) {
    return new TicketTypeNode(
      node.id,
      node.name,
      node.kind,
      node.isEditable,
      node.displaySettings,
      node.isDeleted,
      node.displayNames,
      node.hasActiveTickets,
      node.reportableIn
    );
  }

  constructor(
    id: number,
    name: string,
    kind: TicketTypeKind,
    isEditable: boolean,
    displaySettings: TicketTypeCreationAllowance[],
    isDeleted: boolean,
    displayNames: TicketTypeDisplayName[],
    hasActiveTickets?: boolean,
    reportableIn?: SymptomReportableIn
  ) {
    this.id = id;
    this.name = name;
    this.kind = kind;
    this.isEditable = isEditable;
    this.children = [];
    this.parent = null;
    this.displaySettings = displaySettings || [];
    this.displayNames = displayNames || [];
    this.isDeleted = isDeleted;
    this.hasActiveTickets = hasActiveTickets;
    this.reportableIn = reportableIn;
  }
}
