import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';

import App from 'App';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import moment from 'moment';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';

import 'react-rangeslider/lib/index.css';
import { Router } from 'react-router-dom';
import 'react-widgets/dist/css/react-widgets.css';
import momentLocalizer from 'react-widgets-moment';

import 'scss/style.scss';

import { rootStore } from 'mobx/stores';

import { attachGlobalErrorHandlers } from 'services/errorHandlingService';
import history from 'services/history';
import { initSentry } from 'services/sentryService';

import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from 'constants/config';

import { theme } from 'components/UIkit/theme';

import './apple-app-site-association';
import './i18n';

// https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

declare global {
  interface Window {
    mixpanel: OverridedMixpanel;
  }
}

window.mixpanel = mixpanel;

attachGlobalErrorHandlers();
momentLocalizer();
configure({
  enforceActions: 'observed'
});

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: '%d month',
    MM: '%d month',
    y: '%dy',
    yy: '%dy'
  }
});

initSentry(history);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <Provider {...rootStore.stores}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={AUTH0_AUDIENCE}
        scope="read:current_user update:current_user_metadata"
        useRefreshTokens
      >
        {/* @ts-ignore react18 require explicit children props, but react-router v5  does not provide it */}
        <Router history={history}>
          <App />
        </Router>
      </Auth0Provider>
    </Provider>
  </ThemeProvider>
);
