// @ts-strict-ignore
import { action, observable, runInAction, makeObservable, computed } from 'mobx';

import { DataMap } from 'mobx/stores/DataMap';

import CareManagementFetcher from 'fetchers/CareManagementFetcher';
import { ConstantsFetcher } from 'fetchers/ConstantsFetcher';
import { CmStatusResponseType } from 'fetchers/responses/care-management.response';
import { TagCreateAttributes, TagsFetcher, TagUpdateAttributes } from 'fetchers/TagsFetcher';

import { stringCaseInsensitiveCompare } from 'utils/StringUtils';

import { ALL_ROLES, NO_ROLE } from 'constants/tasks.const';

import { ClinicianCredential } from 'models/ClinicianCredential';
import { DenyCallbackReason } from 'models/DenyCallbackReason';
import { CmEpisodeTypes, EpisodeRuleset, EpisodeTypes } from 'models/Episode';

import { IIcdCategory, IIcdCode } from 'models/Icd';
import MissedDosesReasons from 'models/MissedDosesReasons';
import { PatientEpisodeEndReason } from 'models/PatientEpisodeEndReason';
import PatientTag from 'models/PatientTag';
import RegimenTemplate from 'models/RegimenTemplate';
import { SelectOption } from 'models/SelectOption';
import { SymptomType } from 'models/TicketTypes';

import { ISelectOption, sortOptionsByLocale } from 'views/Widgets/StyledSelect';

import { RootStore } from './rootStore';

type ServerMetadata<T> = {
  id: number;
  isDeleted?: boolean;
  name: T;
};

export type EpisodeMetadata = {
  createdAt: string;
  duration: number;
  ruleset: EpisodeRuleset;
  type: EpisodeTypes | CmEpisodeTypes;
  id: number;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
};

export type TaskRoleOption = ServerMetadata<string>;

export interface InstitutionMetadata {
  distressCauses: SymptomType[];
  taskRoles: TaskRoleOption[];
  episodes: EpisodeMetadata[];
  episodeEndReasons: PatientEpisodeEndReason[];
  patientTags: PatientTag[];
  missedDosesReasons: MissedDosesReasons[];
  denyCallbackReasons: DenyCallbackReason[];
  credentials: ClinicianCredential[];
}

export const ALL_ROLES_OPTION = {
  label: 'All Roles',
  value: { id: ALL_ROLES, name: 'All Roles' }
};

export const NO_ROLE_OPTION = {
  label: 'No Role',
  value: { id: NO_ROLE, name: 'No Role' }
};

export const defaultTagSort = (a: PatientTag, b: PatientTag) => {
  if (!a.institutionId || !b.institutionId) {
    return Boolean(a.institutionId) ? 1 : -1;
  }
  return stringCaseInsensitiveCompare(a.name, b.name);
};

export default class ConstantsStore {
  @observable
  yesNoQuestions: Array<{ id: number; questionText: string }>;

  @observable
  icdCategories: IIcdCategory[];

  @observable
  episodeTemplatesMetadataMap: DataMap<EpisodeMetadata> = new DataMap<EpisodeMetadata>();

  @observable
  taskRoles: DataMap<TaskRoleOption> = new DataMap<TaskRoleOption>();

  @observable
  denyCallbackReasons: DataMap<DenyCallbackReason> = new DataMap<DenyCallbackReason>();

  @observable
  tags: DataMap<PatientTag> = new DataMap<PatientTag>();

  @observable
  regimens: DataMap<RegimenTemplate> = new DataMap<RegimenTemplate>();

  @observable
  initialConstantsLoaded: boolean = false;

  @observable
  cmStatuses = new DataMap<CmStatusResponseType>();

  @observable
  clinicianCredential: DataMap<ClinicianCredential> = new DataMap<ClinicianCredential>();

  @observable
  symptomsMap = new DataMap<SymptomType>();

  @observable
  missedDosesReasonsMap = new DataMap<MissedDosesReasons>();

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async systemLoadInit() {
    await Promise.all([
      // must calls we need for before any view loads
      this.rootStore.stores.settingsStore.init(),
      this.rootStore.stores.departmentsStore.fetchDepartments(),
      this.rootStore.stores.cliniciansStore.fetchAssigneesForFilterSelect(),
      this.fetchInstitutionMetadata(),
      this.fetchRegimenTemplates(),
      this.rootStore.stores.ticketTypesStore.fetchOperatorTicketTypes(),
      this.rootStore.stores.pathwaysStore.fetchPathwayBasicInfo(),
      this.rootStore.stores.locationsStore.fetchPatientLocations(),
      this.rootStore.stores.providersStore.fetchPatientProviders()
    ]);
    runInAction(() => {
      this.initialConstantsLoaded = true;
    });
  }

  @action
  clear() {
    this.initialConstantsLoaded = false;
  }

  searchIcdCodeCategories(searchValue: string = '') {
    return ConstantsFetcher.getIcdCategories(searchValue);
  }

  @action
  async fetchCareManagementFilterData() {
    const response = await CareManagementFetcher.fetchCareManagementFilterData();

    runInAction(() => {
      this.cmStatuses.setItems(response.cmStatuses);
    });

    return response;
  }

  getIcdCodeById(codeId: number): IIcdCode {
    let code = null;
    this.icdCategories.some((category) => {
      code = category.icdCodes.find((icdCode) => icdCode.id === codeId);
      return !!code;
    });
    return code;
  }

  getEpisodeById(episodeId: number): EpisodeMetadata {
    return this.episodeTemplatesMetadataMap.get(episodeId);
  }

  getDenyCallbackReasonById(reasonId: number): DenyCallbackReason {
    return this.denyCallbackReasons.get(reasonId);
  }

  getRoleByIdWithAllAndNoRoles(roleId: number | null) {
    switch (roleId) {
      case ALL_ROLES:
        return { id: ALL_ROLES, name: ALL_ROLES_OPTION.label };
      case NO_ROLE:
        return { id: NO_ROLE, name: NO_ROLE_OPTION.label };
      default:
        return this.taskRoles.get(roleId);
    }
  }

  getCauseNameById(causeId: number) {
    return this.symptomsMap.get(causeId)?.name || '';
  }

  async fetchRegimenTemplates() {
    let regimens: RegimenTemplate[];
    regimens = await ConstantsFetcher.fetchRegimenTemplates();
    runInAction(() => {
      this.regimens.setItems(regimens);
    });
  }

  getYesNoQuestions() {
    return ConstantsFetcher.getYesNoQuestions().then((questions) => {
      runInAction(() => {
        this.yesNoQuestions = questions;
      });
    });
  }

  @computed
  get rolesForSelect(): ISelectOption<TaskRoleOption>[] {
    const rolesOptions = this.taskRoles.items
      .filter((option) => !option.isDeleted)
      .map((role: TaskRoleOption) => ({
        value: role,
        label: role.name
      }))
      .sort(sortOptionsByLocale);

    return [NO_ROLE_OPTION, ALL_ROLES_OPTION, ...rolesOptions];
  }

  async fetchInstitutionMetadata() {
    const institutionMetadata = await ConstantsFetcher.getInstitutionMetadata();
    const {
      taskRoles,
      episodes,
      episodeEndReasons,
      patientTags,
      distressCauses,
      missedDosesReasons,
      denyCallbackReasons,
      credentials
    } = institutionMetadata;

    runInAction(() => {
      this.episodeTemplatesMetadataMap.setItems(episodes);
      this.taskRoles.setItems(taskRoles);
      this.denyCallbackReasons.setItems(denyCallbackReasons);
      episodeEndReasons &&
        this.rootStore.stores.patientEpisodesStore.episodeEndReasons.setItems(episodeEndReasons);
      this.tags.setItems(patientTags);
      this.clinicianCredential.setItems(credentials);
      this.symptomsMap.setItems(distressCauses);
      this.missedDosesReasonsMap.setItems(missedDosesReasons);
    });
  }

  getClinicianCredentialById(credentialId?: number) {
    if (!credentialId) {
      return null;
    }

    return this.clinicianCredential.get(credentialId);
  }

  @computed
  get regimenTemplatesForSelect() {
    return this.regimens.items.map((regimen) => ({
      value: regimen.id,
      label: regimen.name
    }));
  }

  async createTag(tag: TagCreateAttributes) {
    const tagId = await TagsFetcher.createTag(tag);
    this.tags.set(tagId, {
      id: tagId,
      institutionId: this.rootStore.stores.departmentsStore.rootDepartmentId,
      ...tag
    });
  }

  async updateTag(tag: TagUpdateAttributes) {
    await TagsFetcher.updateTag(tag);
    this.tags.set(tag.id, tag);
  }

  async deleteTag(tagId: number) {
    await TagsFetcher.deleteTag(tagId);
    this.tags.delete(tagId);
  }

  @computed
  get activeTags() {
    return this.tags.items.filter((tag) => !tag.isDeleted);
  }

  @computed
  get activeTagsForSelect() {
    return this.activeTags.sort(defaultTagSort).map((tag) => ({
      value: tag.id,
      label: tag.name
    }));
  }

  getTagsByIds = (tagIds: number[]) => {
    return tagIds.map((id) => this.tags.get(id)).filter((tag) => Boolean(tag));
  };

  getSymptomsByPathwayId(pathwayId: string) {
    return this.symptomsMap.items.filter((symptom) => symptom.pathwayId === pathwayId);
  }

  getSymptomOptionsForPathwayById = (pathwayId: string): SelectOption<number>[] =>
    this.getSymptomsByPathwayId(pathwayId).map((symptomItem) => ({
      label: symptomItem.name,
      value: symptomItem.id
    }));
}
