import { FC, useState } from 'react';

import { Box, css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Popups } from 'analytics/events/popup';
import { observer } from 'mobx-react';

import {
  DISPLAY_NAME_MAX_LENGTH,
  HIDDEN_ERROR_MESSAGE_TEXT,
  SERVER_ERROR
} from 'utils/TicketType.utils';

import { Popup } from 'views/Modals/Popup';

import { DeleteTicketSubTypePopup } from 'views/Pages/PracticeManagement/TicketTypes/Modals/DeleteTicketSubTypePopup';

import {
  StyledError,
  StyledSectionTitle
} from 'views/Pages/PracticeManagement/TicketTypes/Modals/shared';
import { useEditTicketTypeOrSubType } from 'views/Pages/PracticeManagement/TicketTypes/useEditTicketTypeOrSubType';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  onClose: () => void;
  resetDataAfterClose: () => void;
  ticketSubTypeId: number;
  isOpen: boolean;
}

export const EditTicketSubTypeModal: FC<Props> = observer(
  ({ onClose, ticketSubTypeId, isOpen, resetDataAfterClose }) => {
    const [isDeleteTicketSubTypePopupOpen, setIsDeleteTicketSubTypePopupOpen] = useState(false);
    const [isUpdateDisplayNamesPopupOpen, setIsUpdateDisplayNamesPopupOpen] = useState(false);

    const {
      methods,
      isSubmitDisabled,
      errors,
      ticketTypeOrSubType,
      isInstructionsSectionVisible,
      isDisplayNamesSectionVisible,
      isBuiltInTicketSubType,
      isLoading,
      editTicketTypeOrSubType,
      defaultEnglishDisplayName,
      defaultSpanishDisplayName,
      formDefaultValues,
      shouldOpenUpdateDisplayNamesPopup
    } = useEditTicketTypeOrSubType(ticketSubTypeId, onClose, true);

    const { handleSubmit, register } = methods;

    const onUpdateDisplayNamesClicked = () => {
      if (isUpdateDisplayNamesPopupOpen) {
        setIsUpdateDisplayNamesPopupOpen(false);
      }

      editTicketTypeOrSubType();
    };

    return (
      <>
        <FormModal
          defaultValues={formDefaultValues}
          methods={methods}
          isOpen={isOpen}
          title={`Ticket Sub-Type: ${ticketTypeOrSubType?.name}`}
          confirmActions={[
            {
              onClick: shouldOpenUpdateDisplayNamesPopup
                ? () => setIsUpdateDisplayNamesPopupOpen(true)
                : handleSubmit(editTicketTypeOrSubType),
              text: isLoading ? 'Saving…' : 'Save',
              disabled: isSubmitDisabled
            }
          ]}
          closeAction={{ onClick: onClose, disabled: false }}
          resetDataAfterClose={resetDataAfterClose}
          secondaryAction={{
            isVisible: !isBuiltInTicketSubType,
            onClick: () => setIsDeleteTicketSubTypePopupOpen(true),
            text: 'Delete',
            disabled: false,
            testHook: 'delete-ticket-subtype-btn',
            type: 'button'
          }}
        >
          {!isBuiltInTicketSubType && (
            <Box mb={isDisplayNamesSectionVisible ? 24 : 28}>
              <RHFStyledInput
                isRequired
                label="Name for Operators"
                name="name"
                register={register}
                error={Boolean(errors.name)}
              />

              {errors.name?.type === SERVER_ERROR ? (
                <StyledError isError={Boolean(errors.name)} variant="helper-text">
                  {errors.name?.message}
                </StyledError>
              ) : (
                <StyledHelperText variant="helper-text" isError={Boolean(errors.name)}>
                  Renaming this ticket type will affect all existing tickets that have been assigned
                  to it.
                </StyledHelperText>
              )}
            </Box>
          )}

          {isDisplayNamesSectionVisible && (
            <>
              <StyledSectionTitle variant="h4">Display Name for Patient App</StyledSectionTitle>

              <Grid container columnSpacing={20} mb={isInstructionsSectionVisible ? 24 : 28}>
                <Grid item xs={6}>
                  <RHFStyledInput
                    label="English"
                    name="enDisplayName"
                    register={register}
                    error={Boolean(errors.enDisplayName)}
                    maxLength={DISPLAY_NAME_MAX_LENGTH}
                    placeholder={defaultEnglishDisplayName?.name}
                  />

                  <StyledError isError={Boolean(errors.enDisplayName)} variant="helper-text">
                    {errors.enDisplayName?.message || HIDDEN_ERROR_MESSAGE_TEXT}
                  </StyledError>
                </Grid>

                <Grid item xs={6}>
                  <RHFStyledInput
                    label="Spanish"
                    name="spDisplayName"
                    register={register}
                    error={Boolean(errors.spDisplayName)}
                    maxLength={DISPLAY_NAME_MAX_LENGTH}
                    placeholder={defaultSpanishDisplayName?.name}
                  />

                  <StyledError isError={Boolean(errors.spDisplayName)} variant="helper-text">
                    {errors.spDisplayName?.message || HIDDEN_ERROR_MESSAGE_TEXT}
                  </StyledError>
                </Grid>
              </Grid>
            </>
          )}

          {isInstructionsSectionVisible && (
            <>
              <StyledSectionTitle variant="h4">Instructions for Patient App</StyledSectionTitle>

              <Grid container columnSpacing={20} mb={28}>
                <Grid item xs={6}>
                  <RHFStyledInput
                    label="English"
                    name="enInstructions"
                    register={register}
                    type="textarea"
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <RHFStyledInput
                    label="Spanish"
                    name="spInstructions"
                    register={register}
                    type="textarea"
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          )}
        </FormModal>

        <DeleteTicketSubTypePopup
          isOpen={isDeleteTicketSubTypePopupOpen}
          ticketSubTypeId={ticketSubTypeId}
          onAction={() => {
            setIsDeleteTicketSubTypePopupOpen(false);
            onClose();
          }}
          onCancel={() => setIsDeleteTicketSubTypePopupOpen(false)}
          isChildModal
        />

        <Popup
          isOpen={isUpdateDisplayNamesPopupOpen}
          title="Display Name(s) for Patient App Will Be Updated"
          onCancelClicked={() => setIsUpdateDisplayNamesPopupOpen(false)}
          description={
            <>
              Default display name(s) for the patient app will be updated according to this new
              ‘Name for Operators’. <br />
              <br />
              If you prefer not to change the name of this Ticket Sub-Type in the Canopy mobile app,
              press “Cancel” and enter Display Name(s) first.{' '}
            </>
          }
          action={{
            actionText: 'Update Name',
            actionCallback: handleSubmit(onUpdateDisplayNamesClicked)
          }}
          id={Popups.DisplayNamesUpdate}
        />
      </>
    );
  }
);

const StyledHelperText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
  ({ theme, isError }) => css`
    color: ${isError ? theme.palette.error.dark : theme.palette.text.disabled};
  `
);
