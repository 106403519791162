// @ts-strict-ignore
import { castArray, findLastIndex, groupBy, isArray, map, pick, sum } from 'lodash';

import { findIndex, get, last, max, omit } from 'lodash/fp';

import { action, computed, makeObservable, observable, runInAction, values } from 'mobx';
import { computedFn } from 'mobx-utils';

import { ConstantsFetcher } from 'fetchers/ConstantsFetcher';

import { isDependentQuestionActive } from 'utils/PathwayUtils';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import Call from 'models/Call';
import CallPathwayAnswer from 'models/CallPathwayAnswer';
import OrderedCallPathwayAnswer from 'models/OrderedCallPathwayAnswer';
import { PathwayQuestionSummary } from 'models/PathwayQuestionSummary';
import {
  BaseQuestion,
  PathwayBasicInfo,
  PathwayCategoryConditionType,
  PathwayOptionAction,
  PathwayOptionOutcome,
  PathwayQuestionCategory,
  PathwayQuestionOutcome,
  PathwayQuestionTypes,
  PathwaysData,
  PathwaySearchResult,
  PathwaySelectionAnswer,
  PathwayTemplate,
  PathwayTemplateQuestion,
  PathwayTextArrayAnswer
} from 'models/PathwayTemplates';

import { PathwayType } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import { BASIC_QUESTION_IDS } from 'views/Patient/PatientMain/PathwaysView.constants';

export default class PathwayStore {
  @observable
  pathwaysData: PathwaysData;

  @observable
  pathwayBasicInfos: PathwayBasicInfo[] = [];

  @observable
  currentPathwayInfo: PathwayBasicInfo;

  @observable
  answeredQuestions: CallPathwayAnswer[] = [];

  @observable
  highlightedQuestionId: string;

  @observable
  isPreviewMode: boolean = false;

  private categoriesShowFilterHandlers: {
    [key in PathwayCategoryConditionType]: (category: PathwayQuestionCategory) => boolean;
  };
  constructor() {
    this.categoriesShowFilterHandlers = {
      [PathwayCategoryConditionType.SCORE]: this.categoriesScoreFilter,
      [PathwayCategoryConditionType.ALERT_EXACT]: this.alertExactCategoryFilter,
      [PathwayCategoryConditionType.ALERT_MINIMUM]: this.alertMinimumCategoryFilter
    };
    makeObservable(this);
  }

  @action
  setPathwaysData = (pathwaysData: PathwaysData) => {
    this.pathwaysData = pathwaysData;
  };

  @action
  setPathwayBasicInfos = (pathwayBasicInfos: PathwayBasicInfo[]) => {
    this.pathwayBasicInfos = pathwayBasicInfos;
  };

  @action
  setIsPreviewMode = (isPreviewMode: boolean) => {
    this.isPreviewMode = isPreviewMode;
  };

  async fetchPathwayBasicInfo(): Promise<void> {
    const data = await ConstantsFetcher.getPathwaysBasicInfo();
    this.setPathwayBasicInfos(data);
  }

  fetchPathwayTemplates(): Promise<void> {
    return ConstantsFetcher.getPathwaysData().then((data: PathwaysData) => {
      this.setPathwaysData(data);
    });
  }

  getAllQuestionsForPathway = computedFn((pathwayId: string): PathwayTemplateQuestion[] => {
    return this.pathwaysData.pathways[pathwayId].categories.reduce<PathwayTemplateQuestion[]>(
      (questionsArr, category) => {
        return questionsArr.concat(...category.questions);
      },
      []
    );
  });

  getActiveQuestionsForPathway = computedFn((pathwayId: string): PathwayTemplateQuestion[] => {
    const allQuestions = this.getAllQuestionsForPathway(pathwayId);
    return allQuestions.filter((question) => {
      const isDependent = question.dependsOn?.length > 0;
      let isDependentActive = false;

      if (isDependent) {
        const parentQuestion = this.getParentQuestionIfExists(question);
        const parentQuestionAnswer =
          parentQuestion && this.getAnswerForQuestion(pathwayId, parentQuestion.id);

        if (parentQuestion && parentQuestionAnswer) {
          const parentAnswers = castArray(parentQuestionAnswer.value);
          isDependentActive = isDependentQuestionActive(question, parentAnswers);
        }
      }

      return !isDependent || isDependentActive;
    });
  });

  @computed
  get isCurrentPathwayPointBased() {
    return this.isPathwayPointBased(this.currentPathwayInfo.id);
  }

  @computed
  get isCurrentPathwayWithKeyQuestions() {
    return (
      this.currentPathwayInfo &&
      this.getIsCurrentPathwayWithKeyQuestions(this.currentPathwayInfo.id)
    );
  }

  get currentPathwayScore(): number {
    return this.getScoreForPathway(this.currentPathwayInfo.id);
  }

  @computed
  get currentPathwayAnsweredQuestions(): CallPathwayAnswer[] {
    if (!this.currentPathwayInfo) {
      return [];
    }

    return this.getAnsweredQuestionsForPathway(this.currentPathwayInfo.id);
  }

  @computed
  get currentPathwayOutcomeByScore(): PathwayOptionOutcome {
    return this.pathwayOutcomeByScore(this.currentPathwayScore, this.currentPathwayInfo.id);
  }

  getCurrentDxAlertQuestion = computedFn((pathwayId: string) => {
    const questions = this.getAllQuestionsForPathway(pathwayId);
    return questions.find((question) => this.pathwaysData.questions[question.id]?.withDxAlert);
  });

  getAnsweredQuestionsForPathway = (pathwayId: string): CallPathwayAnswer[] => {
    return this.answeredQuestions.filter((answer) => answer.pathwayId === pathwayId);
  };

  isPathwayPointBased = computedFn((pathwayId: string): boolean => {
    const pathwayTemplate = this.getPathwayTemplateById(pathwayId);
    return (
      pathwayTemplate.type === PathwayType.AccumulatedScore ||
      pathwayTemplate.type === PathwayType.DecreasedScore
    );
  });

  activeCategoriesForPathway = (pathway: PathwayTemplate) => {
    return pathway.categories.filter((category) => {
      const filterFunc = this.categoriesShowFilterHandlers[category.showCondition?.type];
      if (filterFunc) {
        return filterFunc(category);
      }
      return true;
    });
  };

  @computed
  get activeCategories() {
    return this.activeCategoriesForPathway(this.getPathwayTemplateById(this.currentPathwayInfo.id));
  }

  @computed
  get scoreByCategories(): { [key: string]: number } {
    if (this.isCurrentPathwayPointBased) {
      return this.scoreByCategoryForPathway(this.currentPathwayInfo.id);
    }
    return {};
  }

  scoreByCategoryForPathway = computedFn((pathwayId: string): { [key: string]: number } => {
    const res: { [key: string]: number } = {};
    this.getAnsweredQuestionsForPathway(pathwayId).forEach((answer: CallPathwayAnswer) => {
      const score = answer.value.score || 0;
      if (answer.categoryId) {
        if (res[answer.categoryId]) {
          res[answer.categoryId] = res[answer.categoryId] + score;
        } else {
          res[answer.categoryId] = score;
        }
      }
    });
    return res;
  });

  getIsCurrentPathwayWithKeyQuestions = computedFn((pathwayId: string): boolean => {
    return this.getAllQuestionsForPathway(pathwayId).some((question: PathwayTemplateQuestion) =>
      Boolean(question.isKey)
    );
  });

  getScoreForPathway = computedFn((pathwayId: string): number => {
    if (this.isPathwayPointBased(pathwayId)) {
      const scoresByCategory = this.scoreByCategoryForPathway(pathwayId);
      const pathwayTemplate = this.getPathwayTemplateById(pathwayId);
      return (pathwayTemplate.initialScore || 0) + sum(Object.values(scoresByCategory));
    }
    return 0;
  });

  @computed
  get activePathwaysMap(): { [id: string]: PathwayTemplate } {
    // Return all "active" pathways info. "active" means that pathway is current pathway,
    // or other pathway that was answered.
    if (!this.currentPathwayInfo) {
      return {};
    }

    const answeredQuestionsPathwayTemplateIds = map(this.answeredQuestions, 'pathwayId');
    const pathwayTemplateIds = [this.currentPathwayInfo.id, ...answeredQuestionsPathwayTemplateIds];
    return pick(this.pathwaysData.pathways, pathwayTemplateIds);
  }

  @computed
  get activePathwaysQuestionSummary(): PathwayQuestionSummary[] {
    const questionSummary: PathwayQuestionSummary[] = [];
    Object.values(this.activePathwaysMap).forEach((pathway: PathwayTemplate) => {
      const activeQuestions = this.getActiveQuestionsForPathway(pathway.id);

      const unansweredActiveQuestions = activeQuestions.filter(
        (question) => !this.getAnswerForQuestion(pathway.id, question.id)
      );
      const unansweredQuestionIds = unansweredActiveQuestions.map((question) => question.id);

      questionSummary.push({
        pathwayId: pathway.id,
        pathwayName: pathway.name,
        activeQuestionsCount: activeQuestions.length,
        unansweredQuestionIds
      });
    });
    return questionSummary;
  }

  @computed
  get highestCurrentOutcome(): PathwayOptionOutcome | null {
    if (!this.currentPathwayInfo) {
      return null;
    }
    const allQuestionsForPathway = this.getAllQuestionsForPathway(this.currentPathwayInfo.id);
    const questionsOutcomes = allQuestionsForPathway.map((question: PathwayTemplateQuestion) =>
      this.getOutcomeForQuestionOnCurrentPathway(question)
    );

    return max([...questionsOutcomes, this.currentPathwayOutcomeByScore]);
  }

  orderedPathwayNames = computedFn((searchValue: string): PathwaySearchResult[] => {
    const pathwaySearchResults: PathwaySearchResult[] = [];

    values(this.pathwayBasicInfos).forEach((pathwayBasicInfo: PathwayBasicInfo, index) => {
      if (isSubStringCaseInsensitive(pathwayBasicInfo.name, searchValue)) {
        pathwaySearchResults.push({
          ...pathwayBasicInfo,
          isMatchByName: true,
          matchedKeywords: []
        });
        return;
      }

      let matchedKeywords: string[] = [];

      if (pathwayBasicInfo.keywords?.length > 0) {
        matchedKeywords = pathwayBasicInfo.keywords.filter((keyword) =>
          isSubStringCaseInsensitive(keyword, searchValue)
        );
      }

      if (matchedKeywords.length > 0) {
        pathwaySearchResults.push({
          ...pathwayBasicInfo,
          matchedKeywords: matchedKeywords,
          isMatchByName: false
        });
      }
    });

    const sortedPathwayNames = pathwaySearchResults.sort((pathwayA, pathwayB) =>
      pathwayA.name.localeCompare(pathwayB.name)
    );

    return sortedPathwayNames;
  });

  getAnswerForQuestion = computedFn((pathwayId: string, questionId: string) => {
    return this.answeredQuestions.find(
      (answer) => answer.pathwayId === pathwayId && answer.questionId === questionId
    );
  });

  getOutcomeForQuestionOnCurrentPathway = computedFn((questionToCheck: PathwayTemplateQuestion) => {
    if (!this.currentPathwayInfo) {
      return PathwayOptionOutcome.NONE;
    }
    const callPathwayAnswer = this.getAnswerForQuestion(
      this.currentPathwayInfo.id,
      questionToCheck.id
    );
    if (!callPathwayAnswer) {
      return PathwayOptionOutcome.NONE;
    }
    switch (this.pathwaysData.questions[callPathwayAnswer.questionId].type) {
      case PathwayQuestionTypes.SINGLE:
      case PathwayQuestionTypes.MULTIPLE:
        return this.getWorstOutcomeOnSelectionQuestion(callPathwayAnswer, questionToCheck);
      case PathwayQuestionTypes.TEXT_ARRAY:
        return this.getWorstOutcomeOnTextArrayQuestion(callPathwayAnswer);
      case PathwayQuestionTypes.DATE:
      case PathwayQuestionTypes.TEXT:
        return PathwayOptionOutcome.NONE;
    }
  });

  private getWorstOutcomeOnSelectionQuestion(
    callPathwayAnswer: CallPathwayAnswer,
    questionToCheck: PathwayTemplateQuestion
  ) {
    const answeredOptions: PathwaySelectionAnswer[] = castArray(callPathwayAnswer.value);
    const outcomeObjects = Object.values(questionToCheck.outcomes || {});
    const worstOption = this.getWorstOptionAnswered(outcomeObjects, answeredOptions);
    return worstOption ? worstOption : PathwayOptionOutcome.NONE;
  }

  private getWorstOutcomeOnTextArrayQuestion(callPathwayAnswer: CallPathwayAnswer) {
    const answers: PathwayTextArrayAnswer[] = callPathwayAnswer.value;
    if (!isArray(answers)) {
      /* Fixes a bug where we had a question defined as text changed to textArray making all the drafts saved in the original type crash,
            remove when either no more drafts with Cancer diagnosis type text or the
            pathway's inherent infrastructure issue (drafts can be out of sync with pathway data) is solved @yuvalSela 22.9.2022 */
      return PathwayOptionOutcome.NONE;
    }
    const worstOption = max(
      answers
        .filter((answer: PathwayTextArrayAnswer) => Boolean(answer.outcome))
        .map((answer: PathwayTextArrayAnswer) => answer.outcome)
    );

    return worstOption ?? PathwayOptionOutcome.NONE;
  }

  private getWorstOptionAnswered(
    outcomeObjects: PathwayQuestionOutcome[],
    answeredOptions: PathwaySelectionAnswer[]
  ) {
    return max(
      outcomeObjects
        .filter((outcomeObject) =>
          answeredOptions.some((option) => option.optionId === outcomeObject.optionId)
        )
        .map((outcomeObject) => outcomeObject.outcome)
    );
  }

  answersSortedByPathway = computedFn((answers: OrderedCallPathwayAnswer[]) => {
    answers.sort((answerA: OrderedCallPathwayAnswer, answerB: OrderedCallPathwayAnswer) => {
      if (answerA.order === answerB.order) {
        return 0;
      }
      return answerA.order > answerB.order ? 1 : -1;
    });
    return groupBy(answers, (answer) => {
      return answer.pathwayId;
    });
  });

  @computed
  get orderedPathwayAnswers(): OrderedCallPathwayAnswer[] {
    return this.answeredQuestions.map((answer, i) => new OrderedCallPathwayAnswer(answer, i));
  }

  @computed
  get orderedPathwayAnswersWithoutBasicQuestions() {
    return this.orderedPathwayAnswers.filter(
      (answer) => !BASIC_QUESTION_IDS.includes(answer.questionId)
    );
  }

  getDependentQuestions = computedFn((question: BaseQuestion | PathwayTemplateQuestion) => {
    if (!this.currentPathwayInfo) {
      return [];
    }
    const callPathwayAnswer = this.getAnswerForQuestion(this.currentPathwayInfo.id, question.id);
    const type = this.pathwaysData.questions[question.id].type;
    if (
      (type !== PathwayQuestionTypes.MULTIPLE && type !== PathwayQuestionTypes.SINGLE) ||
      !callPathwayAnswer
    ) {
      return [];
    }
    const answerValues = castArray(callPathwayAnswer.value);

    return this.getAllQuestionsForPathway(callPathwayAnswer.pathwayId).filter(
      (dependantQuestion: PathwayTemplateQuestion) => {
        return (
          dependantQuestion.dependsOn && isDependentQuestionActive(dependantQuestion, answerValues)
        );
      }
    );
  });

  @computed
  get highestPotentialOutcome(): PathwayOptionOutcome | null {
    if (!this.currentPathwayInfo) {
      return null;
    }
    const allQuestionsForPathway = this.getAllQuestionsForPathway(this.currentPathwayInfo.id);
    const unAnsweredQuestions = this.getUnansweredRelevantQuestions(allQuestionsForPathway);
    const maximumPossibleOutcome: PathwayOptionOutcome = max(
      unAnsweredQuestions.map((question: PathwayTemplateQuestion) => {
        return question.outcomes
          ? max(Object.values(question.outcomes).map((value) => value.outcome))
          : 0;
      })
    );
    return max([maximumPossibleOutcome, this.highestCurrentOutcome]);
  }

  getUnansweredRelevantQuestions(allQuestionsForPathway: any[]) {
    return allQuestionsForPathway.filter((question: PathwayTemplateQuestion) => {
      if (question.dependsOn && question.dependsOn.length > 0) {
        const parentQuestion = this.getParentQuestionIfExists(question);
        let parentQuestionAnswer = this.getAnswerForQuestion(
          this.currentPathwayInfo.id,
          parentQuestion.id
        );
        // Child question is irrelevant if parent question
        // has been answered and eliminated chance of seeing child
        if (
          parentQuestionAnswer &&
          !isDependentQuestionActive(question, castArray(parentQuestionAnswer.value))
        ) {
          return false;
        }
      }
      return !this.getAnswerForQuestion(this.currentPathwayInfo.id, question.id);
    });
  }

  getParentQuestionIfExists = computedFn((question: PathwayTemplateQuestion) => {
    if (!question.dependsOn || question.dependsOn.length <= 0) {
      return null;
    }

    return this.pathwaysData.questions[this.pathwaysData.options[question.dependsOn[0]].questionId];
  });

  getQuestionForPathway = computedFn((pathwayId: string, questionId: string) => {
    let allQuestionsForPathway = this.getAllQuestionsForPathway(pathwayId);
    return allQuestionsForPathway.find(
      (question: PathwayTemplateQuestion) => question.id === questionId
    );
  });

  @action
  setCurrentPathwayTemplate(pathwayTemplate: PathwayBasicInfo | null): void {
    this.currentPathwayInfo = pathwayTemplate;
  }

  pathwayOutcomeByScore = computedFn((score: number, pathwayId: string): PathwayOptionOutcome => {
    const pathway = this.getPathwayTemplateById(pathwayId);
    const { scoreToOutcomeMap } = pathway;
    let outcome = PathwayOptionOutcome.NONE;

    if (scoreToOutcomeMap) {
      const sortedScoresToCheck = Object.keys(scoreToOutcomeMap)
        .map((scoreKey) => parseInt(scoreKey))
        .sort();
      const foundScoreIdx = findIndex(
        (scoreVal) =>
          pathway.type === PathwayType.AccumulatedScore ? score >= scoreVal : score <= scoreVal,
        sortedScoresToCheck
      );
      outcome =
        foundScoreIdx >= 0
          ? pathway.scoreToOutcomeMap[[sortedScoresToCheck[foundScoreIdx]].toString()]
          : PathwayOptionOutcome.NONE;
    }

    return outcome;
  });

  getPathwayBasicInfo = computedFn((pathwayId: string): PathwayBasicInfo => {
    return this.pathwayBasicInfos.find((pathway) => pathway.id === pathwayId);
  });

  setStoreForCall(call: Call) {
    runInAction(() => {
      this.clearAnswers();
      if (call.orderedPathwayAnswers && call.orderedPathwayAnswers.length) {
        this.setAnsweredQuestions(
          call.orderedPathwayAnswers.map((answer) => omit('order', answer))
        );
        this.currentPathwayInfo = this.getPathwayBasicInfo(
          last(call.orderedPathwayAnswers).pathwayId
        );
      }
    });
  }

  isQuestionAnswered(id: string) {
    return Boolean(this.getAnswerForQuestion(this.currentPathwayInfo.id, id));
  }

  @action
  setCurrentHighlightedQuestionId(id: string): void {
    this.highlightedQuestionId = id;
  }

  @action
  clearAnswers(): void {
    this.answeredQuestions = [];
  }

  @action
  setAnsweredQuestions(answeredQuestions: CallPathwayAnswer[]): void {
    this.answeredQuestions = answeredQuestions;
  }

  @action
  private setNewCallPathwayAnswer(callPathwayAnswer: CallPathwayAnswer) {
    const existingAnswerIndex = this.answeredQuestions.findIndex(
      (existingAnswer: CallPathwayAnswer) =>
        existingAnswer.pathwayId === callPathwayAnswer.pathwayId &&
        existingAnswer.questionId === callPathwayAnswer.questionId
    );

    if (existingAnswerIndex !== -1) {
      this.answeredQuestions[existingAnswerIndex] = callPathwayAnswer;
    } else {
      const question: PathwayTemplateQuestion = this.getQuestionForPathway(
        callPathwayAnswer.pathwayId,
        callPathwayAnswer.questionId
      );
      const parentQuestionForFollowUp = this.getParentQuestionIfExists(question);

      if (parentQuestionForFollowUp) {
        const parentQuestionAnswerIndex = findLastIndex(this.answeredQuestions, (answer) => {
          const parentQuestion = this.getParentQuestionIfExists(
            this.getQuestionForPathway(answer.pathwayId, answer.questionId)
          );
          return (
            answer.pathwayId === this.currentPathwayInfo.id &&
            (answer.questionId === parentQuestionForFollowUp.id ||
              parentQuestion?.id === parentQuestionForFollowUp.id)
          );
        });
        this.answeredQuestions.splice(parentQuestionAnswerIndex + 1, 0, callPathwayAnswer);
      } else {
        this.answeredQuestions.push(callPathwayAnswer);
      }
    }
  }

  getBaseOptionById = (optionId: string) => {
    return this.pathwaysData.options[optionId];
  };

  getBaseQuestionById = (questionId: string) => {
    return this.pathwaysData.questions[questionId];
  };

  isQuestionWithDenyAllOption = (baseQuestion: BaseQuestion) => {
    return baseQuestion.optionIds.some(
      (optionId) => this.pathwaysData.options[optionId].action === PathwayOptionAction.DenyAll
    );
  };

  getPathwayTemplateById(id: string) {
    if (!id) {
      return null;
    }

    return this.pathwaysData.pathways[id];
  }

  getActionOptionIdIfExists = (question: BaseQuestion) => {
    return question.optionIds.find((optionId) => this.pathwaysData.options[optionId].action);
  };

  handleOptionRemoved = (
    question: BaseQuestion,
    currentAnswers: PathwaySelectionAnswer[],
    removedAnswer: PathwaySelectionAnswer
  ) => {
    const actionOptionId = this.getActionOptionIdIfExists(question);
    const optionsAfterRemove = currentAnswers.filter(
      (answer) => answer.optionId !== removedAnswer.optionId
    );
    if (!actionOptionId) {
      return optionsAfterRemove;
    }
    switch (this.pathwaysData.options[actionOptionId].action) {
      case PathwayOptionAction.DenyAll:
        return optionsAfterRemove;
      case PathwayOptionAction.AcceptAll:
        return optionsAfterRemove.filter((option) => option.optionId !== actionOptionId);
      default:
        throw Error('Unsupported action');
    }
  };
  handleOptionAdded = (
    question: BaseQuestion,
    currentAnswers: PathwaySelectionAnswer[],
    newAnswer: PathwaySelectionAnswer
  ) => {
    const actionOptionId = this.getActionOptionIdIfExists(question);
    if (!actionOptionId) {
      return currentAnswers.concat(newAnswer);
    }
    switch (this.pathwaysData.options[actionOptionId].action) {
      case PathwayOptionAction.DenyAll:
        const denyAllSelected = newAnswer.optionId === actionOptionId;
        if (denyAllSelected) {
          return [newAnswer];
        } else {
          return currentAnswers
            .filter((answer) => answer.optionId !== actionOptionId)
            .concat(newAnswer);
        }
      case PathwayOptionAction.AcceptAll:
        const acceptAllSelected = newAnswer.optionId === actionOptionId;
        if (acceptAllSelected) {
          const questionForPathway = this.getQuestionForPathway(
            this.currentPathwayInfo.id,
            question.id
          );
          return question.optionIds.map((optionId) => {
            const score = get(['outcomes', optionId, 'score'], questionForPathway);
            const outcome = get(['outcomes', optionId, 'outcome'], questionForPathway);
            let baseOption = this.pathwaysData.options[optionId];
            return {
              title: baseOption.title,
              optionId,
              score: score,
              outcome: outcome,
              action: baseOption.action
            };
          });
        } else {
          return currentAnswers
            .concat(newAnswer)
            .filter((answer) => answer.optionId !== actionOptionId);
        }
      default:
        throw Error('Unsupported action');
    }
  };

  getDeniedOptions = (question: BaseQuestion, answers: PathwaySelectionAnswer[]) => {
    const answeredOptionsIds = answers.map((answer: PathwaySelectionAnswer) => answer.optionId);

    return question.optionIds
      .map((optionId) => this.pathwaysData.options[optionId])
      .filter((option) => {
        return !answeredOptionsIds.includes(option.id) && !option.action;
      });
  };

  @action
  removeAnswer(pathwayId: string, questionId: string): void {
    const dependentQuestions = this.getDependentQuestions(this.pathwaysData.questions[questionId]);
    const questionIdsToRemove = dependentQuestions
      .map((question: PathwayTemplateQuestion) => question.id)
      .concat(questionId);
    this.answeredQuestions = this.answeredQuestions.filter(
      (answer) =>
        !(
          answer.pathwayId === pathwayId &&
          questionIdsToRemove.some(
            (removedQuestionId: string) => removedQuestionId === answer.questionId
          )
        )
    );
  }

  @action
  answerQuestion(questionId: string, value: any, isPrefilled: boolean = false): void {
    const pathwayId = this.currentPathwayInfo.id;
    const pathwayName = this.currentPathwayInfo.name;
    const callPathwayAnswer = this.getAnswerForQuestion(pathwayId, questionId);
    const question = this.getQuestionForPathway(pathwayId, questionId);
    const originalDependantQuestions = this.getDependentQuestions(question);
    const questionDetails = this.pathwaysData.questions[questionId];

    switch (questionDetails.type) {
      case PathwayQuestionTypes.MULTIPLE:
        const baseQuestion = this.getBaseQuestionById(question.id);
        let deniedOptions = null;
        if (!callPathwayAnswer) {
          value = this.handleOptionAdded(questionDetails, [], value);
          if (this.isQuestionWithDenyAllOption(baseQuestion)) {
            deniedOptions = this.getDeniedOptions(questionDetails, value);
          }

          this.setNewCallPathwayAnswer(
            new CallPathwayAnswer(
              pathwayId,
              pathwayName,
              question.id,
              questionDetails.title,
              questionDetails.type,
              question.categoryId,
              value,
              deniedOptions,
              isPrefilled
            )
          );
        } else {
          // Current Answer already has this option?
          if (
            callPathwayAnswer.value.some(
              (answer: PathwaySelectionAnswer) => answer.optionId === value.optionId
            )
          ) {
            // Option was already selected, so removing it
            const newSelectedOptions: any = this.handleOptionRemoved(
              questionDetails,
              callPathwayAnswer.value,
              value
            );
            if (newSelectedOptions.length === 0) {
              // No selected options left on question, remove answer
              this.removeAnswer(pathwayId, questionId);
            } else {
              if (this.isQuestionWithDenyAllOption(baseQuestion)) {
                deniedOptions = this.getDeniedOptions(questionDetails, newSelectedOptions);
              }
              this.setNewCallPathwayAnswer(
                new CallPathwayAnswer(
                  pathwayId,
                  pathwayName,
                  question.id,
                  questionDetails.title,
                  questionDetails.type,
                  question.categoryId,
                  newSelectedOptions,
                  deniedOptions,
                  isPrefilled
                )
              );
            }
          } else {
            // Option is new and is added to currently selected options
            let newSelectedOptions = callPathwayAnswer.value.concat(value);
            newSelectedOptions = this.handleOptionAdded(
              questionDetails,
              callPathwayAnswer.value,
              value
            );
            if (this.isQuestionWithDenyAllOption(baseQuestion)) {
              deniedOptions = this.getDeniedOptions(questionDetails, newSelectedOptions);
            }
            this.setNewCallPathwayAnswer(
              new CallPathwayAnswer(
                pathwayId,
                pathwayName,
                question.id,
                questionDetails.title,
                questionDetails.type,
                question.categoryId,
                newSelectedOptions,
                deniedOptions,
                isPrefilled
              )
            );
          }
        }
        break;
      case PathwayQuestionTypes.SINGLE:
        if (callPathwayAnswer && callPathwayAnswer.value.optionId === value.optionId) {
          this.removeAnswer(callPathwayAnswer.pathwayId, callPathwayAnswer.questionId);
        } else {
          this.setNewCallPathwayAnswer(
            new CallPathwayAnswer(
              pathwayId,
              pathwayName,
              question.id,
              questionDetails.title,
              questionDetails.type,
              question.categoryId,
              value,
              null,
              isPrefilled
            )
          );
        }
        break;
      case PathwayQuestionTypes.TEXT:
      case PathwayQuestionTypes.DATE:
      case PathwayQuestionTypes.TEXT_ARRAY:
        if (!value && callPathwayAnswer) {
          this.removeAnswer(pathwayId, questionId);
        } else {
          this.setNewCallPathwayAnswer(
            new CallPathwayAnswer(
              pathwayId,
              pathwayName,
              question.id,
              questionDetails.title,
              questionDetails.type,
              question.categoryId,
              value,
              null,
              isPrefilled
            )
          );
        }
        break;
    }
    const newDependantQuestions = this.getDependentQuestions(question);
    if (newDependantQuestions.length < originalDependantQuestions.length) {
      originalDependantQuestions.forEach((originalDependant: PathwayTemplateQuestion) => {
        if (
          !newDependantQuestions.find((newDependantQuestion: PathwayTemplateQuestion) => {
            return originalDependant.id === newDependantQuestion.id;
          })
        ) {
          this.removeAnswer(pathwayId, originalDependant.id);
        }
      });
    }
    this.alignAnswersWithCategories();
  }

  private alignAnswersWithCategories = () => {
    // Check that current answered questions are aligned with active categories
    const activeCategoriesIds = this.activeCategories.map((category) => category.id);
    this.currentPathwayAnsweredQuestions.forEach((answer) => {
      if (!activeCategoriesIds.includes(answer.categoryId)) {
        this.removeAnswer(this.currentPathwayInfo.id, answer.questionId);
      }
    });
  };

  private categoriesScoreFilter = (category: PathwayQuestionCategory) => {
    let valueToCheck = 0;
    if (category.showCondition.dependsOn) {
      valueToCheck = this.scoreByCategories[category.showCondition.dependsOn] || 0;
    }
    return valueToCheck >= category.showCondition.value;
  };

  private alertExactCategoryFilter = (category: PathwayQuestionCategory) => {
    return this.highestCurrentOutcome === category.showCondition.value;
  };

  private alertMinimumCategoryFilter = (category: PathwayQuestionCategory) => {
    return this.highestCurrentOutcome >= category.showCondition.value;
  };
}
