import { MouseEvent, FC } from 'react';

import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { Testable } from 'utils/TypeUtils';

export type ExclusiveToggleOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
} & Testable;

export interface ExclusiveToggleProps {
  options: ExclusiveToggleOption[];
  value: string | number;
  onChange: (newSelection: string | number) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

export const ExclusiveToggleButton: FC<ExclusiveToggleProps> = ({
  options,
  value: selectedValue,
  onChange,
  fullWidth = false,
  disabled = false,
  isError = false
}) => {
  const handleChange = (event: MouseEvent<HTMLElement>, newSelection: string) => {
    if (newSelection !== null) {
      onChange(newSelection);
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedValue}
      onChange={handleChange}
      color={isError ? 'error' : 'primary'}
      fullWidth={fullWidth}
      exclusive
      disabled={disabled}
    >
      {options.map(({ value: optionValue, label, disabled = false, testHook }) => (
        <ToggleButton
          key={optionValue}
          value={optionValue}
          selected={optionValue === selectedValue}
          disabled={disabled}
          data-test-hook={testHook}
        >
          <Typography variant="form-text">{label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
