// @ts-strict-ignore
import { FunctionComponent, useMemo } from 'react';

import { observer } from 'mobx-react';
import moment from 'moment';

import { pluralize } from 'utils/StringUtils';

import Patient from 'models/Patient';

import OverdueActions from 'components/Ticket/TicketRow/OverdueActions';
import { usePatientProviderLocation } from 'components/Ticket/TicketRow/shared';
import TicketRenderer from 'components/Ticket/TicketRow/TicketRenderer';

interface Props {
  patient: Patient;
  className?: string;
  toggleSmsModal: (shouldOpenSmsBlockedPopup: boolean, patient: Patient) => void;
  withPatientLink: boolean;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
}

const OverduePatientReportRow: FunctionComponent<Props> = ({
  patient,
  className,
  toggleSmsModal,
  withPatientLink,
  ticketIndex,
  ticketSectionCurrentPage = 0
}) => {
  const providerLocationsText = usePatientProviderLocation(patient);
  const daysText = useMemo(() => {
    const days = moment.utc().diff(moment(patient.protocolOverdueStartTime), 'days');
    let protocolText = 'Symptom Assessment';

    if (patient.hasOralProtocol) {
      protocolText = `Oral Oncolytics Assessment (${patient.regimenTitle})`;
    }

    return `${days} ${pluralize('Day', days)} Overdue: ${protocolText}`;
  }, [patient.hasOralProtocol, patient.protocolOverdueStartTime, patient.regimenTitle]);

  const summary = (
    <div>
      <span className="title-text bold">{daysText}</span>
      {providerLocationsText && <span className="subtitle-text"> — {providerLocationsText}</span>}
    </div>
  );

  return (
    <TicketRenderer
      dashedBorder
      patient={patient}
      classes={{
        container: className
      }}
      actions={
        <OverdueActions
          patient={patient}
          toggleSmsModal={toggleSmsModal}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      }
      summary={summary}
      withPatientLink={withPatientLink}
      createdByText={
        <>{patient.mrn && <div className="bold patient-mrn details-row">MRN: {patient.mrn}</div>}</>
      }
    />
  );
};

export default observer(OverduePatientReportRow);
