import { FC, ReactNode, useEffect } from 'react';

import { Popups } from 'analytics/events/popup';

import classNames from 'classnames';

import { ErrorWithUi, NoNetworkError } from 'errors';
import { observer } from 'mobx-react';

import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import { useStores } from 'mobx/hooks/useStores';

import networkLabelService from 'services/networkLabelService';

import { API_URLS } from 'constants/apiUrls';

import 'containers/Layout/Layout.scss';

import { Popup } from 'views/Modals/Popup';

import { Backdrop } from 'components/Backdrop';
import { Footer } from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Loading from 'components/Loaders/Loading';
import { Toast } from 'components/UIkit/atoms/Toast/Toast';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const { uiStore, constantsStore } = useStores();
  const { error, errorDescription, clearError, isNotificationPanelOpen } = uiStore;
  const { pathname } = useLocation();
  const isWq = pathname.includes('work-queue');
  const errorTitle = error instanceof ErrorWithUi ? error.ui.title : error?.message;

  useEffect(() => {
    const handleConnectionAvailable = () => {
      if (Boolean(uiStore.error) && uiStore.error instanceof NoNetworkError) {
        uiStore.clearError();
      }
    };

    window.addEventListener('online', handleConnectionAvailable);

    return () => {
      window.removeEventListener('online', handleConnectionAvailable);
    };
  }, [uiStore]);

  const isLoading =
    !constantsStore.initialConstantsLoaded ||
    networkLabelService.isLoading(API_URLS.GENERATE_FAKE_REPORTS);

  return (
    <div
      className={classNames('app', {
        'intro-mode': uiStore.isIntro,
        wq: isWq
      })}
      id="app"
    >
      <Helmet>
        <title>Canopy</title>
      </Helmet>
      <Header />

      <Popup
        id={Popups.GeneralError}
        isOpen={!!error}
        onCancelClicked={clearError}
        title={errorTitle}
        action={uiStore.action}
        description={errorDescription?.map((str, index) => (
          <div key={index}>{str}</div>
        ))}
        cancelText="OK"
      />

      <Toast />

      <div className="main">
        {isLoading ? (
          <Loading primaryColor />
        ) : (
          <>
            {children}
            <Backdrop isOpen={isNotificationPanelOpen} />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default observer(Layout);
