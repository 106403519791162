// @ts-strict-ignore

import { useCallback, useEffect, useState } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Popups } from 'analytics/events/popup';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';
import { ALL_ROLES_OPTION, EpisodeMetadata, NO_ROLE_OPTION, TaskRoleOption } from 'mobx/stores';

import { parseDateForInputField } from 'utils/DateUtils';

import { nextSuggestedEpisodeNumber } from 'utils/EpisodesUtils';

import Patient from 'models/Patient';
import PatientEpisode from 'models/PatientEpisode';
import PatientLocation from 'models/PatientLocation';
import PatientProvider from 'models/PatientProvider';

import { Popup } from 'views/Modals/Popup';
import { WarningTypes } from 'views/Pages/CareManagement/CareManagementReport';
import { getEpisodeEndDate } from 'views/Pages/EpisodesManagement/EpisodeRulesetHandlers';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import PatientLocationFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormLocation';
import PatientProviderFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormProvider';
import ClinicianSelectionFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/TicketsInfoFormAssignee';
import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

interface Props {
  patient: Patient;
  episode?: PatientEpisode;
}

export interface PatientEpisodeFormFields {
  episodeNumber: number;
  startDate: string;
  endDate: string;
  episode: ISelectOption<any>; // TODO: change any -> Episode class once available
  diagnosis: string;
  treatment: string;
  notes: string;
  provider: ISelectOption<PatientProvider>;
  location: ISelectOption<PatientLocation>;
  owner: ISelectOption<number>;
}

export interface EpisodeEnrollFormFields extends PatientEpisodeFormFields {
  isHistorical: boolean;
}

export const CreateEditPatientEpisodeFormFields = observer(({ patient, episode }: Props) => {
  const { register, clearErrors, setValue, getValues, formState } = useFormContext();

  const { errors } = formState;

  const { constantsStore } = useStores();
  const { getSuggestedEpisodeNumber, isValidEpisodeNumber } = useNewEpisodeNumber(patient?.id);
  const episodeSelect = useWatch({ name: 'episode' });
  const [currentEpisode, setCurrentEpisode] = useState(episodeSelect?.value);
  const episodeStartDate = useWatch({ name: 'startDate' });
  const episodeMetadata = constantsStore.getEpisodeById(episodeSelect?.value);
  const [warningType, setWarningType] = useState(null);
  const setEndDate = useCallback(() => {
    if (episodeMetadata) {
      const endDate = getEpisodeEndDate(getValues('startDate'), episodeMetadata);
      setValue('endDate', parseDateForInputField(endDate));
    }
  }, [episodeMetadata, getValues, setValue]);

  useEffect(
    function episodeChangeEffect() {
      // episodeSelect get a new reference on each render - check vs currentEpisode
      if (episodeSelect?.value && episodeSelect?.value !== currentEpisode) {
        const isSelectedEpisodeCM = constantsStore.cmStatuses.get(episodeSelect.value);
        if (patient.cmStatus.isOptedOut && isSelectedEpisodeCM) {
          setWarningType(WarningTypes.EnrollWhenOptedOut);
        }
        if (patient.cmStatus.isEndedManually && isSelectedEpisodeCM) {
          setWarningType(WarningTypes.EnrollWhenEnded);
        }
        const episodeNumber = getSuggestedEpisodeNumber(episodeSelect?.value);
        setValue('episodeNumber', episodeNumber);
        // Changing end date according to start date if episode changed
        setEndDate();
        setCurrentEpisode(episodeSelect?.value);
        clearErrors(['episodeNumber', 'endDate']);
      }
    },
    [
      episodeSelect,
      currentEpisode,
      getSuggestedEpisodeNumber,
      setEndDate,
      setValue,
      clearErrors,
      constantsStore.cmStatuses,
      patient?.cmStatus?.isEndedManually,
      patient?.cmStatus?.isOptedOut
    ]
  );

  useEffect(
    function startDateChangeEffect() {
      setEndDate();
    },
    [episodeStartDate, setEndDate]
  );

  const validateEpisodeNumber = (episodeNumber: string) => {
    if (episodeSelect?.value) {
      if (!isValidEpisodeNumber(episodeSelect?.value, parseInt(episodeNumber), episode?.id)) {
        return 'Number Already Exists';
      }
    }
  };

  return (
    <StyledBox mb={40}>
      <Popup
        isOpen={warningType === WarningTypes.EnrollWhenEnded}
        title="Patient Was in a Care Management Episode That Was Ended"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient was in a Care Management episode that was ended for the following reason:
              "{patient?.cmStatus?.reasonText}"
            </span>
            <span>Do you wish to enroll them in a new one, anyway?</span>
          </div>
        }
        onCancelClicked={() => {
          setWarningType(null);
        }}
        action={{
          actionText: 'Enroll',
          actionCallback: () => setWarningType(null)
        }}
        id={Popups.CmEpisodeEnded}
      />

      <Popup
        isOpen={warningType === WarningTypes.EnrollWhenOptedOut}
        title="Patient Opted Out of Care Management"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient has opted out of Care Management with the following reason: "
              {patient?.cmStatus?.reasonText}"
            </span>
            <span>Would you like to opt them back in to enroll them in this episode?</span>
          </div>
        }
        onCancelClicked={() => {
          setWarningType(null);
        }}
        action={{
          actionText: 'Opt In & Enroll',
          actionCallback: () => setWarningType(null)
        }}
        id={Popups.HasOptOutOfCm}
      />

      <Row>
        <Col xs={12}>
          <SelectEpisodeFormField disabled={Boolean(episode?.id)} fieldName="episode" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} lg={6}>
          <RHFStyledInput
            min={1}
            isRequired
            type="number"
            name="episodeNumber"
            label="Episode Number"
            register={register}
            error={Boolean(errors.episodeNumber)}
            validate={validateEpisodeNumber}
            errorMessage={errors.episodeNumber?.message as string}
          />
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <ClinicianSelectionFormField label="Owner" fieldName="owner" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} lg={6}>
          <RHFStyledInput
            isRequired
            type="date"
            name="startDate"
            label="Start Date"
            register={register}
            error={Boolean(errors.startDate)}
            errorMessage={errors.startDate?.message as string}
          />
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <RHFStyledInput
            isRequired
            type="date"
            name="endDate"
            label="End Date"
            register={register}
            min={episodeStartDate}
            error={Boolean(errors.endDate)}
            errorMessage={errors.endDate?.message as string}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <PatientProviderFormField fieldName="provider" isRequired={false} patient={patient} />
        </Col>
        <Col xs={12} sm={6}>
          <PatientLocationFormField fieldName="location" isRequired={false} patient={patient} />
        </Col>
      </Row>
      <Row>
        <Col>
          <RHFStyledInput
            type="textarea"
            label="Qualifying Diagnosis"
            name="diagnosis"
            register={register}
            error={Boolean(errors.diagnosis)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RHFStyledInput
            type="textarea"
            label="Qualifying Treatment"
            name="treatment"
            register={register}
            error={Boolean(errors.treatment)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RHFStyledInput
            type="textarea"
            label="Additional Notes"
            name="notes"
            register={register}
            error={Boolean(errors.notes)}
          />
        </Col>
      </Row>
    </StyledBox>
  );
});

const StyledBox = styled(Box)(
  ({ theme }) => css`
    .row:not(:last-of-type) {
      margin-bottom: ${theme.spacing(20)};
    }
  `
);

export const useNewEpisodeNumber = (patientId: number) => {
  const { patientEpisodesStore } = useStores();

  const getSuggestedEpisodeNumber = (episodeId: number): number => {
    const patientEpisodes = patientEpisodesStore.episodesForPatient(patientId);
    return nextSuggestedEpisodeNumber(patientEpisodes, episodeId);
  };

  const isValidEpisodeNumber = (episodeId: number, episodeNumber: number, id?: number): boolean => {
    let patientEpisodes = patientEpisodesStore.episodesForPatient(patientId).filter(
      (episode) =>
        // filter other episodes / episodes which marked as error / current episode
        !episode.isMarkedAsError && episode.episodeId === episodeId
    );
    if (id) {
      // filter current episode (for when updating the current episode)
      patientEpisodes = patientEpisodes.filter((episode) => episode.id !== id);
    }
    return !patientEpisodes.find((episode) => episode.episodeNumber === episodeNumber);
  };

  return { getSuggestedEpisodeNumber, isValidEpisodeNumber };
};

export const useEpisodesMetadataOptions = (): ISelectOption<number>[] => {
  const { constantsStore } = useStores();
  return constantsStore.episodeTemplatesMetadataMap.items.map((episode: EpisodeMetadata) => ({
    value: episode.id,
    label: episode.name
  }));
};

export const useActiveEpisodesOptions = (): ISelectOption<number>[] => {
  const { constantsStore } = useStores();
  return constantsStore.episodeTemplatesMetadataMap.items
    .filter((episode) => !episode.isDeleted)
    .map((episode: EpisodeMetadata) => ({
      value: episode.id,
      label: episode.name
    }));
};

export const useTaskRoleOptions = () => {
  const { constantsStore } = useStores();
  const options = constantsStore.taskRoles.items
    .sort((roleA, roleB) => roleA.name.localeCompare(roleB.name))
    .map((role: TaskRoleOption) => ({
      value: role.id,
      label: role.name
    }));

  return [
    { value: NO_ROLE_OPTION.value.id, label: NO_ROLE_OPTION.label },
    { value: ALL_ROLES_OPTION.value.id, label: ALL_ROLES_OPTION.label },
    ...options
  ];
};

interface SelectEpisodeFieldProps {
  fieldName: string;
  disabled?: boolean;
}
const SelectEpisodeFormField = ({ fieldName, disabled }: SelectEpisodeFieldProps) => {
  const options = useActiveEpisodesOptions();

  return (
    <FormAutocomplete
      isDisabled={disabled}
      label="Episode"
      isClearable={false}
      isRequired
      name={fieldName}
      options={options}
    />
  );
};
