import AlertTargetPopulation from 'models/AlertTargetPopulation';
import ClinicianAlert, {
  ConditionRule,
  ConditionType,
  ConditionTypeName,
  PopulationType
} from 'models/ClinicianAlert';
import AnyCauseCondition from 'models/Conditions/AnyCauseCondition';
import DistressCondition from 'models/Conditions/DistressCondition';
import SpecificCauseCondition from 'models/Conditions/SpecificCauseCondition';
import { ProtocolName } from 'models/ScheduledProtocol';

import {
  ConditionTypeFormField,
  SymptomsAlertFormFields
} from 'views/Modals/SymptomsAlertModals/SymptomsAlertModal.types';

export const formatAlert = (formValues: SymptomsAlertFormFields) => {
  const { contactMethod, populationType, selectedPatients } = formValues;

  const newAlert = new ClinicianAlert();
  newAlert.reportType = ProtocolName.symptom;
  newAlert.conditionsRule = ConditionRule.Any;
  newAlert.contactMethod = contactMethod.value;

  const population = new AlertTargetPopulation();
  population.type = populationType.value;

  if (population.type === PopulationType.SpecificPatients) {
    population.patientIds = selectedPatients.map(({ value }) => value);
  }

  newAlert.population = population;
  newAlert.conditions = formatAlertConditions(formValues);

  return newAlert;
};

export const formatAlertConditions = (formValues: SymptomsAlertFormFields) => {
  const { conditionType, selectedCauses, threshold, distressLevel } = formValues;

  switch (conditionType!.value) {
    case ConditionType.SpecificCause:
      return selectedCauses!.map(
        (selectedCause) =>
          new SpecificCauseCondition({
            causeName: selectedCause.label,
            causeId: selectedCause.value,
            minSeverity: threshold
          })
      );

    case ConditionType.AnyCause:
      return [new AnyCauseCondition({ minSeverity: threshold })];

    case ConditionType.Distress:
      return [new DistressCondition({ minSeverity: distressLevel })];

    default:
      return [];
  }
};

export const getAlertConditionsDefaultValues = (alert: ClinicianAlert): ConditionTypeFormField => {
  switch (alert?.conditions[0]?.type) {
    case ConditionType.SpecificCause:
      return {
        value: ConditionType.SpecificCause,
        label: ConditionTypeName.SpecificCause
      };

    case ConditionType.AnyCause:
      return {
        value: ConditionType.AnyCause,
        label: ConditionTypeName.AnyCause
      };

    case ConditionType.Distress:
      return {
        value: ConditionType.Distress,
        label: ConditionTypeName.Distress
      };

    default:
      return { value: null, label: null };
  }
};

export const getSelectedCausesDefaultValues = (alert: ClinicianAlert) => {
  if (alert?.conditions[0]?.type === ConditionType.SpecificCause) {
    return alert.conditions.map((specificCauseCondition) => ({
      value: specificCauseCondition?.getId(),
      label: (specificCauseCondition as SpecificCauseCondition)?.getCauseName()
    }));
  }

  return [];
};
