import { FC } from 'react';

import { Popups } from 'analytics/events/popup';

import { Popup } from 'views/Modals/Popup';

interface Props {
  isOpen: boolean;
  setIsPopupOpen: (isOpen: boolean) => void;
}

export const ItemDeletedPopup: FC<Props> = ({ isOpen, setIsPopupOpen }) => {
  return (
    <Popup
      onCancelClicked={() => setIsPopupOpen(false)}
      cancelText="Ok"
      id={Popups.TicketDeletedNotification}
      isOpen={isOpen}
      title="Item Deleted"
      description={
        <>
          This item has been deleted. Tickets could have been
          <br />
          deleted by the user who created them, or by a User Type of “Admin”.
          <br />
          <br />
          Non-Episode Tasks can be deleted by all users.
        </>
      }
    />
  );
};
