// @ts-strict-ignore
import { makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { rootStore } from 'mobx/stores';

import ObjectCopier from 'utils/ObjectCopier';

import AlertTargetPopulation from './AlertTargetPopulation';
import ClinicianAlertCondition from './Conditions/ClinicianAlertCondition';
import SpecificCauseCondition from './Conditions/SpecificCauseCondition';
import { SymptomTicketUrgencyText } from './OperatorTicket';
import Patient from './Patient';
import QuestionnaireAnswer from './QuestionnaireAnswer';
import { ProtocolName } from './ScheduledProtocol';

export enum PopulationType {
  All = 0,
  SpecificPatients = 2,
  SpecificCareTeams = 3,
  PatientWithDiagnosis = 4
}

export enum AlertUrgency {
  NurseReview = 2,
  AttentionToday = 3,
  ImmediateAttention = 4
}

export enum ConditionType {
  AnyCause = 0,
  SpecificCause = 1,
  Distress = 2,
  InputQuestion = 3,
  YesNoQuestion = 4,
  Oral = 6
}

export enum ConditionTypeName {
  AnyCause = 'Any Issues at or Above Threshold',
  SpecificCause = 'Specific Issues at or Above Threshold',
  Distress = 'Distress Level'
}

export enum ConditionRule {
  Any = 1,
  All = 2
}

export default class ClinicianAlert extends ObjectCopier {
  @observable id: number;

  @observable population: AlertTargetPopulation = new AlertTargetPopulation();

  @observable contactMethod: number = -1;

  @observable reportType: ProtocolName;

  @observable conditions: ClinicianAlertCondition[] = [];

  @observable conditionsRule: number;

  @observable doctorId: number = -1;

  constructor() {
    super();
    makeObservable(this);
  }

  getContactMethodName = () => {
    switch (this.contactMethod) {
      // First 2 cases deprecated, need to remove
      case 0:
        return 'Email';
      case 1:
        return 'SMS';
      case AlertUrgency.NurseReview:
        return SymptomTicketUrgencyText.NurseReview;
      case AlertUrgency.AttentionToday:
        return SymptomTicketUrgencyText.AttentionToday;
      case AlertUrgency.ImmediateAttention:
        return SymptomTicketUrgencyText.ImmediateAttention;
      default:
        throw new Error(` no contactMethod ${this.contactMethod}`);
    }
  };
  getConditionsRuleString = (): string => {
    switch (this.conditionsRule) {
      case ConditionRule.Any:
        return 'Any';
      case ConditionRule.All:
        return 'All';
      default:
        throw new Error('No condition rule ' + this.conditionsRule);
    }
  };

  getConditionsString = (): string => {
    let finalStr = '';
    if (this.reportType === ProtocolName.covid && this.conditions.length > 1) {
      finalStr += `${this.getConditionsRuleString()} of: `;
    }
    const specificCausesObj: { [severity: number]: ClinicianAlertCondition[] } = {};
    this.conditions.forEach((condition) => {
      if (condition instanceof SpecificCauseCondition) {
        const arr = specificCausesObj[condition.getBottomThreshold()] || [];
        arr.push(condition);
        specificCausesObj[condition.getBottomThreshold()] = arr;
      }
    });
    this.conditions
      .filter((condition) => condition.type !== ConditionType.SpecificCause)
      .forEach((condition, i) => {
        finalStr = finalStr.concat(
          `${condition.getConditionsString()}${this.conditions.length - 1 !== i ? ', ' : ''}`
        );
      });

    Object.keys(specificCausesObj).forEach((key: any) => {
      const sameSeverityConditionsArray = specificCausesObj[key];
      if (sameSeverityConditionsArray.length === 1) {
        finalStr = finalStr.concat(sameSeverityConditionsArray[0].getConditionsString());
        return;
      }
      let conditionStr = '';
      sameSeverityConditionsArray.forEach((condition: SpecificCauseCondition, i: number) => {
        if (i === sameSeverityConditionsArray.length - 1) {
          conditionStr = conditionStr.slice(0, -2);
          finalStr = finalStr.concat(
            `${conditionStr} and/or ${condition.getCauseName()}: ${ClinicianAlertCondition.getSeverityString(
              condition.getBottomThreshold()
            )} severity or greater`
          );
          return;
        }
        conditionStr = conditionStr.concat(`${condition.getCauseName()}, `);
      });
    });
    return finalStr;
  };

  appliesToPatient = computedFn((patient: Patient) => {
    if (this.population.type === PopulationType.SpecificCareTeams) {
      const patientDepartments =
        rootStore.stores.departmentsStore.getDepartmentsForPatient(patient);
      return patientDepartments.some((department) =>
        this.population.careTeamIds.includes(department.id)
      );
    }

    if (this.population.type === PopulationType.SpecificPatients) {
      return this.population.patientIds.some((id) => id === patient.id);
    }

    if (this.population.type === PopulationType.All) {
      return true;
    }

    if (this.population.type === PopulationType.PatientWithDiagnosis) {
      return true;
    }

    return false;
  });

  appliesToReportConditions = computedFn((report: QuestionnaireAnswer, patient?: Patient) => {
    if (this.conditionsRule === ConditionRule.Any) {
      return this.conditions.some((condition) => condition.isMet(report, patient));
    }
    if (this.conditionsRule === ConditionRule.All) {
      return (
        this.conditions.filter((condition) => condition.isMet(report)).length ===
        this.conditions.length
      );
    }
    return false;
  });

  willTrigger = (report: QuestionnaireAnswer, patient: Patient) => {
    return this.appliesToPatient(patient) && this.appliesToReportConditions(report, patient);
  };
}
