// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Popups } from 'analytics/events/popup';

import { ErrorName } from 'errors';

import { observer } from 'mobx-react';

import { FieldError, FormProvider, useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { parseDateForInputField } from 'utils/DateUtils';

import { showToast } from 'utils/UserMessageUtils';

import PatientEpisode from 'models/PatientEpisode';
import { PatientEpisodeEndReason } from 'models/PatientEpisodeEndReason';

import { SelectOption } from 'models/SelectOption';

import { Popup } from 'views/Modals/Popup';
import StyledInput, { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormErrors } from 'components/Forms';
import { FormSelect, isAddOptionValue } from 'components/UIkit/atoms/Dropdown';
import { Modal } from 'components/UIkit/atoms/Modal';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './PatientEpisodeEndModal.scss';

const MAX_REASON_LENGTH = 100;

interface EpisodeEndReasonModalProps {
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  patientEpisode: PatientEpisode;
}

export interface IOptOutSubmitObject {
  reasonId: number;
  details: string;
  name: string;
}

interface EndEpisodeForm {
  endDate: string;
  reason: any; // TODO: replace with SelectOption<number> once issue fixed on RHF. see https://github.com/react-hook-form/react-hook-form/issues/9131
  newReason: string;
}

const usePatientEpisodeEndReasons = () => {
  const { patientEpisodesStore } = useStores();
  return patientEpisodesStore.episodeEndReasons.items.filter((reason) => reason.isSelectable);
};

export const PatientEpisodeEndModal = observer(
  ({ isOpen, onSubmit, onCancel, patientEpisode }: EpisodeEndReasonModalProps) => {
    const { patientEpisodesStore, patientPageStore, userStore } = useStores();
    const [details, setDetails] = useState('');
    const [saveNewCustomReason, setSaveNewCustomReason] = useState(true);
    const [removedReason, setRemovedReason] = useState<PatientEpisodeEndReason>(null);
    const [editedReason, setEditedReason] = useState<PatientEpisodeEndReason>(null);
    const [newReasonName, setNewReasonName] = useState('');

    const endEpisodeFormMethods = useForm<EndEpisodeForm>();
    const { setError, formState } = endEpisodeFormMethods;
    const { errors } = formState;

    const formDefaultValues: EndEpisodeForm = {
      endDate: parseDateForInputField(new Date()),
      reason: null,
      newReason: ''
    };

    const selectedReasonOption = endEpisodeFormMethods.watch('reason');
    const isNewReasonSelected = isAddOptionValue(selectedReasonOption?.value);
    const [isEndEpisodeLoading, setIsEndEpisodeLoading] = useState(false);
    const [isEditReasonLoading, setIsEditReasonLoading] = useState(false);
    const endReasons = usePatientEpisodeEndReasons();
    const endReasonOptions = useMemo(
      () =>
        endReasons.map((reason) => ({
          value: reason.id,
          label: reason.name,
          isEditable: Boolean(reason.institutionId) && userStore.isManager
        })),
      [endReasons, userStore.isManager]
    );

    const editReason = useCallback(
      (reasonOption: SelectOption<number>) => {
        setNewReasonName(reasonOption.label);
        const reason = endReasons.find(
          (reason: PatientEpisodeEndReason) => reason.id === reasonOption.value
        );
        setEditedReason(reason);
      },
      [endReasons]
    );

    const initState = useCallback(() => {
      setDetails('');
      setSaveNewCustomReason(true);
      patientEpisodesStore.populateEndReasons();
    }, [patientEpisodesStore]);

    useEffect(() => {
      if (isOpen) {
        initState();
      }
    }, [isOpen, initState]);

    const createReason = () => {
      return patientEpisodesStore.createPatientEpisodeEndReason({
        name: endEpisodeFormMethods.getValues('newReason'),
        isActive: saveNewCustomReason
      });
    };

    const handleSaveClicked = async (data: { endDate: string }) => {
      setIsEndEpisodeLoading(true);
      try {
        let reasonId;
        if (isNewReasonSelected) {
          reasonId = await createReason();
        } else {
          reasonId = selectedReasonOption.value;
        }
        const { patient } = patientPageStore;
        await patientEpisodesStore.endEpisode(patientEpisode.patientId, patientEpisode.id, {
          reasonId,
          details,
          endDate: data.endDate
        });
        showToast({
          message: `Episode Ended for ${patient.fullName} - ${patientEpisodesStore.getEpisodeTitle(
            patientEpisode.id
          )}`
        });

        onSubmit();
      } catch (error) {
        setIsEndEpisodeLoading(false);

        if (error.name === ErrorName.DuplicateEpisodeEndReason) {
          setError('newReason', {
            type: 'server',
            message: error?.ui?.title || 'Something went wrong'
          });
        } else {
          throw error;
        }
      }
    };

    const deleteReasonClicked = useCallback((reason: PatientEpisodeEndReason) => {
      setRemovedReason(reason);
    }, []);

    const handleReasonEdit = async () => {
      try {
        setIsEditReasonLoading(true);
        await patientEpisodesStore.updateEpisodeEndReason(editedReason.id, newReasonName);
        setEditedReason(null);
      } catch (error) {
        setIsEditReasonLoading(false);
        throw error;
      }
    };

    const sendRemoveReasonRequest = async () => {
      await patientEpisodesStore.deletePatientEpisodeEndReason(removedReason.id);
      endEpisodeFormMethods.setValue('reason', null);
      setEditedReason(null);
      closeRemoveReasonModal();
    };

    const onSaveNewReasonCheckboxClick = () => {
      setSaveNewCustomReason(!saveNewCustomReason);
    };

    const closeRemoveReasonModal = () => {
      setRemovedReason(null);
    };

    const renderRemoveReasonModal = () => {
      return (
        <Popup
          isOpen={removedReason !== null}
          title="Remove this reason from suggestions?"
          description={removedReason ? `"${removedReason.name}"` : ''}
          onCancelClicked={closeRemoveReasonModal}
          action={{ actionText: 'Remove', actionCallback: sendRemoveReasonRequest }}
          id={Popups.RemoveReason}
        />
      );
    };

    const buildForm = () => {
      return (
        <div>
          <div className="form-top-row">
            <FormProvider {...endEpisodeFormMethods}>
              <FormSelect
                options={endReasonOptions}
                name="reason"
                placeholder="Select Reason"
                addText={userStore.isManager && 'Ending Reason'}
                onEditClick={editReason}
                label="End Reason"
                isDisabled={endReasonOptions.length === 0}
                isRequired
                treatAddAsNormalOption
              />
            </FormProvider>
            <RHFStyledInput
              label="End Date"
              type="date"
              name="endDate"
              min={parseDateForInputField(patientEpisode?.startDate)}
              register={endEpisodeFormMethods.register}
              isRequired
              error={Boolean(endEpisodeFormMethods.formState.errors.endDate)}
            />
          </div>
          {isNewReasonSelected && (
            <div className="mt-3">
              <RHFStyledInput
                isRequired
                register={endEpisodeFormMethods.register}
                maxLength={MAX_REASON_LENGTH}
                error={Boolean(endEpisodeFormMethods.formState.errors.newReason)}
                name="newReason"
                placeholder="Episode Ending Reason"
                rounded
              />
            </div>
          )}
          {selectedReasonOption && (
            <div className="mt-3">
              <StyledInput
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                placeholder={`Optional: add details to the episode ending reason. `}
                rounded
                type="textarea"
              />
            </div>
          )}
        </div>
      );
    };
    return (
      <div>
        {renderRemoveReasonModal()}

        {/*TODO replace to FormModal after use rhf with this form*/}
        <Modal
          resetDataAfterClose={() => setIsEditReasonLoading(false)}
          isOpen={editedReason !== null}
          title="Edit End Episode Reason"
          subtitle="Renaming an End Episode reason will update it for any previously ended episodes, as well."
          confirmActions={[
            {
              onClick: handleReasonEdit,
              text: isEditReasonLoading ? 'Saving...' : 'Save',
              disabled: isEditReasonLoading
            }
          ]}
          closeAction={{ onClick: () => setEditedReason(null), disabled: false }}
          secondaryAction={{
            type: 'button',
            onClick: () => deleteReasonClicked(editedReason),
            text: 'Delete',
            disabled: isEditReasonLoading
          }}
        >
          <div className="edit-reason-modal-content">
            <StyledInput
              rounded
              value={newReasonName}
              onChange={(e) => setNewReasonName(e.target.value)}
            />
          </div>
        </Modal>

        <FormModal
          defaultValues={formDefaultValues}
          methods={endEpisodeFormMethods}
          title="End Episode"
          isOpen={isOpen && editedReason === null}
          confirmActions={[
            {
              onClick: endEpisodeFormMethods.handleSubmit(handleSaveClicked),
              text: 'End Episode',
              disabled: isEndEpisodeLoading
            }
          ]}
          closeAction={{ onClick: onCancel, disabled: false }}
          secondaryAction={{
            type: 'controlled-labeled-checkbox',
            isVisible: isNewReasonSelected,
            label: 'Save this reason for future use',
            id: 'save-reason',
            onChange: onSaveNewReasonCheckboxClick,
            checked: saveNewCustomReason
          }}
          resetDataAfterClose={() => setIsEndEpisodeLoading(false)}
        >
          <div className="end-episode-content">{buildForm()}</div>
          <FormErrors errors={errors as Record<string, FieldError>} />
        </FormModal>
      </div>
    );
  }
);
