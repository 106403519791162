import React, { FC } from 'react';

import { Popups } from 'analytics/events/popup';

import { Popup } from 'views/Modals/Popup';

interface Props {
  isOpen: boolean;
  onCancelClicked: () => void;
}

const SmsBlockedPopup: FC<Props> = ({ isOpen, onCancelClicked }) => (
  <Popup
    title="Unsubscribed from SMS Messages"
    isOpen={isOpen}
    description="This occurs when a patient has replied STOP to an SMS message from Canopy. To subscribe again,
        the patient must text START to the same number."
    onCancelClicked={onCancelClicked}
    cancelText="OK"
    id={Popups.UnsubscribeSms}
  />
);

export default SmsBlockedPopup;
