// @ts-strict-ignore

import { FC, useState } from 'react';

import { Popups } from 'analytics/events/popup';
import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';

import { useHistory, useLocation } from 'react-router-dom';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { TaskRoleOption } from 'mobx/stores';

import { parseDateForInputField } from 'utils/DateUtils';

import { removeUrlParam } from 'utils/urlUtils';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';

import { ALL_ROLES } from 'constants/tasks.const';

import Patient from 'models/Patient';
import { MAX_TASK_NAME_LENGTH } from 'models/TaskTicket';
import Ticket from 'models/Ticket';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import { Popup } from 'views/Modals/Popup';
import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';
import StyledFieldsForm from 'views/Widgets/StyledFieldsForm';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import FormDateField from 'components/Forms/FormDateField';
import FormTextAreaField from 'components/Forms/FormTextAreaField';
import TicketInfoFormRole from 'components/Ticket/TicketForms/TicketsInfoFormFragments/TicketInfoFormRole';
import ClinicianSelectionFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/TicketsInfoFormAssignee';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './EditTaskModal.scss';

interface EditTaskModalProps {
  isOpen: boolean;
  patient: Patient;
  ticket?: Ticket;
  onSubmit: () => void;
  onCancel: () => void;
  resetDataAfterClose: () => void;
  onDeleteTask: (...args: any) => void;
  onEditTask: (...args: any) => void;
}

interface TaskFormFields {
  id: number;
  name: string;
  role: ISelectOption<TaskRoleOption> | null;
  assignee: ISelectOption<number> | null;
  owner: ISelectOption<number> | null;
  dueDate: string;
  description: string;
}

interface EditTaskForm {
  taskTicket: TaskFormFields;
}

export const taskFieldName = 'taskTicket';

const EditTaskModal: FC<EditTaskModalProps> = ({
  patient,
  ticket,
  onCancel,
  onSubmit,
  isOpen,
  resetDataAfterClose,
  onDeleteTask,
  onEditTask
}) => {
  const [isDeleteWarningOpen, setDeleteWarningOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  // Check both update and create task loading state
  const isLoading = useNetworkLoading([
    API_URLS.TASKS,
    API_URLS.UPDATE_TASK(ticket?.taskTicket?.id)
  ]);
  const { constantsStore, tasksStore, ticketsStore, userStore } = useStores();

  const role = constantsStore.getRoleByIdWithAllAndNoRoles(ticket?.taskTicket?.roleId);

  const ownerName = useClinicianFullNameWithCredentials(
    ticket?.taskTicket?.owner?.firstName,
    ticket?.taskTicket?.owner?.lastName,
    ticket?.taskTicket?.owner?.credentialId
  );
  const assigneeName = useClinicianFullNameWithCredentials(
    ticket?.assignee?.doctor.firstName,
    ticket?.assignee?.doctor.lastName,
    ticket?.assignee?.doctor.credentialId
  );
  const taskTicketDefaults: { taskTicket: TaskFormFields } = {
    taskTicket: {
      id: ticket?.id,
      name: ticket?.taskTicket?.name,
      role: { label: role?.name, value: role },
      owner: ticket?.taskTicket?.owner
        ? {
            label: ownerName,
            value: ticket?.taskTicket?.owner?.id
          }
        : null,
      assignee: ticket?.assignee
        ? {
            label: assigneeName,
            value: ticket?.assignee?.doctor?.id
          }
        : null,
      dueDate: ticket?.taskTicket?.dueDate
        ? parseDateForInputField(ticket?.taskTicket?.dueDate)
        : null,
      description: ticket?.taskTicket?.description
    }
  };

  const methods = useForm<EditTaskForm>();

  const getTaskFromData = (data: EditTaskForm) => {
    const task = data.taskTicket;
    return {
      ...(ticket?.taskTicket?.id && {
        id: ticket.taskTicket.id
      }),
      ...(ticket?.taskTicket?.patientEpisodeId && {
        patientEpisodeId: ticket.taskTicket?.patientEpisodeId
      }),
      ...(ticket?.taskTicket?.monthNumber && {
        monthNumber: ticket.taskTicket?.monthNumber
      }),
      name: task.name,
      description: task.description,
      roleId: task.role?.value?.id,
      ownerId: task.owner?.value,
      assigneeId: task.assignee?.value || null,
      dueDate: task.dueDate
    };
  };

  const getSuccessMessage = (isExistingTask: boolean = true) => {
    return `${isExistingTask ? 'Item Updated' : 'Task Created'} for ${patient.fullName}`;
  };

  const handleSaveClicked = async (data: EditTaskForm) => {
    const task = getTaskFromData(data);
    if (task.id) {
      await tasksStore.updateTask({ ...task, ticketId: ticket.id });
      onEditTask && onEditTask(ticket);
    } else {
      // TODO: relevant for pp only (we should separate edit and create modals!!)
      await tasksStore.createTask({ ...task, patientId: patient.id });
      onEditTask && onEditTask();
    }
    showToast({ message: getSuccessMessage(!!task.id) });
    onSubmit();
  };

  const handleDeleteClicked = () => {
    setDeleteWarningOpen(true);
  };

  const handleDelete = async () => {
    await ticketsStore.deleteTicket(ticket.id);
    onDeleteTask && onDeleteTask(ticket);
    showToast({ message: 'Task Deleted' });
    setDeleteWarningOpen(false);
    removeUrlParam(history, location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
    onCancel();
  };

  const title = `${ticket?.id ? 'Edit' : 'Add'} ${
    ticket?.taskTicket?.episodeId
      ? constantsStore.getEpisodeById(ticket.taskTicket.episodeId).name
      : ''
  } Task: ${patient?.fullName}`;
  const errors = methods.formState.errors.taskTicket || {};

  const allowDelete = ticket && userStore.isManager;

  const triggerValidation = () => {
    if (methods.formState.isSubmitted) {
      methods.trigger();
    }
  };

  const isValidRole = (role: ISelectOption<TaskRoleOption>) => {
    const id = role?.value?.id;
    return id > 0 || id === ALL_ROLES;
  };

  const checkMinRequiredFields = () => {
    const taskTicket = methods.getValues().taskTicket;
    return Boolean(
      taskTicket.owner?.value || taskTicket.assignee?.value || isValidRole(taskTicket.role)
    );
  };

  return (
    <FormModal
      defaultValues={taskTicketDefaults}
      methods={methods}
      isOpen={isOpen}
      title={title}
      confirmActions={[
        {
          onClick: methods.handleSubmit(handleSaveClicked),
          text: isLoading ? 'Saving...' : 'Save',
          disabled: isLoading
        }
      ]}
      closeAction={{ onClick: onCancel, disabled: false }}
      secondaryAction={{
        type: 'button',
        onClick: handleDeleteClicked,
        isVisible: allowDelete,
        disabled: isLoading,
        text: 'Delete'
      }}
      resetDataAfterClose={resetDataAfterClose}
    >
      <Popup
        isOpen={isDeleteWarningOpen}
        title="Delete this task?"
        description="Are you sure you want to delete? This action cannot be undone."
        action={{ actionText: 'Delete', actionCallback: handleDelete }}
        onCancelClicked={() => setDeleteWarningOpen(false)}
        id={Popups.DeleteItem}
      />

      <div className="mb-4">
        <RHFStyledInput
          name={`${taskFieldName}.name`}
          register={methods.register}
          isRequired
          maxLength={MAX_TASK_NAME_LENGTH}
          label="Task Title"
          error={Boolean(errors.name)}
        />
      </div>

      <StyledFieldsForm
        extraFields={[
          <TicketInfoFormRole
            key={`${taskFieldName}.role`}
            fieldName={`${taskFieldName}.role`}
            onChange={triggerValidation}
            validate={checkMinRequiredFields}
          />,

          <ClinicianSelectionFormField
            isInline
            key={`${taskFieldName}.assignee`}
            fieldName={`${taskFieldName}.assignee`}
            placeholder="Unassigned"
            onChange={triggerValidation}
            label="Assignee"
            validate={checkMinRequiredFields}
            withClearableOption
          />,

          <ClinicianSelectionFormField
            isInline
            label="Owner"
            key={`${taskFieldName}.owner`}
            fieldName={`${taskFieldName}.owner`}
            onChange={triggerValidation}
            validate={checkMinRequiredFields}
            withClearableOption
          />,

          <div className="date-form-option" key={`${taskFieldName}.dueDate`}>
            <FormDateField isRequired name={`${taskFieldName}.dueDate`} error={errors.dueDate} />
            <div className="label">Due Date:</div>
          </div>
        ]}
        mainField={
          <FormTextAreaField
            placeholder="Add optional notes here…"
            name={`${taskFieldName}.description`}
            error={errors.description}
            label="Task Description"
          />
        }
      />
    </FormModal>
  );
};

export default observer(EditTaskModal);
