// @ts-strict-ignore
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { Popups } from 'analytics/events/popup';
import classNames from 'classnames';
import { ErrorName } from 'errors';
import { observer, Provider } from 'mobx-react';
import ReactDOMServer from 'react-dom/server';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';
import { rootStore, UiStore } from 'mobx/stores';

import networkLabelService from 'services/networkLabelService';

import { API_URLS } from 'constants/apiUrls';

import Call, {
  CallDurationInterval,
  CallDurationValidationErrorEnum,
  getCallDurationErrorOrNull,
  getCallInfo,
  ICall,
  IncludeSummaryInEmrNote,
  MAX_TOTAL_CALL_DURATION_IN_HOURS
} from 'models/Call';
import CallLogIntervalAuditTrailRecord, {
  CallLogAuditTrailType
} from 'models/CallLogIntervalAuditTrailRecord';
import CallReason, { CallTopicDiscussed } from 'models/CallReason';
import { ICallSaveOptions } from 'models/CallSaveOptions';
import { DurationInterval } from 'models/DurationInterval';
import Patient from 'models/Patient';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { useWillMount } from 'hooks/useWillMount';

import { CallReasonSelectionModal } from 'views/Modals/CallReasonSelectionModal';
import ConnectTicketsToCallModal from 'views/Modals/ConnectTicketsToCallModal';
import { Popup } from 'views/Modals/Popup';

import PathwaysView from 'views/Patient/PatientMain/PathwaysView';
import { PATIENT_TABS } from 'views/Patient/PatientMain/PatientMainView.constants';
import 'views/Widgets/CallLogging/CallLogging.scss';
import { disabledSummaryText } from 'views/Widgets/CallLogging/CallLoggingSummary';

import GeneratedCallDetailsView from 'views/Widgets/GeneratedCallDetailsView';

import { IntervalContextProvider } from 'components/IntervalsEditor/IntervalsContext';
import IntervalsEditorModal, {
  IntervalsEditorResult
} from 'components/IntervalsEditor/IntervalsEditorModal';
import Loading from 'components/Loaders/Loading';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import { Text } from 'components/UIkit/atoms/Text';

import { CallLoggingBody } from './CallLoggingBody';
import { CallLoggingFooter, CallLoggingFooterFormFields } from './CallLoggingFooter';
import { CallLoggingHeader } from './CallLoggingHeader';

interface ICallLoggingProps {
  patient: Patient;
  call?: Call | null;
  uiStore?: UiStore;
  onCallCanceled: () => void;
  onNewCallSaved: (call: Call, options: ICallSaveOptions, markup?: string) => Promise<any>;
  selectedTab?: string;
  hasServerError: boolean;
}

const CallContainer: FC<ICallLoggingProps> = (props) => {
  let auditTrail: CallLogIntervalAuditTrailRecord[] = [];

  const {
    userStore,
    pathwaysStore,
    callsStore,
    callLoggingStore,
    settingsStore,
    careTimerStore,
    ticketsStore,
    constantsStore
  } = useStores();

  const [shouldSendCallToEmr, setShouldSendCallToEmr] = useState(
    settingsStore.institutionSettings.saveCallSendToEmrByDefault
  );

  const { userPreferences, setUserPreferences } = useUserPreferences();

  useWillMount(() => {
    callLoggingStore.resetCurrentCall(props.call);
    if (!pathwaysStore.pathwaysData) {
      pathwaysStore.fetchPathwayTemplates();
    }
  });

  const [isDeleteCallPopupOpen, setIsDeletePopupOpen] = useState(false);
  const [showInvalidDurationsWarningDialog, setShowInvalidDurationsWarningDialog] = useState(false);
  const [showCallDurationOverflowDialog, setShowCallDurationOverflowDialog] = useState(false);
  const [showPauseOtherTimersModal, setShowPauseOtherTimersModal] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(false);
  const [isSaveInProgress, setSaveInProgress] = useState(false);
  const [isCallReasonSelectionModalOpen, setCallReasonSelectionModalOpen] = useState(false);
  const [isShowingPathways, setShowPathways] = useState(false);
  const [showConnectTicketsModal, setShowConnectTicketsModal] = useState(false);
  const [wasDeletedDraftWarningShown, setWasDeletedDraftWarningShown] = useState(false);
  const { currentCall } = callLoggingStore;

  const defaultIsOriginalGeneratedSmartSummary =
    Boolean(currentCall.summary) && !currentCall.isSummaryManuallyEdited;

  const [originalGeneratedSmartSummary, setOriginalGeneratedSmartSummary] = useState(
    defaultIsOriginalGeneratedSmartSummary ? currentCall.summary : ''
  );

  const { openDisconnectDraftPopup } = useTicketOverviewContext();

  const methods = useForm<CallLoggingFooterFormFields>({
    defaultValues: {
      summary: currentCall.summary,
      includeSummaryInEmrNote: currentCall.includeSummaryInEmrNote
    }
  });

  const { getValues } = methods;

  //We need the patient tickets when the user trys to save the call as a draft
  useMount(function fetchTicketsForPatientWhenInCallsTab() {
    if (props.selectedTab === PATIENT_TABS.CALLS) {
      ticketsStore.fetchTicketsForPatient(props.patient.id);
    }
  });

  const userFullNameWithClinician = useClinicianFullNameWithCredentials(
    userStore.currentDoctor.firstName,
    userStore.currentDoctor.lastName,
    userStore.currentDoctor.credentialId
  );
  useEffect(
    function setInitialSaveOptions() {
      setShouldSendCallToEmr(settingsStore.institutionSettings.saveCallSendToEmrByDefault);
    },
    [callLoggingStore, settingsStore.institutionSettings]
  );

  useEffect(
    function setupNewCall() {
      pathwaysStore.clearAnswers();
      if (props.call) {
        pathwaysStore.setStoreForCall(props.call);
      }

      callLoggingStore.resetCurrentCall(props.call);

      if (!callLoggingStore.isGlobalCallLoggingTimerActive) {
        callLoggingStore.startCallTimer({
          id: userStore.currentDoctor.id,
          name: userFullNameWithClinician
        });
      }

      return () => {
        if (callLoggingStore.isTimerActive()) {
          callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
        }

        callLoggingStore.callTimer && callLoggingStore.clearCallTimer();
        callLoggingStore.callTimer = null;
        careTimerStore.setIdleDisabled(false);
      };
    },
    [
      careTimerStore,
      callLoggingStore,
      pathwaysStore,
      props.call,
      userStore.currentDoctor.id,
      userFullNameWithClinician
    ]
  );

  const handleWindowClosed = useCallback(() => {
    if (callLoggingStore.isTimerActive() && callLoggingStore.isGlobalCallLoggingTimerActive) {
      callLoggingStore.endCall();
    }
  }, [callLoggingStore]);

  useEffect(
    function endCallOnUnload() {
      window.addEventListener('unload', handleWindowClosed);
      return () => {
        window.removeEventListener('unload', handleWindowClosed);
      };
    },
    [handleWindowClosed]
  );

  useEffect(
    function checkCallDuration() {
      if (
        currentCall.callDuration / 3600 <= MAX_TOTAL_CALL_DURATION_IN_HOURS &&
        (currentCall.callDuration + 1) / 3600 > MAX_TOTAL_CALL_DURATION_IN_HOURS
      ) {
        toggleMaximumCallDurationReachedDialog();
      }
      if (shouldEndTimers()) {
        callLoggingStore.endCall(false);
      }
    },
    // eslint-disable-next-line
    [currentCall.callDuration]
  );

  const shouldEndTimers = () => {
    return (
      callLoggingStore.isTimerActive() &&
      currentCall.callDuration &&
      !callLoggingStore.isGlobalCallLoggingTimerActive
    );
  };

  const handleToggleMinimize = () => {
    trackActionButtonAnalyticsEvent({
      action: callLoggingStore.isCallLoggerMinimized
        ? AnalyticEventAction.Maximize
        : AnalyticEventAction.Minimize,
      virtual_page: 'call logger'
    });

    callLoggingStore.toggleMinimize();
  };

  const handleCloseBtnClicked = () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.CancelIcon,
      virtual_page: 'call logger'
    });
    toggleDeletePopup();
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeleteCallPopupOpen);
  };

  const toggleInaccurateDurationsWarningDialog = () => {
    setShowInvalidDurationsWarningDialog(!showInvalidDurationsWarningDialog);
  };

  const toggleMaximumCallDurationReachedDialog = () => {
    setShowCallDurationOverflowDialog(!showCallDurationOverflowDialog);
  };

  const toggleCallReasonSelectionModal = () => {
    setCallReasonSelectionModalOpen(!isCallReasonSelectionModalOpen);
  };

  const handleCallReasonsSaved = (
    callReasons: CallReason[],
    topicsDiscussed: CallTopicDiscussed[]
  ) => {
    setCallReasonSelectionModalOpen(false);
    callLoggingStore.updateCurrentCall({ callReasons, topicsDiscussed });
    submitCall();
  };

  const onSubmitCallClicked = () => {
    const { callDuration } = currentCall;
    const callError: CallDurationValidationErrorEnum = getCallDurationErrorOrNull(callDuration);

    if (callLoggingStore.callTimer) {
      callLoggingStore.endCall();
    }

    switch (callError) {
      case CallDurationValidationErrorEnum.INACCURATE_DURATION:
        toggleInaccurateDurationsWarningDialog();
        break;
      case CallDurationValidationErrorEnum.MAXIMUM_DURATION_REACHED:
        toggleMaximumCallDurationReachedDialog();
        break;
      default:
        tryToSubmitCall();
    }
  };

  const tryToSubmitCall = () => {
    const { patientHasOpenTickets } = ticketsStore;
    if (patientHasOpenTickets(props.patient)) {
      toggleConnectTicketsModal();
    } else {
      toggleCallReasonSelectionModal();
    }
  };

  const toggleConnectTicketsModal = () => {
    setShowConnectTicketsModal(!showConnectTicketsModal);
  };

  const onSaveDraftClick = (isCallAttempt?: boolean) => {
    handleWindowClosed();
    submitCall(true, isCallAttempt);
    return;
  };

  const getCallFromState = (isDraft: boolean = false, isCallAttempt: boolean = false) => {
    const { patient } = props;
    const {
      id,
      callDuration,
      documentationDuration,
      note,
      patientInitiated,
      durationIntervals,
      callReasons,
      ticketIds,
      topicsDiscussed,
      summary,
      includeSummaryInEmrNote,
      smartSummaryId,
      isSummaryManuallyEdited,
      clinician
    } = callLoggingStore.currentCall;
    const { lastReport } = patient;
    const orderedPathwayAnswers = pathwaysStore.orderedPathwayAnswers;

    const lastInterval = durationIntervals[durationIntervals.length - 1];
    lastInterval.isCallAttempt = isCallAttempt;

    const callSummaryOptionalProperties: {
      summary?: string;
      smartSummaryId?: number | null;
      includeSummaryInEmrNote?: IncludeSummaryInEmrNote;
      isSummaryManuallyEdited?: boolean;
    } = {};

    if (userPreferences.generateSmartSummary) {
      callSummaryOptionalProperties.summary = summary === disabledSummaryText ? '' : summary;
      callSummaryOptionalProperties.isSummaryManuallyEdited = isSummaryManuallyEdited;

      if (Boolean(smartSummaryId)) {
        callSummaryOptionalProperties.smartSummaryId = smartSummaryId;
      }

      if (Boolean(includeSummaryInEmrNote)) {
        callSummaryOptionalProperties.includeSummaryInEmrNote = includeSummaryInEmrNote;
      }
    }

    const callObject: ICall = {
      id,
      callDuration,
      documentationDuration,
      doctorId: userStore.currentDoctor.id,
      patientId: patient.id,
      note,
      lastReportId: lastReport ? lastReport.id : null,
      patientInitiated,
      createdAt: new Date(),
      updatedAt: null,
      durationIntervals,
      auditTrail,
      callReasons,
      topicsDiscussed,
      info: getCallInfo(patient, constantsStore.symptomsMap),
      orderedPathwayAnswers,
      isDraft,
      ticketIds,
      documentId: null,
      clinician,
      ...callSummaryOptionalProperties
    };

    return new Call(callObject);
  };

  const getIsSummaryManuallyEdited = () => {
    const currentSummary = getValues('summary');

    if (currentSummary === disabledSummaryText) {
      return false;
    }

    let isSummaryManuallyEdited = originalGeneratedSmartSummary !== currentSummary;

    //2 scenarios:
    // - if the user opens a draft call with an unchanged smart summary.
    // - if the user doesn't generate a smart summary
    if (!originalGeneratedSmartSummary) {
      isSummaryManuallyEdited = currentCall.isSummaryManuallyEdited;
    }

    return isSummaryManuallyEdited;
  };

  const submitCall = (
    isDraft: boolean = false,
    isCallAttempt: boolean = false,
    shouldResolveAll: boolean = false
  ) => {
    const [currentSummary, currentIncludeSummaryInEmrNote] = getValues([
      'summary',
      'includeSummaryInEmrNote'
    ]);

    callLoggingStore.updateCurrentCall({
      summary: currentSummary,
      includeSummaryInEmrNote: currentIncludeSummaryInEmrNote,
      isSummaryManuallyEdited: isSummaryManuallyEdited
    });

    setSaveInProgress(true); // prevent saving another call while saving in progress

    const { patient } = props;
    const call = getCallFromState(isDraft, isCallAttempt);

    let staticMarkup: string;
    const isMarkupRequired = userPreferences.copyNotesToClipboard && !isDraft;

    if (isMarkupRequired) {
      let connectedTickets;
      if (call.ticketIds?.size > 0) {
        connectedTickets = Array.from(call.ticketIds).map((ticketId) =>
          ticketsStore.ticketsMap.get(ticketId)
        );
      }
      // TODO: use server to create markup (see: EH-5600)
      staticMarkup = ReactDOMServer.renderToStaticMarkup(
        <Provider {...rootStore.stores}>
          <GeneratedCallDetailsView
            call={call}
            patient={patient}
            pathwayAnswers={call.orderedPathwayAnswers}
            primarySymptoms={currentCall.callReasons}
            connectedTickets={connectedTickets}
          />
        </Provider>
      );
    }

    const callSaveOptions: ICallSaveOptions = {
      sendToEmr: shouldSendCallToEmr,
      copyToClipboard: userPreferences.copyNotesToClipboard,
      resolveConnectedTickets: shouldResolveAll
    };

    props
      .onNewCallSaved(
        call,
        isDraft ? { copyToClipboard: false, sendToEmr: false } : callSaveOptions,
        staticMarkup
      )
      .catch((error) => {
        if (error.name === ErrorName.TicketConnectedToDraft) {
          // call is transitioning from draft to save call we do not want to disconnect it before the transition
          const excludedCallIds = !call.isDraft && call.id ? [call.id] : undefined;
          openDisconnectDraftPopup({
            ticketIds: call.ticketIdsArray,
            excludedCallIds,
            callback: () => {
              props.onNewCallSaved(
                call,
                isDraft ? { copyToClipboard: false, sendToEmr: false } : callSaveOptions,
                staticMarkup
              );
            }
          });
        } else throw error;
      })
      // reset isSaveInProgress
      .finally(() => setSaveInProgress(false));
  };

  const renderDeleteCallPrompt = () => {
    return (
      <Popup
        isOpen={isDeleteCallPopupOpen}
        title="Delete Call"
        description="Are you sure you want to delete this call?"
        onCancelClicked={() => {
          toggleDeletePopup();
        }}
        action={{
          actionText: 'Delete',
          actionCallback: () => {
            props.onCallCanceled();
            toggleDeletePopup();
          }
        }}
        cancelText="Cancel"
        overrideCloseAction={toggleDeletePopup}
        id={Popups.DeleteCall}
      />
    );
  };

  const dismissDraftDeletedWarning = () => {
    setWasDeletedDraftWarningShown(true);
    callLoggingStore.updateCurrentCall({ ...currentCall, id: null });
  };
  const renderCallDeletedPrompt = () => {
    const shouldShowDraftDeletedWarning =
      currentCall.isDraft &&
      currentCall.id &&
      !props.patient.calls.find((call) => call.id === currentCall.id) &&
      !wasDeletedDraftWarningShown;
    return (
      <Popup
        isOpen={shouldShowDraftDeletedWarning}
        title="This call has been deleted, but you can still save it as a new call"
        description="This draft call was deleted, but if you save it again, it will be saved as a new call."
        cancelText="OK"
        onCancelClicked={dismissDraftDeletedWarning}
        id={Popups.DraftCallWasDeleted}
      />
    );
  };

  const renderInaccurateDurationsDialog = () => {
    return (
      <Popup
        isOpen={showInvalidDurationsWarningDialog}
        title="Please Confirm Duration"
        description={`This call is ${callLoggingStore.durationStrFormat(
          Number(currentCall.callDuration)
        )} long. If this is not accurate, place your mouse on the Call Timer and click ‘Edit Timer’.`}
        onCancelClicked={toggleInaccurateDurationsWarningDialog}
        action={{
          actionText: 'Save Call',
          actionCallback: () => {
            toggleInaccurateDurationsWarningDialog();
            tryToSubmitCall();
          }
        }}
        id={Popups.ConfirmDuration}
        cancelText="Edit Timers"
      />
    );
  };

  const renderMaxCallTimeReachedDialog = () => {
    return (
      <Popup
        isOpen={showCallDurationOverflowDialog}
        title="Maximum Duration Reached"
        description={`This call is over ${MAX_TOTAL_CALL_DURATION_IN_HOURS} hours. 
        Correct the duration by placing your mouse on the Call Timer and clicking ‘Edit Timer’.`}
        onCancelClicked={toggleMaximumCallDurationReachedDialog}
        cancelText="OK"
        id={Popups.MaximumDurationReached}
      />
    );
  };

  const openEditDurationModal = () => {
    setCurrentlyEditing(true);
  };

  const validateInterval = async (interval: DurationInterval): Promise<string | null> => {
    const { patient } = props;
    if (interval.startDate && interval.endDate) {
      return await callsStore.validateInterval(interval.startDate, interval.endDate, patient.id);
    }
    return null;
  };

  const renderEditDurationModel = () => {
    return (
      <IntervalContextProvider
        initialIntervals={callLoggingStore.currentCall.durationIntervals}
        externalValidationAction={validateInterval}
      >
        <IntervalsEditorModal
          isOpen={currentlyEditing}
          title="Edit Call Timer"
          virtualPage="call logger"
          onSave={onDurationEditSaved}
          onClose={closeEditDurationModal}
        />
      </IntervalContextProvider>
    );
  };
  const onDurationEditSaved = ({ intervals, deletedIntervals }: IntervalsEditorResult) => {
    const deletedIntervalIds = new Set<number>(deletedIntervals.map((item) => item.id));
    const durationIntervals = intervals.map((interval) =>
      CallDurationInterval.fromDurationInterval(interval)
    );
    updateAuditTrail(durationIntervals, deletedIntervalIds);
    setCurrentlyEditing(null);
    callLoggingStore.updateCurrentCall({
      durationIntervals,
      callDuration: sumTotalDurationOfIntervals(durationIntervals)
    });
  };

  const closeEditDurationModal = () => {
    setCurrentlyEditing(null);
  };

  const renderHelmet = () => {
    const { patient } = props;
    return (
      <Helmet>
        <title>{` ${callLoggingStore.isTimerActive() ? 'Active Call: ' : ''} ${
          patient.fullName
        } - Canopy`}</title>
      </Helmet>
    );
  };

  const sumTotalDurationOfIntervals = (durationIntervals: CallDurationInterval[]) => {
    return durationIntervals.reduce((totalDuration: number, interval: DurationInterval) => {
      return totalDuration + interval.durationInSeconds;
    }, 0);
  };

  const updateAuditTrail = (
    updatedDurationIntervals: DurationInterval[],
    deletedIntervalIds: Set<number>
  ) => {
    const { durationIntervals } = currentCall;
    const durationIntervalsMap = durationIntervals.reduce(
      (map, interval) => map.set(interval.uniqueIdentifier, interval),
      new Map()
    );
    const durationIntervalsIdSet = new Set(durationIntervalsMap.keys());

    durationIntervals.forEach((interval) => {
      if (deletedIntervalIds.has(interval.id)) {
        auditTrail.push(
          new CallLogIntervalAuditTrailRecord(
            CallLogAuditTrailType.DELETE,
            interval.uniqueIdentifier,
            interval.startDate,
            interval.endDate
          )
        );
      }
    });

    updatedDurationIntervals.forEach((interval) => {
      if (
        durationIntervalsIdSet.has(interval.uniqueIdentifier) &&
        !interval.equals(durationIntervalsMap.get(interval.uniqueIdentifier))
      ) {
        auditTrail.push(
          new CallLogIntervalAuditTrailRecord(
            CallLogAuditTrailType.EDIT,
            interval.uniqueIdentifier,
            interval.startDate,
            interval.endDate,
            {
              startDate: durationIntervalsMap.get(interval.uniqueIdentifier).startDate,
              endDate: durationIntervalsMap.get(interval.uniqueIdentifier).endDate
            }
          )
        );
      } else if (!durationIntervalsIdSet.has(interval.uniqueIdentifier)) {
        auditTrail.push(
          new CallLogIntervalAuditTrailRecord(
            CallLogAuditTrailType.ADD,
            interval.uniqueIdentifier,
            interval.startDate,
            interval.endDate
          )
        );
      }
    });
  };

  const renderOtherActiveCallDialog = () => {
    return (
      <Popup
        isOpen={showPauseOtherTimersModal}
        title="Another Call Timer is Running"
        description="Please pause or end it before starting another, or use the button here to pause all other timers."
        overrideCloseAction={() => setShowPauseOtherTimersModal(false)}
        cancelText="'Pause Other Timers'"
        onCancelClicked={onClickedPauseOtherTimers}
        id={Popups.AnotherCallTimerIsRunning}
      />
    );
  };

  const renderTicketConnectModal = (): ReactNode => {
    return (
      <ConnectTicketsToCallModal
        call={currentCall}
        patient={props.patient}
        virtualPage="call logger"
        isOpen={showConnectTicketsModal}
        onSave={onConnectTicketsSave}
        onClose={toggleConnectTicketsModal}
      />
    );
  };

  const onConnectTicketsSave = (
    connectedTicketIds: number[],
    callReasons: CallReason[],
    topicsDiscussed: CallTopicDiscussed[],
    shouldResolveAll: boolean
  ) => {
    toggleConnectTicketsModal();

    callLoggingStore.updateCurrentCall({
      ticketIds: new Set<number>(connectedTicketIds),
      callReasons,
      topicsDiscussed
    });
    submitCall(false, false, shouldResolveAll);
  };

  const onClickedPauseOtherTimers = () => {
    callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
    setShowPauseOtherTimersModal(false);
  };

  const minimized = callLoggingStore.isOpenAndMinimized;

  const callViewClasses = classNames('call-view', {
    'active-call': callLoggingStore.isTimerActive(),
    extended: !minimized && isShowingPathways
  });

  const wrapperClasses = classNames('call-logging-wrapper', {
    minimized
  });
  const containerClasses = classNames('call-logging-container', {
    minimized,
    'is-preview-mode': pathwaysStore.isPreviewMode
  });

  const isCallSaveInProgress = networkLabelService.isLoading(
    API_URLS.SAVE_PATIENT_CALL(props.patient.id)
  );

  const isSummaryManuallyEdited = getIsSummaryManuallyEdited();

  return (
    <>
      {renderDeleteCallPrompt()}

      <FormProvider {...methods}>
        <div className={wrapperClasses}>
          {pathwaysStore.isPreviewMode && (
            <StyledPreviewWarning component="div" variant="badge" color="error">
              PATHWAY PREVIEW: do not submit call or save draft
            </StyledPreviewWarning>
          )}

          <div className={containerClasses} data-test-hook="callLogger">
            <CallReasonSelectionModal
              patient={props.patient}
              isOpen={isCallReasonSelectionModalOpen}
              onCancel={toggleCallReasonSelectionModal}
              onSaved={handleCallReasonsSaved}
              initialValue={currentCall.callReasons}
            />
            {pathwaysStore.pathwaysData && (
              <PathwaysView
                isOpen={isShowingPathways}
                setIsOpen={setShowPathways}
                patientId={props.patient.id}
              />
            )}
            <div className={callViewClasses} onClick={minimized ? handleToggleMinimize : null}>
              {renderHelmet()}
              {!minimized && (
                <>
                  {renderEditDurationModel()}
                  {renderOtherActiveCallDialog()}
                  {renderCallDeletedPrompt()}
                  {renderInaccurateDurationsDialog()}
                  {renderMaxCallTimeReachedDialog()}
                  {renderTicketConnectModal()}
                </>
              )}
              {isCallSaveInProgress && (
                <div className="loading-backdrop">
                  <Loading primaryColor />
                </div>
              )}

              <CallLoggingHeader
                patient={props.patient}
                minimized={minimized}
                onCloseClicked={handleCloseBtnClicked}
                onSaveDraftClicked={onSaveDraftClick}
                onToggleMinimizeClicked={handleToggleMinimize}
                isSaveInProgress={isSaveInProgress}
                isSummaryManuallyEdited={isSummaryManuallyEdited}
              />
              {!minimized && (
                <>
                  {pathwaysStore.pathwaysData ? (
                    <CallLoggingBody
                      patient={props.patient}
                      generateSmartSummaryPreference={userPreferences.generateSmartSummary}
                    />
                  ) : (
                    <Loading primaryColor />
                  )}
                </>
              )}

              <CallLoggingFooter
                isSaveInProgress={isSaveInProgress}
                onSubmitCallClicked={onSubmitCallClicked}
                onEditCallDurationClicked={openEditDurationModal}
                isOtherTimersRunning={showPauseOtherTimersModal}
                onResumeCallWithOtherTimers={() => setShowPauseOtherTimersModal(true)}
                shouldSendCallToEmr={shouldSendCallToEmr}
                onSendCallToEmrChange={(shouldSendCallToEmr) =>
                  setShouldSendCallToEmr(shouldSendCallToEmr)
                }
                setOriginalGeneratedSmartSummary={setOriginalGeneratedSmartSummary}
                isSummaryManuallyEdited={isSummaryManuallyEdited}
                userPreferences={userPreferences}
                setUserPreferences={setUserPreferences}
                hasServerError={props.hasServerError}
              />
            </div>
          </div>
          {pathwaysStore.isPreviewMode && (
            <StyledBottomPreviewWarning component="div" variant="badge" color="error">
              PATHWAY PREVIEW: do not submit call or save draft
            </StyledBottomPreviewWarning>
          )}
        </div>
      </FormProvider>
    </>
  );
};

const StyledPreviewWarning = styled(Text)`
  height: 60px;
  width: 100%;
  background-color: #ffe55b;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 100;
`;

const StyledBottomPreviewWarning = styled(StyledPreviewWarning)`
  position: fixed;
  bottom: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
`;

export const CallLogging = observer(CallContainer);
