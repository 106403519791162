import { FC, useCallback } from 'react';

import { Popups } from 'analytics/events/popup';

import Doctor from 'models/Doctor';

import { IDefaultPopupProps } from 'views/Modals/BasePopup';

import { Popup } from 'views/Modals/Popup';

interface DeactivateProps extends Omit<IDefaultPopupProps, 'title'> {
  clinician: Doctor;
}

export const DeactivatePopup: FC<DeactivateProps> = ({ clinician, ...popupProps }) => {
  const getTitleProps = useCallback(() => {
    if (!clinician) {
      return {
        title: '',
        description: ''
      };
    }

    return {
      title: `Deactivate User ${clinician.fullName}?`,
      description: (
        <div className="deactivate-subtitle">
          Any tickets currently assigned to {clinician.fullName} will return to the Work Queue as
          unassigned tickets. This user’s comment history and calls will still be visible.
        </div>
      )
    };
  }, [clinician]);

  return (
    <Popup {...popupProps} {...getTitleProps()} cancelText="Cancel" id={Popups.DeactivateUser} />
  );
};
