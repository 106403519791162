export const CANCER_DIAGNOSIS_QUESTION_ID = 'dd94fce2-5d31-472f-b159-da7da439f91b';
export const TREATMENT_TYPE_QUESTION_ID = 'ab61e5b8-ff8e-4456-883f-7162d055ed75';
export const LAST_RECEIVE_TREATMENT_DATE_QUESTION_ID = '98c2a394-5962-4aa5-81a4-53b349f74fe9';
export const COLD_SYMPTOMS_TREATMENT_TYPE_QUESTION_ID = '2383b5ba-e33a-4138-a8a7-76df65668673';

export const BASIC_QUESTION_IDS = [
  CANCER_DIAGNOSIS_QUESTION_ID,
  TREATMENT_TYPE_QUESTION_ID,
  LAST_RECEIVE_TREATMENT_DATE_QUESTION_ID,
  COLD_SYMPTOMS_TREATMENT_TYPE_QUESTION_ID
];
