import { FC, ForwardedRef, forwardRef, ReactNode } from 'react';

import { css, Radio, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

import { StyledRadioButton, StyledRadioButtonChecked } from './StyledRadioButton';

interface InternalProps extends Testable {
  id: string;
  label: ReactNode;
  name?: string;
  value?: any;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  forwardedRef?: ForwardedRef<HTMLButtonElement>;
  canToggle?: boolean;
}

export type RadioButtonExternalProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

export const InternalRadioButton: FC<InternalProps> = ({
  id,
  label,
  name,
  checked,
  disabled,
  onChange,
  value,
  testHook,
  forwardedRef,
  canToggle = false
}) => {
  const onClick = () => {
    if (canToggle || !checked) {
      onChange && onChange(!checked);
      return;
    }

    if (checked) {
      return;
    }
  };

  return (
    <StyledContainer disabled={disabled} onClick={onClick}>
      <Radio
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        data-test-hook={testHook}
        ref={forwardedRef}
        checkedIcon={<StyledRadioButtonChecked />}
        icon={<StyledRadioButton />}
        color="primary"
        size="small"
      />

      <StyledLabel variant="form-text" component="label" pl={8}>
        {label}
      </StyledLabel>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')<{ disabled?: boolean }>(
  ({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    color: ${disabled ? theme.palette.text.disabled : theme.palette.text.primary};
  `
);

const StyledLabel = styled(Typography)`
  cursor: pointer;
` as typeof Typography;

const RadioButtonWithSpacing = withSpacing(InternalRadioButton);

export const RadioButton: FC<RadioButtonExternalProps> = forwardRef<
  HTMLButtonElement,
  RadioButtonExternalProps
>((props, ref) => <RadioButtonWithSpacing {...props} forwardedRef={ref} />);
