import { FC, FocusEvent, ReactNode, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { trackHeaderMenuAnalyticsEvent } from 'analytics/events/header-menu';
import { Popups } from 'analytics/events/popup';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Link } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { publicUrlsService } from 'services/publicUrls.service';

import { isProd } from 'utils/EnvUtils';

import { showToast } from 'utils/UserMessageUtils';

import { FEATURES } from 'constants/features';

import { useHasAnalyticsFeatures } from 'hooks/useFeature';
import { useToggle } from 'hooks/useToggle';

import AccountModal from 'views/Modals/AccountModal';
import { Popup } from 'views/Modals/Popup';
import {
  QaAddLocationModal,
  QaAddTreatmentModal,
  QaAdjustPatientTimelineModal,
  QaCreateCmRowModal,
  QaCreateEmrRowModal,
  QaMobileTokenModal,
  QaProviderModal,
  QaWebEProTokenModal,
  QaAddDxModal,
  QaPatientDeceasedStatusModal,
  QaEnrollmentTokenModal
} from 'views/Modals/QaModals';

import { PathwayBuilderTab } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.types';

import QaActions from 'components/Header/QaActions';
import Icon from 'components/Icons/Icon';
import { PatientContextProvider } from 'components/Patient/PatientContextProvider';
import { Text } from 'components/UIkit/atoms/Text';

import { HEADER_TAB, useIsActiveTab } from './Header.shared';

interface Props {
  children?: ReactNode;
  label: string;
  isActive: boolean;
  testHook: string;
  sendAnalyticsEvent: () => void;
}

const HeaderDropdown: FC<Props> = ({ label, isActive, children, testHook, sendAnalyticsEvent }) => {
  const dropdown = useToggle(false);

  const wrapperClasses = classNames('header-dropdown-wrapper', {
    active: isActive || dropdown.isOpen
  });
  const dropdownClasses = classNames('header-dropdown', {
    open: dropdown.isOpen
  });

  return (
    <div
      className={wrapperClasses}
      onClick={() => {
        if (!dropdown.isOpen) {
          sendAnalyticsEvent();
        }

        dropdown.toggle();
      }}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        // if the new focused element is not part of the dropdown - close the dropdown
        if (!event.currentTarget.contains(event.relatedTarget as Node)) {
          dropdown.setIsOpen(false);
        }
      }}
    >
      <div tabIndex={0}>
        <div data-test-hook={testHook} className="d-flex align-items-center">
          {label}
          <Icon.Chevron className="ml-2" />
        </div>
        <div className={dropdownClasses}>{children}</div>
      </div>
    </div>
  );
};

export const EnrollmentDropdown: FC = () => {
  const isActiveTab = useIsActiveTab(HEADER_TAB.INVITE_TAB);

  const { settingsStore } = useStores();

  return (
    <HeaderDropdown
      label="Patient Enrollment"
      isActive={isActiveTab}
      testHook="enrollment-dropdown"
      sendAnalyticsEvent={() =>
        trackHeaderMenuAnalyticsEvent({
          action: AnalyticEventAction.Click,
          value: 'Patient Enrollment'
        })
      }
    >
      {settingsStore.hasFeature(FEATURES.INVITE_PATIENTS) && (
        <Link
          to="/invitation"
          onClick={() =>
            trackHeaderMenuAnalyticsEvent({
              action: AnalyticEventAction.Select,
              value: 'invite patient'
            })
          }
        >
          <div className="header-dropdown-item">Invite Patients</div>
        </Link>
      )}

      {settingsStore.hasFeature(FEATURES.AWAITING_ACTIVATION) && (
        <Link
          to="/activation?sort_by=invited"
          onClick={() =>
            trackHeaderMenuAnalyticsEvent({
              action: AnalyticEventAction.Select,
              value: 'awaiting activation'
            })
          }
        >
          <div className="header-dropdown-item">Awaiting Activation</div>
        </Link>
      )}
    </HeaderDropdown>
  );
};

export const AccountDropdown: FC = observer(() => {
  const isActiveTab = useIsActiveTab(HEADER_TAB.ACCOUNT_TAB);
  const { logout } = useAuth0();
  const { settingsStore, userStore, departmentsStore, qaStore } = useStores();
  const { isOpen: isDeceasedModalOpen, toggle: toggleDeceasedModal } = useToggle(false);
  const { isOpen: isEmrRowModalOpen, toggle: toggleEmrRowModal } = useToggle(false);
  const { isOpen: isCmRowModalOpen, toggle: toggleCmRowModal } = useToggle(false);
  const { isOpen: isDxModalOpen, toggle: toggleDxModal } = useToggle(false);
  const { isOpen: isLocationModalOpen, toggle: toggleLocationModal } = useToggle(false);
  const { isOpen: isMobileTokenModalOpen, toggle: toggleMobileTokenModal } = useToggle(false);
  const { isOpen: isWebTokenModalOpen, toggle: toggleWebTokenModal } = useToggle(false);
  const { isOpen: isEnrollmentTokenModalOpen, toggle: toggleEnrollmentTokenModal } =
    useToggle(false);
  const { isOpen: isAdjustTimelineModalOpen, toggle: toggleAdjustTimelineModal } = useToggle(false);
  const { isOpen: isProviderModalOpen, toggle: toggleProviderModal } = useToggle(false);
  const { isOpen: isAddTreatmentModalOpen, toggle: toggleAddTreatmentModal } = useToggle(false);
  const { isOpen: isRefreshDemoPopupOpen, toggle: toggleRefreshDemoPopup } = useToggle(false);
  const myAccount = useToggle(false);
  const hasAnalyticsFeatures = useHasAnalyticsFeatures();
  const [isQaDropdownOpen, setQaDropdownOpen] = useState(false);

  const showPracticeManagerOption =
    userStore.isManager && settingsStore.hasFeature(FEATURES.PRACTICE_MANAGER);
  const showQaActions = userStore.isAdmin && settingsStore.institutionSettings.isTest;
  const showPracticeAnalyticsOption = userStore.isManager && hasAnalyticsFeatures;

  const handleGenerateReports = async () => {
    showToast({ message: 'Refreshing reports. This will take a couple minutes.' });
    await qaStore.generateFakeReports();
    showToast({ message: 'Refresh successful. Refresh browser now.' });
  };

  const handleDemoReset = async () => {
    if (!isProd) {
      showToast({ message: 'Resetting environment...' });
      await qaStore.resetDemoEnvironment();
      window.location.reload();
    } else {
      showToast({ message: 'Reset Demo not available for this environment!' });
    }
  };

  const handleLogoutClicked = async () => {
    trackHeaderMenuAnalyticsEvent({ action: AnalyticEventAction.Select, value: 'Logout' });

    await userStore.logout();
    logout({ returnTo: publicUrlsService.getUrlWithCurrentProtocol('login') });
  };

  const closeModalAndResetDropdown = (toggle: () => void) => () => {
    toggle();
    setQaDropdownOpen(false);
  };

  const handleMyAccountMenuItemClicked = () => {
    trackHeaderMenuAnalyticsEvent({ action: AnalyticEventAction.Select, value: 'My Account' });

    if (isAccountOptionDisabled) {
      return;
    }

    myAccount.toggle();
  };

  const isAccountOptionDisabled = !Boolean(userStore.currentDoctor && departmentsStore.root);

  const accountOptionClasses = classNames('header-dropdown-item', {
    disabled: isAccountOptionDisabled
  });

  const onSave = () => {
    userStore.fetchCurrentClinicianProfile();
    myAccount.toggle();
  };

  return (
    <>
      {userStore.currentDoctor && departmentsStore.root && (
        <AccountModal
          isOpen={myAccount.isOpen}
          onCancel={myAccount.toggle}
          onSaved={onSave}
          doctor={userStore.currentDoctor}
        />
      )}

      {isRefreshDemoPopupOpen && (
        <StyledPopup
          isOpen
          id={Popups.RefreshDemo}
          title="Are you sure?"
          description={<Text color="error">The DB will be deleted!</Text>}
          action={{ actionText: 'Reset', actionCallback: handleDemoReset }}
          onCancelClicked={toggleRefreshDemoPopup}
        />
      )}

      {showQaActions && (
        <>
          <QaCreateEmrRowModal
            isOpen={isEmrRowModalOpen}
            onClose={closeModalAndResetDropdown(toggleEmrRowModal)}
            onSubmit={toggleEmrRowModal}
          />

          <QaCreateCmRowModal
            isOpen={isCmRowModalOpen}
            onClose={closeModalAndResetDropdown(toggleCmRowModal)}
            onSubmit={toggleCmRowModal}
          />

          <QaAddDxModal
            isOpen={isDxModalOpen}
            onClose={closeModalAndResetDropdown(toggleDxModal)}
            onSubmit={toggleDxModal}
          />

          <QaAddLocationModal
            isOpen={isLocationModalOpen}
            onClose={closeModalAndResetDropdown(toggleLocationModal)}
            onSubmit={toggleLocationModal}
          />

          <PatientContextProvider>
            <QaMobileTokenModal
              isOpen={isMobileTokenModalOpen}
              onClose={closeModalAndResetDropdown(toggleMobileTokenModal)}
              onSubmit={toggleMobileTokenModal}
            />
            <QaWebEProTokenModal
              isOpen={isWebTokenModalOpen}
              onClose={closeModalAndResetDropdown(toggleWebTokenModal)}
              onSubmit={toggleWebTokenModal}
            />
            <QaEnrollmentTokenModal
              isOpen={isEnrollmentTokenModalOpen}
              onClose={closeModalAndResetDropdown(toggleEnrollmentTokenModal)}
              onSubmit={toggleEnrollmentTokenModal}
            />
            <QaAdjustPatientTimelineModal
              isOpen={isAdjustTimelineModalOpen}
              onClose={closeModalAndResetDropdown(toggleAdjustTimelineModal)}
              onSubmit={toggleAdjustTimelineModal}
            />
            <QaPatientDeceasedStatusModal
              isOpen={isDeceasedModalOpen}
              onClose={closeModalAndResetDropdown(toggleDeceasedModal)}
              onSubmit={toggleDeceasedModal}
            />
          </PatientContextProvider>

          <QaProviderModal
            isOpen={isProviderModalOpen}
            onClose={closeModalAndResetDropdown(toggleProviderModal)}
            onSubmit={toggleProviderModal}
          />
          <QaAddTreatmentModal
            isOpen={isAddTreatmentModalOpen}
            onClose={closeModalAndResetDropdown(toggleAddTreatmentModal)}
            onSubmit={toggleAddTreatmentModal}
          />
        </>
      )}

      <HeaderDropdown
        label={userStore.currentDoctor.fullName}
        isActive={isActiveTab}
        testHook="account-dropdown"
        sendAnalyticsEvent={() =>
          trackHeaderMenuAnalyticsEvent({
            action: AnalyticEventAction.Click,
            value: 'Account Dropdown'
          })
        }
      >
        {isQaDropdownOpen ? (
          <QaActions
            onCloseQaActions={() => setQaDropdownOpen(false)}
            toggleCmRowModal={toggleCmRowModal}
            toggleEmrRowModal={toggleEmrRowModal}
            toggleDxModal={toggleDxModal}
            toggleLocationModal={toggleLocationModal}
            toggleMobileTokenModal={toggleMobileTokenModal}
            toggleWebEProTokenModal={toggleWebTokenModal}
            toggleEnrollmentTokenModal={toggleEnrollmentTokenModal}
            toggleAdjustPatientTimelineModal={toggleAdjustTimelineModal}
            toggleProviderModal={toggleProviderModal}
            toggleAddTreatmentModal={toggleAddTreatmentModal}
            toggleDeceasedModal={toggleDeceasedModal}
          />
        ) : (
          <>
            {settingsStore.hasFeature(FEATURES.MY_ACCOUNT) && (
              <div
                className={accountOptionClasses}
                onClick={handleMyAccountMenuItemClicked}
                data-test-hook="account-option"
              >
                My Account
              </div>
            )}

            {!isProd && (
              <div className="header-dropdown-item" onClick={handleGenerateReports}>
                Refresh Reports
              </div>
            )}

            {!isProd && (
              <div className="header-dropdown-item" onClick={toggleRefreshDemoPopup}>
                Reset Demo Env
              </div>
            )}
            {showQaActions && (
              <div
                className="header-dropdown-item"
                onClick={(event) => {
                  event.stopPropagation();
                  setQaDropdownOpen(true);
                }}
              >
                QA Helpers
              </div>
            )}
            {settingsStore.hasFeature(FEATURES.TRIAGE_RULES) && (
              <Link
                to="/alerts"
                onClick={() =>
                  trackHeaderMenuAnalyticsEvent({
                    action: AnalyticEventAction.Select,
                    value: 'triage rules'
                  })
                }
              >
                <div className="header-dropdown-item">Triage Rules</div>
              </Link>
            )}

            {showPracticeAnalyticsOption && (
              <Link
                to="/practice-analytics"
                onClick={() =>
                  trackHeaderMenuAnalyticsEvent({
                    action: AnalyticEventAction.Select,
                    value: 'practice analytics'
                  })
                }
              >
                <div className="header-dropdown-item">Practice Analytics</div>
              </Link>
            )}

            {showPracticeManagerOption && (
              <Link
                to="/practice/users"
                onClick={() =>
                  trackHeaderMenuAnalyticsEvent({
                    action: AnalyticEventAction.Select,
                    value: 'practice manager'
                  })
                }
              >
                <div className="header-dropdown-item">Practice Manager</div>
              </Link>
            )}

            {userStore.isAdmin && (
              <Link
                to={`/pathway-builder/${PathwayBuilderTab.Pathways}`}
                onClick={() =>
                  trackHeaderMenuAnalyticsEvent({
                    action: AnalyticEventAction.Select,
                    value: 'pathway builder'
                  })
                }
              >
                <div className="header-dropdown-item">Pathway Builder</div>
              </Link>
            )}

            {userStore.isAdmin && (
              <Link
                to="/patient-list"
                onClick={() =>
                  trackHeaderMenuAnalyticsEvent({
                    action: AnalyticEventAction.Select,
                    value: 'patient list'
                  })
                }
              >
                <div className="header-dropdown-item">Patient List</div>
              </Link>
            )}

            <div
              className="header-dropdown-item"
              onClick={handleLogoutClicked}
              data-test-hook="logout-option"
            >
              <span className="flex-grow-1">Logout</span>
              <i className="logout" />
            </div>
          </>
        )}
      </HeaderDropdown>
    </>
  );
});

const StyledPopup = styled(Popup)`
  text-transform: initial;
`;
