import { transformErrorUiProps } from 'errors';

import { InstitutionMetadata } from 'mobx/stores';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { ConstantsParser } from 'parsers/ConstantsParser';

import { IIcdCategory } from 'models/Icd';
import { PathwayBasicInfo, PathwaysData } from 'models/PathwayTemplates';
import RegimenTemplate from 'models/RegimenTemplate';

const httpService = new HttpService('constant');

export class ConstantsFetcher {
  static async getPathwaysData(): Promise<PathwaysData> {
    return await httpService.get({
      url: API_URLS.PATHWAY_TEMPLATES,
      transformResponse: ConstantsParser.parsePathwayData,
      transformError: transformErrorUiProps('Failed to fetch pathways data')
    });
  }

  static async getPathwaysBasicInfo(): Promise<PathwayBasicInfo[]> {
    return await httpService.get({
      url: API_URLS.PATHWAY_BASIC_INFO,
      transformError: transformErrorUiProps('Failed to fetch pathways data')
    });
  }

  static async fetchRegimenTemplates(): Promise<RegimenTemplate[]> {
    return await httpService.get({
      url: API_URLS.REGIMEN_TEMPLATES,
      transformResponse: (items: any[]) => items.map((item) => new RegimenTemplate(item)),
      transformError: transformErrorUiProps('Failed to fetch regimen data')
    });
  }

  static async getIcdCategories(searchValue: string = ''): Promise<IIcdCategory[]> {
    return await httpService.get({
      url: API_URLS.ICD_CATEGORIES(searchValue),
      transformResponse: ConstantsParser.parseIcdCategories,
      transformError: transformErrorUiProps('Failed to fetch ICD categories')
    });
  }

  static async getYesNoQuestions(): Promise<Array<{ id: number; questionText: string }>> {
    return await httpService.get({
      url: API_URLS.YES_NO_QUESTIONS,
      transformResponse: (items: any[]) =>
        items.map((unparsedQuestion) => ({
          id: unparsedQuestion.id,
          questionText: unparsedQuestion.question_text
        })),
      transformError: transformErrorUiProps('Failed to fetch yes/no questions')
    });
  }

  static async getInstitutionMetadata(): Promise<InstitutionMetadata> {
    return await httpService.get({
      url: API_URLS.INSTITUTION_METADATA,
      transformError: transformErrorUiProps('Failed to fetch institution meta data')
    });
  }
}
