// @ts-strict-ignore
import { Dispatch, FC, ReactNode, SetStateAction, useRef, useState } from 'react';

import { Box, Collapse } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { Popups } from 'analytics/events/popup';
import { trackSubmitCallSideMenuAnalyticsEvent } from 'analytics/events/submit-call-side-menu';
import { ErrorName } from 'errors';

import { isEmpty } from 'lodash/fp';

import { observer } from 'mobx-react';

import { createPortal } from 'react-dom';

import { useForm, useFormContext } from 'react-hook-form';

import { useUpdateEffect } from 'react-use';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { CallsFetcher } from 'fetchers/CallsFetcher';

import { FEATURES } from 'constants/features';

import { IncludeSummaryInEmrNote } from 'models/Call';
import { UserPreferences } from 'models/UserModel';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import { Popup } from 'views/Modals/Popup';

import CallLoggingSummary from 'views/Widgets/CallLogging/CallLoggingSummary';

import SplitButton from 'components/Buttons/SplitButton';
import Icon from 'components/Icons/Icon';
import {
  ITooltipOption,
  SimpleTooltip,
  Tooltip,
  TooltipCheckboxSelect,
  TooltipSelect
} from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { OutlinedIconSubtextButton } from 'components/UIkit/atoms/Button';

import './CallLoggingFooter.scss';
interface CallLoggingFooterProps {
  onResumeCallWithOtherTimers: () => void;
  isOtherTimersRunning: boolean;
  onEditCallDurationClicked: () => void;
  onSubmitCallClicked: () => void;
  isSaveInProgress: boolean;
  shouldSendCallToEmr: boolean;
  onSendCallToEmrChange: (shouldSendCallToEmr: boolean) => void;
  setOriginalGeneratedSmartSummary: Dispatch<SetStateAction<string>>;
  isSummaryManuallyEdited: boolean;
  userPreferences: UserPreferences;
  setUserPreferences: Dispatch<SetStateAction<UserPreferences | undefined>>;
  hasServerError: boolean;
}

export interface CallLoggingFooterFormFields {
  summary: string;
  includeSummaryInEmrNote: IncludeSummaryInEmrNote | null;
}

const Footer: FC<CallLoggingFooterProps> = ({
  onResumeCallWithOtherTimers,
  isOtherTimersRunning,
  onEditCallDurationClicked,
  onSubmitCallClicked,
  isSaveInProgress,
  shouldSendCallToEmr,
  onSendCallToEmrChange,
  setOriginalGeneratedSmartSummary,
  isSummaryManuallyEdited,
  userPreferences,
  setUserPreferences,
  hasServerError
}) => {
  const { callLoggingStore, pathwaysStore, settingsStore, userStore } = useStores();
  const { currentCall } = callLoggingStore;
  const [isCallTimerMenuOpen, setIsCallTimerMenuOpen] = useState(false);
  const [isGeneratingSmartSummary, setIsGeneratingSmartSummary] = useState(false);
  const [
    isPathwayEditedAfterGeneratingSmartSummaryPopupOpen,
    setIsPathwayEditedAfterGeneratingSmartSummaryPopupOpen
  ] = useState(false);
  const [isFailedToGenerateSmartSummaryError, setIsFailedToGenerateSmartSummaryError] =
    useState(false);
  const [lastDisplayedSummary, setLastDisplayedSummary] = useState('');
  const [
    isPathwayEditedAfterGeneratingSmartSummary,
    setIsPathwayEditedAfterGeneratingSmartSummary
  ] = useState(false);

  const { formState, setValue, reset, handleSubmit, watch } =
    useFormContext<CallLoggingFooterFormFields>();
  const { errors } = formState;
  const currentSummary = watch('summary');

  //we can't use just the currentSummary state because if the user generates a smart summary, then deletes all, the
  //"isCallLoggerSummaryVisible" constant will be false and the summary will not be visible
  const [isCallHasSummary, setIsCallHasSummary] = useState(
    Boolean(currentCall.summary || currentSummary)
  );

  const userFullNameWithCredentials = useClinicianFullNameWithCredentials(
    userStore.currentDoctor.firstName,
    userStore.currentDoctor.lastName,
    userStore.currentDoctor.credentialId
  );
  const shouldCheckPathwayEditedAfterGeneratingSmartSummaryRef = useRef<boolean>(
    !currentCall.isDraft && !hasServerError
  );

  const isCallLoggerSummaryVisible =
    (userPreferences.generateSmartSummary &&
      isCallHasSummary &&
      pathwaysStore.orderedPathwayAnswersWithoutBasicQuestions.length > 0) ||
    (userPreferences.generateSmartSummary && isFailedToGenerateSmartSummaryError);

  const isSubmitButtonVisible =
    isCallLoggerSummaryVisible ||
    pathwaysStore.orderedPathwayAnswersWithoutBasicQuestions.length === 0 ||
    !userPreferences.generateSmartSummary ||
    !settingsStore.hasFeature(FEATURES.CALL_LOGGER_SMART_SUMMARY);

  //this function is running when opening the call logger because of rendering issue, so we lost the ability of "updateEffect"
  //when we resume a draft call, we don't want this function to run, so we are using the "shouldCheckPathwayEditedAfterGeneratingSmartSummary" flag.
  useUpdateEffect(
    function checkPathwayEditedAfterGeneratingSmartSummary() {
      if (
        settingsStore.hasFeature(FEATURES.CALL_LOGGER_SMART_SUMMARY) &&
        shouldCheckPathwayEditedAfterGeneratingSmartSummaryRef.current &&
        isCallHasSummary &&
        !isPathwayEditedAfterGeneratingSmartSummary &&
        Boolean(currentSummary) &&
        userPreferences.generateSmartSummary &&
        pathwaysStore.orderedPathwayAnswersWithoutBasicQuestions.length > 0
      ) {
        setIsPathwayEditedAfterGeneratingSmartSummary(true);
        setIsPathwayEditedAfterGeneratingSmartSummaryPopupOpen(true);
      }

      if (!shouldCheckPathwayEditedAfterGeneratingSmartSummaryRef.current) {
        shouldCheckPathwayEditedAfterGeneratingSmartSummaryRef.current = true;
      }
    },
    [pathwaysStore.orderedPathwayAnswersWithoutBasicQuestions]
  );

  useUpdateEffect(
    function resetForm() {
      if (!isCallLoggerSummaryVisible && !callLoggingStore.isOpenAndMinimized) {
        reset();
        setIsFailedToGenerateSmartSummaryError(false);
        setIsCallHasSummary(false);
      }
    },
    [isCallLoggerSummaryVisible]
  );

  const methods = useForm<CallLoggingFooterFormFields>({
    defaultValues: {
      summary: currentCall.summary,
      includeSummaryInEmrNote: currentCall.includeSummaryInEmrNote
    }
  });

  const isSplitButtonDisabled = isSaveInProgress || !isEmpty(errors) || isGeneratingSmartSummary;

  const callNotStarted = currentCall.callDuration === null;

  const getSplitButtonText = () => {
    if (isGeneratingSmartSummary) {
      return 'Loading...';
    }

    if (isSaveInProgress) {
      return 'Submitting...';
    }

    if (isSubmitButtonVisible) {
      return 'Submit Call';
    }

    return 'Finish Call';
  };

  const onSplitButtonClick = async () => {
    if (splitButtonText === 'Finish Call') {
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.FinishCall,
        virtual_page: 'call logger'
      });
      setValue('includeSummaryInEmrNote', null);
      await finishCall();
      setIsCallHasSummary(true);
      return;
    }

    if (isFailedToGenerateSmartSummaryError) {
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.SubmitCall,
        virtual_page: 'call logger',
        summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
      });
      submitCallInFailedToLoadSmartSummaryErrorMode();
      return;
    }

    if (isCallLoggerSummaryVisible) {
      handleSubmit(onSubmitCallClicked, () => {
        trackActionButtonAnalyticsEvent({
          action: AnalyticEventAction.SubmitCall,
          virtual_page: 'call logger',
          value: 'missing fields',
          summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
        });
      })();
      return;
    }

    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.SubmitCall,
      virtual_page: 'call logger',
      summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
    });
    onSubmitCallClicked();
  };

  const startCallTimerIfAllowed = () => {
    if (!callLoggingStore.isGlobalCallLoggingTimerActive) {
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.ResumeCall,
        virtual_page: 'call logger'
      });

      callLoggingStore.startCallTimer({
        id: userStore.currentDoctor.id,
        name: userFullNameWithCredentials
      });
    } else {
      onResumeCallWithOtherTimers();
    }
  };

  const handleCallTimerBtnClicked = () => {
    if (callLoggingStore.callTimer) {
      // call timer is active
      callLoggingStore.endCall();
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.PauseCallTimer,
        virtual_page: 'call logger'
      });
    } else {
      setIsCallTimerMenuOpen(true);
    }
    callLoggingStore.hideReturnToCallWarnings();
  };

  const getSaveOptions = () => {
    const saveOptions: ITooltipOption[] = [
      {
        // this option is always checked and disabled
        id: 'save_canopy',
        text: 'Save in Canopy',
        disabled: true,
        isSelected: true
      },
      {
        id: 'save_emr',
        testHook: 'sendToEmr',
        text: 'Send to EMR',
        onClick: () => {
          trackSubmitCallSideMenuAnalyticsEvent({
            action: shouldSendCallToEmr ? AnalyticEventAction.Remove : AnalyticEventAction.Select,
            virtual_page: 'call logger',
            value: 'send to EMR'
          });

          onSendCallToEmrChange(!shouldSendCallToEmr);
        },
        isSelected: shouldSendCallToEmr
      },
      {
        id: 'generate_smart_summary',
        testHook: 'generateSmartSummary',
        text: 'Generate Smart Summary',
        onClick: () => {
          trackSubmitCallSideMenuAnalyticsEvent({
            action: userPreferences.generateSmartSummary
              ? AnalyticEventAction.Remove
              : AnalyticEventAction.Select,
            virtual_page: 'call logger',
            value: 'generate smart summary'
          });

          setUserPreferences({
            ...userPreferences,
            generateSmartSummary: !userPreferences.generateSmartSummary
          });
        },
        isSelected: userPreferences.generateSmartSummary,
        isVisible: settingsStore.hasFeature(FEATURES.CALL_LOGGER_SMART_SUMMARY)
      },
      {
        id: 'copy_clipboard',
        text: 'Copy Notes to Clipboard (With Connected Tickets)',
        onClick: () => {
          trackSubmitCallSideMenuAnalyticsEvent({
            action: userPreferences.copyNotesToClipboard
              ? AnalyticEventAction.Select
              : AnalyticEventAction.Remove,
            virtual_page: 'call logger',
            value: 'copy to clipboard'
          });

          setUserPreferences({
            ...userPreferences,
            copyNotesToClipboard: !userPreferences.copyNotesToClipboard
          });
        },
        isSelected: userPreferences.copyNotesToClipboard
      }
    ];
    return saveOptions;
  };

  const getPassedTimeString = () => {
    if (!currentCall.callDuration) {
      return '00:00:00';
    }
    return callLoggingStore.durationStrFormat(currentCall.callDuration);
  };

  const showSubmitCallBtn = currentCall.durationIntervals.length > 0;

  const renderCallButton = (): ReactNode => {
    const options = [
      {
        id: 'callDurationResume',
        text: <span>Resume Call</span>,
        value: 'resume',
        onClick: () => {
          startCallTimerIfAllowed();
          setIsCallTimerMenuOpen(false);
        }
      },
      {
        id: 'callDurationEdit',
        text: <span>Edit Timer</span>,
        value: 'edit',
        onClick: () => {
          onEditCallDurationClicked();
          setIsCallTimerMenuOpen(false);
        }
      }
    ];

    return (
      <div className="btn-container show">
        <Tooltip
          disabled={callLoggingStore.callTimer || isOtherTimersRunning}
          trigger={TooltipTrigger.CLICK}
          label={
            <OutlinedIconSubtextButton
              onClick={handleCallTimerBtnClicked}
              disabled={false}
              subText={getPassedTimeString()}
              testHook="callBtn"
              icon={
                callNotStarted || !callLoggingStore.callTimer ? (
                  <StyledIcon>
                    <Icon.Resume />
                  </StyledIcon>
                ) : (
                  <StyledIcon>
                    <Icon.Pause data-test-hook="pause-call-timer-icon" />
                  </StyledIcon>
                )
              }
              isActive={isCallTimerMenuOpen}
            >
              Call Timer
            </OutlinedIconSubtextButton>
          }
          controller={{
            onClickOutside: () => setIsCallTimerMenuOpen(false),
            visible: isCallTimerMenuOpen
          }}
        >
          <TooltipSelect options={options} />
        </Tooltip>
      </div>
    );
  };

  const finishCall = async () => {
    try {
      setIsGeneratingSmartSummary(true);

      const { summary, smartSummaryId } = await CallsFetcher.generateSmartSummary(
        pathwaysStore.orderedPathwayAnswersWithoutBasicQuestions
      );

      setIsPathwayEditedAfterGeneratingSmartSummary(false);

      setValue('summary', summary, { shouldValidate: true });
      setOriginalGeneratedSmartSummary(summary);
      callLoggingStore.updateCurrentCall({ smartSummaryId });
      setIsFailedToGenerateSmartSummaryError(false);
    } catch (error) {
      if (error.name === ErrorName.SummaryCharacterLimit) {
        throw error;
      }

      setIsFailedToGenerateSmartSummaryError(true);
      setValue(
        'summary',
        'Smart Summary failed to load. Click ‘Regenerate Smart Summary’ to try again.'
      );
    } finally {
      setIsGeneratingSmartSummary(false);
    }
  };

  const submitCallInFailedToLoadSmartSummaryErrorMode = () => {
    callLoggingStore.updateCurrentCall({
      summary: '',
      includeSummaryInEmrNote: null,
      isSummaryManuallyEdited: false
    });

    onSubmitCallClicked();
  };

  const regenerateSmartSummary = () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.GenerateNewSmartSummary,
      virtual_page: 'call logger'
    });
    finishCall();
  };

  const splitButtonText = getSplitButtonText();

  if (callLoggingStore.isOpenAndMinimized) {
    return null;
  }

  return (
    <>
      {createPortal(
        <Popup
          isOpen={isPathwayEditedAfterGeneratingSmartSummaryPopupOpen}
          title="Pathway Edited — Consider Regenerating Smart Summary"
          description={
            <>
              Edits to pathways are not updated in Smart Summary after it has been generated. This
              will result in an inaccurate summary. <br /> <br /> Please edit your Smart Summary
              accordingly, or click the Regenerate Smart Summary button to create a new one.
            </>
          }
          onCancelClicked={() => setIsPathwayEditedAfterGeneratingSmartSummaryPopupOpen(false)}
          cancelText="OK"
          id={Popups.PathwayEditedWarning}
        />,
        document.body
      )}

      <div className="window-footer">
        <Collapse in={isCallLoggerSummaryVisible}>
          <CallLoggingSummary
            generateSmartSummary={regenerateSmartSummary}
            isGeneratingSmartSummary={isGeneratingSmartSummary}
            isFailedToGenerateSmartSummaryError={isFailedToGenerateSmartSummaryError}
            lastDisplayedSummary={lastDisplayedSummary}
            setLastDisplayedSummary={setLastDisplayedSummary}
          />
        </Collapse>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tooltip
            controller={{
              visible:
                !callLoggingStore.isCallLoggerMinimized && callLoggingStore.wasCallForceStopped,
              onClickOutside: callLoggingStore.hideReturnToCallWarnings
            }}
            label={<div className="btns-container">{renderCallButton()}</div>}
          >
            <div className="p-3">
              The Call Timer pauses automatically when you switch to another Patient Page. Click to
              resume.
            </div>
          </Tooltip>

          <div className="save-container">
            {showSubmitCallBtn && (
              <SplitButton.Wrapper onClick={null}>
                <SplitButton.Main
                  testHook={patientPageTestSelectors.callLogger.submitButton}
                  disabled={isSplitButtonDisabled}
                  onClick={onSplitButtonClick}
                >
                  {splitButtonText}
                </SplitButton.Main>

                <SimpleTooltip
                  disabled={
                    methods.getValues('includeSummaryInEmrNote') !==
                    IncludeSummaryInEmrNote.DontOfferAgain
                  }
                  title={<Box p={16}>To enable Smart Summary later, click here.</Box>}
                >
                  <Tooltip
                    trigger={TooltipTrigger.CLICK}
                    disabled={isSplitButtonDisabled}
                    label={
                      <SplitButton.OpenEllipsis
                        onClick={() =>
                          trackSubmitCallSideMenuAnalyticsEvent({
                            action: AnalyticEventAction.Click,
                            virtual_page: 'call logger'
                          })
                        }
                        disabled={isSplitButtonDisabled}
                        testHook="saveOptions"
                      />
                    }
                  >
                    <TooltipCheckboxSelect options={getSaveOptions()} />
                  </Tooltip>
                </SimpleTooltip>
              </SplitButton.Wrapper>
            )}
          </div>
        </Box>
      </div>
    </>
  );
};

const StyledIcon = styled(Box)(
  ({ theme }) => css`
    svg {
      color: ${theme.palette.primary.main};
    }
  `
);

export const CallLoggingFooter = observer(Footer);
