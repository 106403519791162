import React, { FC, useEffect, useState } from 'react';

import { css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Popups } from 'analytics/events/popup';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FeatureIntroCodes } from 'models/Doctor';

import { Popup } from 'views/Modals/Popup';

import { Text } from 'components/UIkit/atoms/Text';

const BaseFeatureIntroPopup: FC = () => {
  const { userStore, uiStore } = useStores();
  const [isOpen, setIsOpen] = useState(
    !userStore.currentDoctor?.hasFeature(FeatureIntroCodes.CallLoggerSmartSummary)
  );

  useEffect(
    function showFeatureIntro() {
      if (isOpen) {
        uiStore.setIntro(true);
      }
    },
    [isOpen, uiStore]
  );

  const onCancelClicked = () => {
    uiStore.setIntro(false);
    setIsOpen(false);
    userStore.updateIntroFinished(FeatureIntroCodes.CallLoggerSmartSummary);
  };

  return (
    <StyledPopup
      id={Popups.CallLoggerSmartSummary}
      isOpen={isOpen}
      title="Introducing: Smart Summary"
      onCancelClicked={onCancelClicked}
      description={
        <Text variant="form-text">
          Smart Summary is designed to simplify and improve your documentation when using Pathways.
          <br />
          <br />
          When you finish a call, it will generate a summary of Pathway responses that can be easily
          reviewed by healthcare providers, decreasing the need for manual documentation.
          <br />
          <br />
          It is important to note: Smart Summary utilizes cutting-edge technology, and it is
          therefore important to review the summary before submission, to ensure accuracy.
        </Text>
      }
      cancelText="Got It"
    />
  );
};

const StyledPopup = styled(Popup)(
  ({ theme }) =>
    css`
      // for title override: remove when default popup gets refactored to DS
      .error-title {
        color: ${theme.palette.text.primary};
        width: 100%;
        text-align: center;
        font-size: ${theme.fontSizes.xLarge};
        font-weight: ${theme.fontWeights.medium};
        margin-bottom: ${theme.spacing(20)};
      }

      // remove when default popup gets refactored to DS
      .error-dialog-container {
        max-width: 500px;
        width: 490px;
      }
    `
);

export const FeatureIntroPopup = observer(BaseFeatureIntroPopup);
