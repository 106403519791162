import { FC } from 'react';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import ScheduledProtocol from 'models/ScheduledProtocol';

import { SelectOption } from 'models/SelectOption';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

import './EndProtocolOptions.scss';

interface EndProtocolOptionsProps {
  minEndDate: string;
}

export const endOptions = [
  { value: ScheduledProtocol.END_TYPE_NEVER, label: 'Never' },
  { value: ScheduledProtocol.END_TYPE_DATE, label: 'On Date' },
  { value: ScheduledProtocol.END_TYPE_OCCURRENCES, label: 'After Number of Report Requests' }
];

const EndProtocolOptions: FC<EndProtocolOptionsProps> = (props) => {
  const methods = useFormContext();
  const selectedEndOption = methods.watch('selectedEndOption');
  return (
    <div className="ending-container">
      <FormAutocomplete
        sortAlphabetically={false}
        isClearable={false}
        isRequired
        onChange={(option: SelectOption<number>) => {
          if (option.value !== ScheduledProtocol.END_TYPE_DATE) {
            methods.setValue('selectedEndDate', null);
          }
        }}
        options={endOptions}
        label="End of Protocol"
        name="selectedEndOption"
      />
      {selectedEndOption?.value !== ScheduledProtocol.END_TYPE_OCCURRENCES && (
        <RHFStyledInput
          type="date"
          min={props.minEndDate}
          disabled={selectedEndOption?.value === ScheduledProtocol.END_TYPE_NEVER}
          label="End Date"
          validate={() => {
            return (
              selectedEndOption.value !== ScheduledProtocol.END_TYPE_DATE ||
              Boolean(methods.getValues('selectedEndDate'))
            );
          }}
          error={Boolean(
            methods.formState.errors.selectedEndDate &&
              selectedEndOption.value === ScheduledProtocol.END_TYPE_DATE
          )}
          register={methods.register}
          name="selectedEndDate"
        />
      )}
      {selectedEndOption?.value === ScheduledProtocol.END_TYPE_OCCURRENCES && (
        <RHFStyledInput
          type="number"
          min={1}
          label="Report Requests"
          error={Boolean(methods.formState.errors.numberOfOccurrences)}
          isRequired={selectedEndOption?.value === ScheduledProtocol.END_TYPE_OCCURRENCES}
          register={methods.register}
          name="numberOfOccurrences"
        />
      )}
    </div>
  );
};

export default observer(EndProtocolOptions);
