// @ts-strict-ignore
import { computed, observable, makeObservable } from 'mobx';

export enum AlertTargetPopulationName {
  AllPatients = 'All Patients',
  MyPatients = 'My Patients',
  SpecificPatients = 'Specific Patients',
  SpecificCareTeams = 'Specific Care Teams'
}

export default class AlertTargetPopulation {
  @observable type: number;
  @observable patients: { patientId: number; firstName: string; lastName: string }[];
  @observable careTeamIds: number[];
  @observable patientIds: number[];

  constructor() {
    makeObservable(this);
  }

  @computed
  get name(): AlertTargetPopulationName {
    switch (this.type) {
      case 0:
        return AlertTargetPopulationName.AllPatients;
      case 1:
        return AlertTargetPopulationName.MyPatients;
      case 2:
        return AlertTargetPopulationName.SpecificPatients;
      case 3:
        return AlertTargetPopulationName.SpecificCareTeams;
      default:
        throw new Error(` no population type ${this.type}`);
    }
  }
}
