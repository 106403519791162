import { Dispatch, SetStateAction, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import {
  trackDropdownInputUsageAnalyticsEvent,
  trackInputUsageAnalyticsEvent,
  trackMultiSelectionInputUsageAnalyticsEvent,
  trackToggleButtonInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';

import {
  ControllerRenderProps,
  useController,
  useForm,
  UseFormReturn,
  useWatch
} from 'react-hook-form';
import { ActionMeta } from 'react-select';

import { Options } from 'react-select/dist/declarations/src/types';

import { ConditionType, PopulationType } from 'models/ClinicianAlert';
import { SelectOption } from 'models/SelectOption';

import { useAsyncPatientSearch } from 'hooks/useAsyncPatientSearch';

import { SymptomsAlertFormFields } from 'views/Modals/SymptomsAlertModals/SymptomsAlertModal.types';

import { SpecificPatientOption } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/SpecificPatientsAsyncMultiAutocomplete';

interface UseSymptomsAlertModalReturnType {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadOptions: (
    inputValue: string,
    callback: (options: Options<SpecificPatientOption>) => void
  ) => Promise<void>;
  distressLevelField: ControllerRenderProps<SymptomsAlertFormFields, 'distressLevel'>;
  handleConditionTypeChanged: () => void;
  handleSpecificPatientsSearched: (newValue: string) => void;
  formMethods: UseFormReturn<SymptomsAlertFormFields, any, undefined>;
  isSpecificPatientsSelectMenuOpen: boolean;
  conditionalFieldsVisibleState: {
    isSearchPatientsVisible: boolean;
    isThresholdToggleButtonVisible: boolean;
    isIssuesDropdownVisible: boolean;
    isDistressSliderVisible: boolean;
  };
  analyticsEvents: {
    trackPopulationType: (
      actionMeta: ActionMeta<SelectOption<any>>,
      eventKey: string | null
    ) => void;
    trackSpecificPatients: (
      actionMeta: ActionMeta<SelectOption<any>>,
      eventKey: string | null
    ) => void;
    trackUrgency: (actionMeta: ActionMeta<SelectOption<any>>, eventKey: string | null) => void;
    trackTriggerType: (actionMeta: ActionMeta<SelectOption<any>>, eventKey: string | null) => void;
    trackDistressLevel: () => void;
    trackCauses: (actionMeta: ActionMeta<SelectOption<any>>, eventKey: string | null) => void;
    trackThreshold: () => void;
    trackSaveAlert: () => void;
    trackCancelChanges: () => void;
  };
}

export const useSymptomsAlertModal = (
  context: 'edit rule' | 'add rule'
): UseSymptomsAlertModalReturnType => {
  const { loadOptions, handleSpecificPatientsSearched, isSpecificPatientsSelectMenuOpen } =
    useAsyncPatientSearch();
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<SymptomsAlertFormFields>({ mode: 'onChange' });
  const { control, setValue } = methods;

  const { populationType, conditionType, contactMethod, threshold } =
    useWatch<SymptomsAlertFormFields>({
      control
    });

  const handleConditionTypeChanged = () => {
    setValue('threshold', null);
    setValue('distressLevel', 0);
    setValue('selectedCauses', []);
  };

  const isSearchPatientsVisible = populationType?.value === PopulationType.SpecificPatients;
  const isThresholdToggleButtonVisible =
    conditionType?.value === ConditionType.AnyCause ||
    conditionType?.value === ConditionType.SpecificCause;
  const isIssuesDropdownVisible = conditionType?.value === ConditionType.SpecificCause;
  const isDistressSliderVisible = conditionType?.value === ConditionType.Distress;

  const { field } = useController<SymptomsAlertFormFields, 'distressLevel'>({
    name: 'distressLevel',
    control,
    rules: { required: isDistressSliderVisible }
  });

  const trackPopulationType = (
    actionMeta: ActionMeta<SelectOption<any>>,
    eventKey: string | null
  ) =>
    trackDropdownInputUsageAnalyticsEvent(
      actionMeta,
      'Rule Applies to',
      eventKey === 'Enter',
      Boolean(populationType),
      { virtual_page: context }
    );

  const trackSpecificPatients = (
    actionMeta: ActionMeta<SelectOption<any>>,
    eventKey: string | null
  ) =>
    trackMultiSelectionInputUsageAnalyticsEvent(
      actionMeta,
      'Specific Patients',
      eventKey === 'Enter',
      { virtual_page: context }
    );

  const trackUrgency = (actionMeta: ActionMeta<SelectOption<any>>, eventKey: string | null) =>
    trackDropdownInputUsageAnalyticsEvent(
      actionMeta,
      'Urgency',
      eventKey === 'Enter',
      Boolean(contactMethod),
      { virtual_page: context }
    );

  const trackTriggerType = (actionMeta: ActionMeta<SelectOption<any>>, eventKey: string | null) =>
    trackDropdownInputUsageAnalyticsEvent(
      actionMeta,
      'Trigger Type',
      eventKey === 'Enter',
      Boolean(conditionType),
      { virtual_page: context }
    );

  const trackDistressLevel = () =>
    trackInputUsageAnalyticsEvent({
      action: AnalyticEventAction.Update,
      type: 'slider',
      value: 'Distress Level',
      source: null,
      virtual_page: context
    });

  const trackCauses = (actionMeta: ActionMeta<SelectOption<any>>, eventKey: string | null) =>
    trackMultiSelectionInputUsageAnalyticsEvent(
      actionMeta,
      'Search by Issue',
      eventKey === 'Enter',
      { virtual_page: context }
    );

  const trackThreshold = () =>
    trackToggleButtonInputUsageAnalyticsEvent(threshold?.toString() || '', 'Threshold', {
      virtual_page: context
    });

  const trackSaveAlert = () =>
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Save });

  const trackCancelChanges = () =>
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Cancel });

  return {
    isLoading,
    setIsLoading,
    loadOptions,
    distressLevelField: field,
    handleConditionTypeChanged,
    handleSpecificPatientsSearched,
    formMethods: methods,
    isSpecificPatientsSelectMenuOpen,
    conditionalFieldsVisibleState: {
      isSearchPatientsVisible,
      isThresholdToggleButtonVisible,
      isIssuesDropdownVisible,
      isDistressSliderVisible
    },
    analyticsEvents: {
      trackPopulationType,
      trackSpecificPatients,
      trackUrgency,
      trackTriggerType,
      trackDistressLevel,
      trackCauses,
      trackThreshold,
      trackSaveAlert,
      trackCancelChanges
    }
  };
};
