import { FC, MouseEvent } from 'react';

import { Popups } from 'analytics/events/popup';

import { Popup } from 'views/Modals/Popup';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (event: MouseEvent<HTMLButtonElement>) => void;
  selectedTableRowsCount: number;
}

export const ClearCmTableSelectionPopup: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  selectedTableRowsCount
}) => (
  <Popup
    isOpen={isOpen}
    title="Clear Selection?"
    description={`Filtering the table will clear your selection. Are you sure you want to clear ${selectedTableRowsCount} selected patients and continue filtering?`}
    onCancelClicked={onClose}
    action={{ actionText: 'Unselect All', actionCallback: onSave }}
    id={Popups.ClearCmTableSelection}
  />
);
