// @ts-strict-ignore

import { useMemo, useState } from 'react';

import { Popups } from 'analytics/events/popup';
import { ErrorName, HttpError } from 'errors';
import { useForm } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { Col, Row } from 'reactstrap';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';
import { FEATURES } from 'constants/features';

import Episode, {
  EpisodeOption,
  EpisodeRuleset,
  episodeSelectionOptions,
  EpisodeTypes,
  getEpisodeSelectionOption
} from 'models/Episode';

import { Popup } from 'views/Modals/Popup';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

export interface PatientEpisodeFormFields {
  name: string;
  duration: number;
  type: ISelectOption<EpisodeOption>;
}

export interface CreateEditEpisodeModalProps {
  episodeId: number;
  isOpen: boolean;
  onClose: () => void;
  resetDataAfterClose: () => void;
}

export const NEW_EPISODE_ID = -1;

export const CreateEditEpisodeModal = ({
  isOpen,
  onClose,
  episodeId,
  resetDataAfterClose
}: CreateEditEpisodeModalProps) => {
  const { episodeManagementStore, settingsStore } = useStores();
  const isNewEpisode = episodeId === NEW_EPISODE_ID;

  const episode = useMemo(() => {
    return isNewEpisode ? new Episode({}) : episodeManagementStore.allEpisodesMap.get(episodeId);
  }, [episodeId, episodeManagementStore.allEpisodesMap, isNewEpisode]);

  const title = isNewEpisode ? 'New Episode' : `Edit Episode ${episode?.name}`;
  const isLoading = useNetworkLoading([API_URLS.EPISODES, API_URLS.UPDATE_EPISODE(episode?.id)]);
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const toggleDeleteWarning = () => setIsDeleteWarningOpen((current) => !current);
  const withCcm = settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT);
  const episodeOptions = episodeSelectionOptions(withCcm);
  const selectedEpisodeOption = getEpisodeSelectionOption(episode?.ruleset, episode?.type, withCcm);
  const methods = useForm<PatientEpisodeFormFields>();

  useUpdateEffect(
    function resetFields() {
      if (isOpen) {
        methods.reset();
      }
    },
    [isOpen]
  );

  const submitDelete = () => {
    episodeManagementStore.deleteEpisode(episode.id);
    setIsDeleteWarningOpen(false);
    showToast({ message: `Episode "${episode.name}" Deleted` });
    onClose();
  };

  const { register, setError, handleSubmit, watch, formState } = methods;
  const { errors } = formState;
  const { duration } = watch();

  const handleErrorResponse = (error: HttpError) => {
    let fieldName: keyof PatientEpisodeFormFields;

    if (error.name === ErrorName.DuplicateEpisodeName) {
      fieldName = 'name';
    } else if (error.name === ErrorName.InvalidEpisodeDuration) {
      fieldName = 'duration';
    }

    if (fieldName) {
      setError(fieldName, {
        type: 'server',
        message: error.ui.title
      });
    } else {
      throw error;
    }
  };

  const submitForm = async (values: PatientEpisodeFormFields) => {
    try {
      const reqValues = {
        name: values.name,
        duration: values.duration,
        ruleset:
          values.type.value === EpisodeTypes.ocm ? EpisodeRuleset.ocm : EpisodeRuleset.default,
        type: values.type.value
      };
      if (episode.id) {
        await episodeManagementStore.updateEpisode(episode.id, reqValues);
        showToast({ message: `Episode "${values.name}" Updated` });
      } else {
        await episodeManagementStore.createEpisode(reqValues);
        showToast({ message: `Episode "${values.name}" Created` });
      }
      onClose();
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const serverErrorMessages = Object.values(errors)
    .filter((e: any) => e.type === 'server')
    .map((e: any) => e.message);

  const disableSubmit = isLoading || Object.keys(errors).length > 0;

  return (
    <>
      <FormModal
        methods={methods}
        defaultValues={{
          name: episode?.name,
          duration: episode?.duration,
          type: selectedEpisodeOption
        }}
        isOpen={isOpen}
        title={title}
        confirmActions={[
          {
            onClick: handleSubmit(submitForm),
            text: isLoading ? 'Saving...' : 'Save',
            disabled: disableSubmit
          }
        ]}
        closeAction={{ onClick: onClose, disabled: false }}
        secondaryAction={{
          type: 'button',
          text: 'Delete',
          isVisible: !isNewEpisode,
          onClick: toggleDeleteWarning,
          disabled: false
        }}
        resetDataAfterClose={resetDataAfterClose}
      >
        <div className="create-edit-episode-form">
          <Row>
            <Col>
              <RHFStyledInput
                isRequired
                label="Episode Name"
                name="name"
                testHook="episode-name"
                register={register}
                error={Boolean(errors.name)}
              />
            </Col>
            <Col>
              <RHFStyledInput
                isRequired
                max={Episode.MAX_DURATION}
                min={1}
                validate={(value: number) => Number.isInteger(Number(value))}
                type="number"
                label="Episode Duration (Months)"
                name="duration"
                testHook="episode-duration"
                register={register}
                error={Boolean(errors.duration)}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl={12}>
              <FormAutocomplete
                isClearable={false}
                isRequired
                label="Episode Type"
                labelTooltip="Episode Type cannot be changed after an episode has been created, as it may
                            cause issues for patients that have already been enrolled to this episode."
                name="type"
                testHook="episode-type"
                isDisabled={!isNewEpisode}
                options={episodeOptions}
                sortAlphabetically={false}
              />
            </Col>
          </Row>
          <div className="errors-container">
            {errors.duration && duration < 1 && (
              <div className="error-messages bouncy-animation">
                Minimum episode duration is 1 month.
              </div>
            )}
            {errors.duration && duration > Episode.MAX_DURATION && (
              <div className="error-messages bouncy-animation">
                {`Maximum episode duration is ${Episode.MAX_DURATION} months.`}
              </div>
            )}
            {serverErrorMessages.length > 0 && (
              <div className="error-messages bouncy-animation">
                {serverErrorMessages.join('<br/>')}
              </div>
            )}
          </div>
        </div>
      </FormModal>
      <Popup
        isOpen={isDeleteWarningOpen}
        title={`Delete episode ${episode?.name}?`}
        description=" This action cannot be undone. It will delete all tasks inside this episode. Episode will
            not be deleted for patients who are already enrolled in this episode."
        action={{ actionText: 'Delete', actionCallback: submitDelete }}
        onCancelClicked={toggleDeleteWarning}
        id={Popups.DeleteEpisode}
      />
    </>
  );
};
