import { ComponentType, FC } from 'react';

import { Box } from '@mui/material';

export interface WithSpacingProps {
  m?: SpacingSize;
  mx?: SpacingSize;
  my?: SpacingSize;
  mr?: SpacingSize | 'auto';
  ml?: SpacingSize | 'auto';
  mt?: SpacingSize;
  mb?: SpacingSize;
  p?: SpacingSize;
  px?: SpacingSize;
  py?: SpacingSize;
  pr?: SpacingSize;
  pl?: SpacingSize;
  pt?: SpacingSize;
  pb?: SpacingSize;
  display?: 'inline-block' | 'flex' | 'block' | 'contents';
}

type SpacingSize =
  | 0
  | 2
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 68
  | 76
  | 100;
export const spacingSizeArray = [
  0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 68, 76, 100
];

//https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
export const withSpacing =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P & WithSpacingProps> =>
  ({
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    p,
    px,
    py,
    pt,
    pr,
    pb,
    pl,
    display = 'flex',
    ...componentProps
  }: WithSpacingProps) =>
    (
      <Box
        m={m}
        mx={mx}
        my={my}
        mt={mt}
        mr={mr}
        mb={mb}
        ml={ml}
        p={p}
        px={px}
        py={py}
        pt={pt}
        pr={pr}
        pb={pb}
        pl={pl}
        display={display}
      >
        <WrappedComponent {...(componentProps as P)} />
      </Box>
    );
