import { PopupCancelTrigger, PopupCancelTriggerBy } from 'views/Modals/BasePopup';

import { AnalyticEventAction, AnalyticEventName, AnalyticsService } from '../';

export enum Popups {
  ClearAllFields = 'ClearAllFields',
  DisconnectDraftAndResolve = 'DisconnectDraftAndResolve',
  DeleteTicketType = 'DeleteTicketType',
  DeleteTicketTypeSubSelect = 'DeleteTicketTypeSubSelect',
  DeleteTag = 'DeleteTag',
  ResolveConnectedTickets = 'ResolveConnectedTickets',
  DeactivateUser = 'DeactivateUser',
  DeleteAndSwitchProtocol = 'DeleteAndSwitchProtocol',
  RemoveCallbackContact = 'RemoveCallbackContact',
  DeleteTask = 'DeleteTask',
  DeleteTicket = 'DeleteTicket',
  RemoveReason = 'RemoveReason',
  PathwayUpdates = 'PathwayUpdates',
  DeleteFoodsToAvoid = 'DeleteFoodsToAvoid',
  UnsubscribeSms = 'UnsubscribeSms',
  HasActiveCmEpisode = 'HasActiveCmEpisode',
  HasOptOutOfCm = 'HasOptOutOfCm',
  MarkedAsIneligibleForCm = 'MarkedAsIneligibleForCm',
  CmEpisodeEnded = 'CmEpisodeEnded',
  DeleteEpisode = 'DeleteEpisode',
  DeleteRole = 'DeleteRole',
  DeleteTicketSubType = 'DeleteTicketSubType',
  DisableTicketCategory = 'DisableTicketCategory',
  DisplayNamesUpdate = 'DisplayNamesUpdate',
  CareTimerWillPause = 'CareTimerWillPause',
  CareTimerAutoPause = 'CareTimerAutoPause',
  DeleteCall = 'DeleteCall',
  DraftCallWasDeleted = 'DraftCallWasDeleted',
  ConfirmDuration = 'ConfirmDuration',
  MaximumDurationReached = 'MaximumDurationReached',
  AnotherCallTimerIsRunning = 'AnotherCallTimerIsRunning',
  DeleteItem = 'DeleteItem',
  TicketDeletedNotification = 'TicketDeletedNotification',
  SendBulkInvitationsViaSms = 'SendBulkInvitationsViaSms',
  ClearCmTableSelection = 'ClearCmTableSelection',
  DeleteTriageRule = 'DeleteTriageRule',
  RefreshDemo = 'RefreshDemo',
  CallLoggerSmartSummary = 'CallLoggerSmartSummary',
  ResolveAll = 'ResolveAll',
  RequestAllOverdueReports = 'RequestAllOverdueReports',
  PathwayEditedWarning = 'PathwayEditedWarning',
  GeneralError = 'GeneralError',
  InvalidPhoneOnProtocol = 'InvalidPhoneOnProtocol',
  PathwayBuilderJsonValidationError = 'PathwayBuilderJsonValidationError',
  PathwayNameAlreadyExists = 'PathwayNameAlreadyExists',
  RemovePathwayBlock = 'RemovePathwayBlock',
  RemovePathwayCategory = 'RemovePathwayCategory',
  EditPathwayQuestion = 'EditPathwayQuestion',
  DismissChangesPathwayQuestion = 'DismissChangesPathwayQuestion'
}

export const analyticsTitleMap: Record<Popups, string> = {
  [Popups.ClearAllFields]: 'Clear All Fields?',
  [Popups.DisconnectDraftAndResolve]: 'Disconnect Call Draft from Ticket & Resolve?',
  [Popups.DeleteTicketType]: 'Delete Ticket Type',
  [Popups.DeleteTicketTypeSubSelect]: 'Delete Ticket Type From Sub Dropdown',
  [Popups.DeleteTag]: 'Delete Tag',
  [Popups.ResolveConnectedTickets]: 'Resolve Connected Tickets?',
  [Popups.DeactivateUser]: 'Deactivate User',
  [Popups.DeleteAndSwitchProtocol]: 'Delete & Switch Protocols?',
  [Popups.RemoveCallbackContact]: 'Remove this callback contact?',
  [Popups.DeleteTask]: 'Delete Task',
  [Popups.DeleteTicket]: 'Delete this ticket?',
  [Popups.DeleteItem]: 'Delete this item?',
  [Popups.RemoveReason]: 'Remove this reason from suggestions?',
  [Popups.PathwayUpdates]: 'Pathway Updates',
  [Popups.DeleteFoodsToAvoid]: 'Delete Foods to Avoid Item',
  [Popups.UnsubscribeSms]: 'Unsubscribed from SMS Messages',
  [Popups.HasActiveCmEpisode]: 'Patient has Active Care Management Episode',
  [Popups.HasOptOutOfCm]: 'Patient has Opted Out of Care Management',
  [Popups.MarkedAsIneligibleForCm]: 'Patient is Marked as Ineligible for Care Management',
  [Popups.CmEpisodeEnded]: 'Patient Was in a Care Management Episode That Was Ended',
  [Popups.DeleteEpisode]: 'Delete Episode',
  [Popups.DeleteRole]: 'Delete this role?',
  [Popups.DeleteTicketSubType]: 'Delete Ticket Sub Type',
  [Popups.DisableTicketCategory]: 'Disable Ticket Category for Operators and Patients?',
  [Popups.DisplayNamesUpdate]: 'Display Name(s) for Patient App Will Be Updated',
  [Popups.CareTimerWillPause]: 'Care Timer Will Pause',
  [Popups.CareTimerAutoPause]: 'Care Timer Auto-Paused',
  [Popups.DeleteCall]: 'Delete Call',
  [Popups.DraftCallWasDeleted]:
    'This call has been deleted, but you can still save it as a new call',
  [Popups.ConfirmDuration]: 'Please Confirm Duration',
  [Popups.MaximumDurationReached]: 'Maximum Duration Reached',
  [Popups.AnotherCallTimerIsRunning]: 'Another Call Timer is Running',
  [Popups.TicketDeletedNotification]: 'Notification Leads to Deleted Ticket',
  [Popups.SendBulkInvitationsViaSms]: 'Send Bulk Invitation Via SMS',
  [Popups.ClearCmTableSelection]: 'Clear Selection?',
  [Popups.DeleteTriageRule]: 'Delete Triage Rule?',
  [Popups.RefreshDemo]: 'Are you sure?',
  [Popups.CallLoggerSmartSummary]: 'Introducing: Smart Summary',
  [Popups.ResolveAll]: 'Resolve Patient Reports',
  [Popups.RequestAllOverdueReports]: 'Request Overdue Reports',
  [Popups.PathwayEditedWarning]: 'Pathway Edited — Consider Regenerating Smart Summary',
  [Popups.GeneralError]: 'Error',
  [Popups.InvalidPhoneOnProtocol]: 'Phone Number Invalid',
  [Popups.PathwayBuilderJsonValidationError]: 'JSON Validation Error',
  [Popups.PathwayNameAlreadyExists]: 'Pathway Name Already Exists',
  [Popups.RemovePathwayBlock]: 'Remove from Pathway',
  [Popups.RemovePathwayCategory]: 'Remove Category and All Questions',
  [Popups.EditPathwayQuestion]: 'Edit Pathway Question',
  [Popups.DismissChangesPathwayQuestion]: 'Dismiss Changes to Pathway Question'
};

export const getSafeTitle = (id: Popups): string => {
  const safeTitle = analyticsTitleMap[id];

  if (!safeTitle) {
    throw new Error('no record for this popup');
  }

  return safeTitle;
};

type Action = string | AnalyticEventAction.Cancel | AnalyticEventAction.CancelIcon;

interface Properties {
  action: Action;
  value: string;
}

export const trackPopupAnalyticsEvent = ({ action, value }: Properties) =>
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.Popup,
    properties: { action, value }
  });

export const trackCancelPopupAnalyticsEvent = (
  cancelTrigger: PopupCancelTriggerBy,
  value: string,
  overrideCancelAction?: string | null
) => {
  let eventAction: Action = AnalyticEventAction.CancelIcon;

  if (cancelTrigger === PopupCancelTrigger.Button) {
    eventAction = AnalyticEventAction.Cancel;

    if (overrideCancelAction) {
      eventAction = overrideCancelAction;
    }
  }

  trackPopupAnalyticsEvent({ action: eventAction, value });
};
