// @ts-strict-ignore
import { DataMap } from 'mobx/stores';

import ClinicianAlertCondition from 'models/Conditions/ClinicianAlertCondition';
import { OperatorTicketUrgency, OperatorTicketUrgencyText } from 'models/OperatorTicket';
import ReportedCause, { CauseSeverity } from 'models/ReportedCause';
import { SymptomType } from 'models/TicketTypes';

import {
  ReportTableCellData,
  ReportTableCellDataColor,
  ReportTableIcon,
  TableReportItem,
  ReportTableSymptomSection
} from './ReportTable.model';

// cell with static content and color
const NO_SYMPTOM_CELL_DATA = {
  color: ReportTableCellDataColor.gray
} as const;

// convert severity/normalized-distress level to color
export const cellColorByLevel = (level: number): ReportTableCellDataColor => {
  const isInRange = CauseSeverity.Mild <= level && level <= CauseSeverity.Unbearable;
  if (isInRange) {
    return level;
  } else {
    return ReportTableCellDataColor.darkRed; //highest severity
  }
};

const cellTooltipByColor = (color: ReportTableCellDataColor): string => {
  // verify in range
  if (CauseSeverity.Mild <= color && color <= CauseSeverity.Unbearable) {
    return ClinicianAlertCondition.getSeverityString(color);
  } else {
    return null;
  }
};

function getSymptomCellData(report: TableReportItem, cause?: ReportedCause): ReportTableCellData {
  if (!cause) {
    return NO_SYMPTOM_CELL_DATA;
  }
  let cellData: ReportTableCellData;

  if (report.isOperatorReport) {
    const isUrgent = report.operatorReportUrgency === OperatorTicketUrgency.High;
    const urgencyLevel = isUrgent
      ? OperatorTicketUrgencyText.High
      : OperatorTicketUrgencyText.Standard;
    cellData = {
      color: isUrgent ? ReportTableCellDataColor.red : ReportTableCellDataColor.orange,
      iconContent: ReportTableIcon.phone,
      tooltip: `Reported via Triage Call as "${urgencyLevel} Urgency"`
    };
  } else {
    cellData = {
      color: cellColorByLevel(cause.severity)
    };
    cellData.tooltip = cellTooltipByColor(cellData.color);
  }
  return cellData;
}

export function addSymptomsReportData(
  symptomsSection: ReportTableSymptomSection,
  report: TableReportItem,
  symptomsMap: DataMap<SymptomType>
) {
  // convert current report causes to map
  const reportCauses: { [key: string]: ReportedCause } = {};
  report.answer?.causes?.forEach(
    (cause) => (reportCauses[symptomsMap.get(cause.causeId)?.name] = cause)
  );

  // fill current questionnaire causes
  Object.keys(symptomsSection).forEach((causeName: string) => {
    const cause = reportCauses[causeName];
    const cellData = getSymptomCellData(report, cause);
    symptomsSection[causeName].push(cellData);
  });
}
