// @ts-strict-ignore
import { FunctionComponent, ReactElement, ReactNode } from 'react';

import { countBy } from 'lodash/fp';

import { useStores } from 'mobx/hooks/useStores';

import { EpisodeMetadata } from 'mobx/stores';

import { joinStringArrayByDelimiter } from 'utils/StringUtils';

import Episode from 'models/Episode';

import { SymptomTicketUrgency } from 'models/OperatorTicket';
import Patient from 'models/Patient';

import ReportedCause, { ReportCauseChange } from 'models/ReportedCause';

import Ticket from 'models/Ticket';

import { usePatientModel } from 'components/Patient/usePatientModel';

import { TicketRowIconType } from 'components/Ticket/TicketRow/TicketRowIcon';
import { SimpleTooltip } from 'components/Tooltip';

interface TicketRowDateTooltipProps {
  children: ReactNode;
  tooltipText: string | ReactElement | ReactElement[];
  onShow?: () => void;
  onHide?: () => void;
}

export const getSymptomIconAndAlertType = (urgency: number): TicketRowIconType => {
  if (urgency === SymptomTicketUrgency.NurseReview) {
    return TicketRowIconType.SYMPTOM_REPORT_NURSE_REVIEW;
  }

  if (urgency === SymptomTicketUrgency.AttentionToday) {
    return TicketRowIconType.SYMPTOM_REPORT_ATTENTION_TODAY;
  }

  if (urgency === SymptomTicketUrgency.ImmediateAttention) {
    return TicketRowIconType.SYMPTOM_REPORT_IMMEDIATE_ATTENTION;
  }

  return TicketRowIconType.MILD;
};

export const TicketRowDateTooltip: FunctionComponent<TicketRowDateTooltipProps> = ({
  tooltipText,
  onShow,
  onHide,
  children
}) => (
  <SimpleTooltip onOpen={onShow} onClose={onHide} title={<div className="p-3">{tooltipText}</div>}>
    {children as ReactElement}
  </SimpleTooltip>
);

export const usePatientProviderLocation = (patient: Patient) => {
  const { providersStore } = useStores();

  const provider = providersStore.getProviderById(patient.providerId)?.name;
  const location = patient.location?.name;

  return joinStringArrayByDelimiter([provider, location]);
};

export const useTicketProviderLocation = (ticket: Ticket) => {
  const { providersStore, locationsStore } = useStores();
  const { getProviderById } = providersStore;
  const patient = usePatientModel(ticket.patientId);

  if (ticket.isOperatorTicket) {
    const { operatorTicket } = ticket;
    let provider = getProviderById(operatorTicket?.providerId)?.name;
    let location = locationsStore.getLocationById(operatorTicket?.locationId)?.name;

    if (!provider) {
      provider = getProviderById(patient?.providerId)?.name;
    }

    if (!location) {
      location = patient?.location?.name;
    }

    return joinStringArrayByDelimiter([provider, location]);
  }

  const provider = getProviderById(patient?.providerId)?.name;
  const location = patient?.location?.name;

  return joinStringArrayByDelimiter([provider, location]);
};

const REPORT_CAUSES_TO_STR = {
  [ReportCauseChange.NEW]: '+',
  [ReportCauseChange.UP]: '↑',
  [ReportCauseChange.DOWN]: '↓',
  [ReportCauseChange.NO_CHANGE]: '',
  [ReportCauseChange.FIRST_REPORT]: ''
};

export const getDeltaForCause = (cause: ReportedCause) => {
  return REPORT_CAUSES_TO_STR[cause.change] || '';
};

const NUMBER_OF_COLORS = 10;
export const OPERATOR_TICKETS_DESCRIPTION_MAX_LINES = 5;
export const getEpisodeColorIndex = (episode: EpisodeMetadata | Episode): number =>
  episode?.id ? (episode.id % NUMBER_OF_COLORS) + 1 : null;

export const countPatientsInTickets = (tasks: Ticket[]): number => {
  return Object.keys(countBy((task: Ticket) => task.patientId, tasks)).length;
};
