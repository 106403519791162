import { CareManagementTabNames } from 'views/Pages/CareManagement/CareManagementPage';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';


type Action = AnalyticEventAction.TabSelect;
type Value =
  | 'All Questions'
  | 'Key Questions'
  | 'Enrollment Manager'
  | 'Enrolled Patients'
  | 'Open Items'
  | 'Resolved Tickets'
  | 'Calls'
  | 'Episodes & Tasks';

type Tab = CareManagementTabNames;

interface Properties {
  action: Action;
  value: Value;
  virtual_page?: 'call logger';
  tab?: Tab;
}

export const trackTabNavigationAnalyticsEvent = ({
  action,
  value,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.TabNavigation,
    properties: { action, value, ...optionalProps }
  });
};
