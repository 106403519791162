import React from 'react';

import { Popups } from 'analytics/events/popup';
import { ErrorWithUi } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { Popup } from 'views/Modals/Popup';

export const GeneralErrorPopup = () => {
  const { uiStore } = useStores();
  const { error, errorDescription, clearError } = uiStore;
  const errorTitle = error instanceof ErrorWithUi ? error.ui.title : error?.message;

  return (
    <Popup
      id={Popups.GeneralError}
      isOpen={!!error}
      onCancelClicked={clearError}
      title={errorTitle}
      action={uiStore.action}
      description={errorDescription?.map((str, index) => (
        <div key={index}>{str}</div>
      ))}
      cancelText="OK"
    />
  );
};
