import { FC, MouseEvent, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { Popups } from 'analytics/events/popup';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { ErrorName } from 'errors';

import { observer } from 'mobx-react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { useWqSection } from 'hooks/useWqSection';

import { Popup } from 'views/Modals/Popup';
import { StyledSectionActionsContainer } from 'views/WorkQueue/WorkQueue.styled';

import { WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

import WqCollapsibleSection from 'views/WorkQueue/WqCollapsibleSection';
import { WqSectionControl } from 'views/WorkQueue/WqSectionControl';
import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import TicketReportRow from 'components/Ticket/TicketRow/TicketReportRow';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { OutlinedButton } from 'components/UIkit/atoms/Button';

const WqPatientReportsSection: FC = () => {
  const { totalPages, totalItems, items, currentPage, isSectionPageLoading } = useWqSection(
    WorkQueueSectionName.OtherPatientReports
  );
  const { ticketsStore } = useStores();
  const { openDisconnectDraftPopup } = useTicketOverviewContext();
  const { workQueueStore } = useStores();
  const [isResolveAllLoading, setIsResolveAllLoading] = useState(false);
  const [isResolveAllPopupOpen, setIsResolveAllPopupOpen] = useState(false);

  const ticketsToResolveIds = useMemo(
    () =>
      workQueueStore.sectionDataMap[WorkQueueSectionName.OtherPatientReports].items.map(
        (patientReportItem) => patientReportItem.itemData.id
      ),
    [workQueueStore.sectionDataMap]
  );

  const resolveAllTickets = async () => {
    await ticketsStore.resolveAllTickets(ticketsToResolveIds);
    workQueueStore.setSectionCurrentPage(WorkQueueSectionName.OtherPatientReports, 0);
    workQueueStore.handleSectionPageChange(WorkQueueSectionName.OtherPatientReports);
  };

  const handleResolveAllClicked = async () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.ResolveAll,
      value: workQueueStore.sectionDataMap[WorkQueueSectionName.OtherPatientReports].totalItems,
      page_number: currentPage
    });
    setIsResolveAllLoading(true);

    try {
      await resolveAllTickets();
    } catch (error: any) {
      if (error.name === ErrorName.TicketConnectedToDraft) {
        openDisconnectDraftPopup({
          ticketIds: [],
          callback: resolveAllTickets
        });
      } else throw error;
    } finally {
      setIsResolveAllLoading(false);
      setIsResolveAllPopupOpen(false);
    }
  };

  return (
    <>
      <Popup
        id={Popups.ResolveAll}
        isOpen={isResolveAllPopupOpen}
        testHook={workQueueTestSelectors.ResolveAllPatientReportsPopup}
        title={`Resolve ${ticketsToResolveIds.length} Patient Reports`}
        description="Are you sure you want to resolve all eligible reports in the current page of the “Other Patient Reports” section?"
        onCancelClicked={() => setIsResolveAllPopupOpen(false)}
        action={{
          actionCallback: handleResolveAllClicked,
          actionText: isResolveAllLoading ? 'Resolving...' : 'Resolve All',
          isLoading: isResolveAllLoading
        }}
      />

      <div className="report-section">
        <WqCollapsibleSection
          sectionName={Section.NonUrgent}
          totalItems={totalItems}
          onTrigger={(isOpen) =>
            trackSectionUsageAnalyticsEvent({
              action: isOpen ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
              value: Section.NonUrgent,
              page_number: currentPage
            })
          }
          testHook="other-reports-section"
        >
          <StyledSectionActionsContainer>
            <OutlinedButton
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setIsResolveAllPopupOpen(true);
              }}
              testHook={workQueueTestSelectors.ResolveAllPatientReportsButton}
            >
              Resolve All
            </OutlinedButton>

            {totalPages > 1 && (
              <WqSectionControl sectionName={WorkQueueSectionName.OtherPatientReports} />
            )}
          </StyledSectionActionsContainer>

          <div className="tickets-list">
            <TransitionGroup>
              {items.map(({ itemData }, index) => {
                const patient = workQueueStore.getPatientModel(itemData.patientId);

                return (
                  <CSSTransition
                    key={itemData.id}
                    classNames="connect-ticket-transition"
                    timeout={600}
                  >
                    {isSectionPageLoading ? (
                      <WqTicketPlaceholder />
                    ) : (
                      <TicketReportRow
                        ticket={itemData}
                        reports={patient!.reportsSortedBySeverity}
                        withPatientLink
                        ticketIndex={index}
                        ticketSectionCurrentPage={currentPage - 1}
                      />
                    )}
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </div>

          {totalPages > 1 && (
            <WqSectionControl sectionName={WorkQueueSectionName.OtherPatientReports} isBottom />
          )}
        </WqCollapsibleSection>
      </div>
    </>
  );
};

export default observer(WqPatientReportsSection);
