// @ts-strict-ignore

import { FC, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Popups } from 'analytics/events/popup';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { Popup } from 'views/Modals/Popup';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface AddEditTagModalProps {
  isOpen: boolean;
  tagId: number;
  onSubmit: () => void;
  onCancel: () => void;
  resetDataAfterClose: () => void;
}

interface AddTagForm {
  tagName: string;
}

const MAX_TAG_NAME_LENGTH = 100;

const AddEditTagModal: FC<AddEditTagModalProps> = ({
  isOpen,
  tagId,
  onCancel,
  onSubmit,
  resetDataAfterClose
}) => {
  const { constantsStore } = useStores();
  const tag = useMemo(() => {
    return constantsStore.tags.get(tagId);
  }, [tagId, constantsStore.tags]);

  const [isDeleteWarningOpen, setDeleteWarningOpen] = useState(false);
  const title = tag?.id ? 'Edit Tag' : 'Add Tag';

  const methods = useForm<AddTagForm>({
    shouldUnregister: true
  });

  const { register, handleSubmit, formState } = methods;
  const { errors } = formState;

  const handleSaveClicked = async (data: AddTagForm) => {
    const name = data.tagName.trim();
    if (tagId) {
      await constantsStore.updateTag({ ...tag, name });
    } else {
      await constantsStore.createTag({ name, bgColor: null });
    }
    onSubmit();
  };

  const handleDeleteClicked = async () => {
    await constantsStore.deleteTag(tagId);
    setDeleteWarningOpen(false);
    onSubmit();
  };

  const validateTag = {
    existing: (newName: string) => {
      const finalName = newName.trim().toLowerCase();
      return !constantsStore.activeTags.some(
        (existingTag) => existingTag.id !== tagId && existingTag.name.toLowerCase() === finalName
      );
    },
    empty: (newName: string) => {
      return newName.trim().length > 0;
    }
  };

  let errorMessage;
  switch (errors.tagName?.type) {
    case 'existing':
      errorMessage = 'Tag Already Exists';
      break;
    case 'empty':
      errorMessage = '';
      break;
    default:
      errorMessage = null;
  }
  return (
    <FormModal
      defaultValues={{
        tagName: tag?.name
      }}
      methods={methods}
      isOpen={isOpen}
      title={title}
      confirmActions={[{ onClick: handleSubmit(handleSaveClicked), text: 'Save', disabled: false }]}
      closeAction={{ onClick: onCancel, disabled: false }}
      secondaryAction={{
        type: 'button',
        onClick: () => setDeleteWarningOpen(true),
        isVisible: Boolean(tag?.id),
        text: 'Delete',
        disabled: false
      }}
      resetDataAfterClose={resetDataAfterClose}
    >
      <Popup
        isOpen={isDeleteWarningOpen}
        title={`Delete Tag “${tag?.name}”?`}
        description="This will permanently delete the tag for all patients who have already been assigned to it. "
        action={{ actionText: 'Delete Tag', actionCallback: handleDeleteClicked }}
        onCancelClicked={() => setDeleteWarningOpen(false)}
        id={Popups.DeleteTag}
      />

      <Box mb={40}>
        <RHFStyledInput
          name="tagName"
          register={register}
          isRequired
          maxLength={MAX_TAG_NAME_LENGTH}
          label="Tag Title"
          validate={validateTag}
          error={Boolean(errors.tagName)}
          errorMessage={errorMessage}
        />
      </Box>
    </FormModal>
  );
};

export default observer(AddEditTagModal);
