// @ts-strict-ignore
import { FC } from 'react';

import { Popups } from 'analytics/events/popup';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { StatusUpdateBody } from 'fetchers/CareManagementFetcher';
import { CmStatusEnum } from 'fetchers/responses/care-management.response';

import { CM_INELIGIBILITY, CM_OPT_OUT, EPISODE_END } from 'constants/reasons.const';

import { CmPatient } from 'models/CmPatient';

import { Popup } from 'views/Modals/Popup';
import CareManagementSelectReasonModal from 'views/Pages/CareManagement/CareManagementReasonModal/CareManagementSelectReasonModal';

import { REASON_TYPE_CM, WarningTypes } from 'views/Pages/CareManagement/CareManagementReport';

interface Props {
  patient: CmPatient;
  setActivePatient: (cmPatient: CmPatient) => void;
  warningType: WarningTypes | null;
  setWarningType: (warningType: WarningTypes | null) => void;
  setReasonType: (reasonType: REASON_TYPE_CM | null) => void;
  reasonType: REASON_TYPE_CM | null;
  eligibilityStatus: CmStatusEnum.EligibleForCcm | CmStatusEnum.EligibleForPcm;
  setEligibilityStatus: (
    eligibilityStatus: CmStatusEnum.EligibleForCcm | CmStatusEnum.EligibleForPcm
  ) => void;
  markPatientEligible: (
    cmPatient: CmPatient,
    status: CmStatusEnum.EligibleForCcm | CmStatusEnum.EligibleForPcm
  ) => Promise<void>;
  enrollPatientInEpisode: (cmPatient: CmPatient) => Promise<void>;
  getPatientId: (cmPatient: CmPatient) => Promise<number>;
  episodeId: number;
  setEpisodeId: (episodeId: number) => void;
  onUpdateStatus: () => void;
  isCmSelectReasonModalOpen: boolean;
  toggleCmSelectReasonModalOpen: () => void;
}

const reasonTypeToStatusMap = {
  [CM_INELIGIBILITY]: CmStatusEnum.Ineligible,
  [CM_OPT_OUT]: CmStatusEnum.OptedOut,
  [EPISODE_END]: CmStatusEnum.Ended
};

export const CareManagementModals: FC<Props> = ({
  patient,
  setActivePatient,
  warningType,
  setWarningType,
  reasonType,
  setReasonType,
  eligibilityStatus,
  setEligibilityStatus,
  markPatientEligible,
  enrollPatientInEpisode,
  getPatientId,
  episodeId,
  setEpisodeId,
  onUpdateStatus,
  isCmSelectReasonModalOpen,
  toggleCmSelectReasonModalOpen
}) => {
  const { careManagementReportStore } = useStores();

  const resetWarning = () => setWarningType(null);

  const handleStatusChanged = async (status: StatusUpdateBody) => {
    const patientId = await getPatientId(patient);
    await careManagementReportStore.updateStatus(patientId, status, onUpdateStatus);
    setActivePatient(null);
    setReasonType(null);
  };

  return (
    <>
      <CareManagementSelectReasonModal
        reasonType={reasonType}
        isOpen={isCmSelectReasonModalOpen}
        onClose={toggleCmSelectReasonModalOpen}
        resetDataAfterClose={() => setReasonType(null)}
        setSelectedReason={(reasonId, note) =>
          handleStatusChanged({
            status: reasonTypeToStatusMap[reasonType],
            reasonId,
            notes: note
          })
        }
      />
      <Popup
        isOpen={warningType === WarningTypes.OptOutWhenActive}
        onCancelClicked={resetWarning}
        title="Patient has Active Care Management Episode"
        description="If you proceed to opt them out of Care Management, this active episode will be ended immediately."
        action={{
          actionText: 'End & Opt out',
          actionCallback: () => {
            resetWarning();
            setReasonType(CM_OPT_OUT);
            toggleCmSelectReasonModalOpen();
          }
        }}
        cancelText="Cancel"
        id={Popups.HasActiveCmEpisode}
      />

      <Popup
        isOpen={warningType === WarningTypes.RemoveEnrollWhenOptedOut}
        onCancelClicked={resetWarning}
        title="Patient has Opted Out of Care Management"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient has opted out of Care Management with the following reason: "
              {patient?.cmStatus.reasonText}"
            </span>
            <span>Would you like to opt them back in?</span>
          </div>
        }
        action={{
          actionText: 'Opt In',
          actionCallback: () => {
            resetWarning();
            handleStatusChanged({ status: CmStatusEnum.None });
          }
        }}
        cancelText="Cancel"
        id={Popups.HasOptOutOfCm}
      />

      <Popup
        isOpen={warningType === WarningTypes.MarkIneligibleWhenOptedOut}
        onCancelClicked={resetWarning}
        title="Patient has Opted Out of Care Management"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient has opted out of Care Management with the following reason: "
              {patient?.cmStatus.reasonText}"
            </span>
            <span>Would you like to opt them back in and mark them as ineligible?</span>
          </div>
        }
        action={{
          actionText: 'Opt In, Mark Ineligible',
          actionCallback: () => {
            resetWarning();
            setReasonType(CM_INELIGIBILITY);
            toggleCmSelectReasonModalOpen();
          }
        }}
        cancelText="Cancel"
        id={Popups.HasOptOutOfCm}
      />

      <Popup
        isOpen={
          warningType === WarningTypes.MarkEligibleOrEnrollWhenIneligible ||
          warningType === WarningTypes.RemoveEnrollWhenIneligible
        }
        onCancelClicked={() => {
          resetWarning();
          if (eligibilityStatus) {
            setEligibilityStatus(null);
          }
          if (episodeId) {
            setEpisodeId(null);
          }
        }}
        title="Patient is Marked as Ineligible for Care Management"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient has been marked ineligible for Care Management for the following reason:
              "{patient?.cmStatus.reasonText}"
            </span>
            <span>Do you wish to undo this and continue?</span>
          </div>
        }
        action={{
          actionText: 'Continue',
          actionCallback: () => {
            if (warningType === WarningTypes.RemoveEnrollWhenIneligible) {
              resetWarning();
              handleStatusChanged({ status: CmStatusEnum.None });
              return;
            }
            resetWarning();
            if (eligibilityStatus) {
              markPatientEligible(patient, eligibilityStatus);
            }
            if (episodeId) {
              enrollPatientInEpisode(patient);
            }
          }
        }}
        cancelText="Cancel"
        id={Popups.MarkedAsIneligibleForCm}
      />

      <Popup
        isOpen={warningType === WarningTypes.EnrollWhenEnded}
        onCancelClicked={() => {
          resetWarning();
          setEpisodeId(null);
        }}
        title="Patient Was in a Care Management Episode That Was Ended"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient was in a Care Management episode that was ended for the following reason:
              "{patient?.cmStatus.reasonText}"
            </span>
            <span>Do you wish to enroll them in a new one, anyway?</span>
          </div>
        }
        action={{
          actionText: 'Enroll',
          actionCallback: () => {
            resetWarning();
            enrollPatientInEpisode(patient);
          }
        }}
        id={Popups.CmEpisodeEnded}
      />

      <Popup
        isOpen={warningType === WarningTypes.EnrollWhenOptedOut}
        onCancelClicked={() => {
          resetWarning();
          setEpisodeId(null);
        }}
        title="Patient Opted Out of Care Management"
        description={
          <div className="d-flex flex-column">
            <span className="mb-3">
              This patient has opted out of Care Management with the following reason: "
              {patient?.cmStatus.reasonText}"
            </span>
            <span>Would you like to opt them back in to enroll them in this episode?</span>
          </div>
        }
        action={{
          actionText: 'Opt In & Enroll',
          actionCallback: () => {
            resetWarning();
            enrollPatientInEpisode(patient);
          }
        }}
        id={Popups.HasOptOutOfCm}
      />
    </>
  );
};

export default observer(CareManagementModals);
