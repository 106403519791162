// @ts-strict-ignore
import { FC } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackEditPatientModalAnalyticsEvent } from 'analytics/events/edit-patient-modal';

import Patient from 'models/Patient';

import { useToggleWithRoute } from 'hooks/useToggle';

import EditPatientInfoModal from 'views/Modals/EditPatientInfoModal';

import Icon from 'components/Icons/Icon';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';

import { useRefreshSinglePatientData } from './PatientDetailsCard.shared';

interface Props {
  patient: Patient;
  selectedTab?: string;
}

export const PatientDetailsCardEdit: FC<Props> = ({ patient, selectedTab }) => {
  const editModal = useToggleWithRoute();
  const refreshDataForSinglePatient = useRefreshSinglePatientData(patient, selectedTab);

  const onSuccessfulUpdate = () => {
    refreshDataForSinglePatient();
    editModal.toggle();
  };

  const openEditPatientInfoModal = () => {
    editModal.toggle();
    trackEditPatientModalAnalyticsEvent({ action: AnalyticEventAction.Open });
  };

  const closeEditPatientInfoModal = () => {
    editModal.toggle();
    trackEditPatientModalAnalyticsEvent({ action: AnalyticEventAction.Cancel });
  };

  return (
    <>
      <OutlinedIconButton
        icon={<Icon.CircleEdit />}
        onClick={openEditPatientInfoModal}
        testHook="patient-details-edit"
        variant="secondary"
      >
        Edit
      </OutlinedIconButton>

      <EditPatientInfoModal
        patient={patient}
        key={patient.id}
        isOpen={editModal.isOpen}
        onEditSuccessful={onSuccessfulUpdate}
        onCancelClicked={closeEditPatientInfoModal}
      />
    </>
  );
};
