// @ts-strict-ignore

import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { Popups } from 'analytics/events/popup';
import { ErrorName, HttpError } from 'errors';
import { observer } from 'mobx-react';
import { FieldError, useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { REASON_TYPE } from 'constants/reasons.const';

import { useToggle } from 'hooks/useToggle';

import { Popup } from 'views/Modals/Popup';
import {
  MAX_REASON_LENGTH,
  useSelectedReason
} from 'views/Pages/CareManagement/CareManagementReasonModal/CareManagementSelectReasonModal';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormErrors } from 'components/Forms';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface EditReasonForm {
  reasonName: string;
}

interface Props {
  selectedReasonId: number;
  reasonType: REASON_TYPE;
  title: string;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
  reasonLabel: string;
  isOpen: boolean;
}

const EditReasonModal: FC<Props> = ({
  selectedReasonId,
  reasonType,
  title,
  onSave,
  onCancel,
  onDelete,
  reasonLabel,
  isOpen
}) => {
  const { reasonsStore } = useStores();
  const deleteModal = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);
  const reasons = reasonsStore.getReasonsByType(reasonType);
  const selectedReason = useSelectedReason(selectedReasonId, reasonType, reasons);
  const reason = selectedReason?.value;
  const methods = useForm<EditReasonForm>();
  const { setError, formState, handleSubmit, clearErrors } = methods;
  const { errors } = formState;

  const handleErrorResponse = (error: HttpError) => {
    if (error.name === ErrorName.DuplicateReason) {
      setError('reasonName', {
        type: 'server',
        message: error.ui.title
      });
    } else {
      throw error;
    }
  };

  const onSubmit = async (formData: EditReasonForm) => {
    try {
      setIsLoading(true);
      await reasonsStore.updateReason(reasonType, selectedReasonId, formData.reasonName);
      onSave();
    } catch (e) {
      handleErrorResponse(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    deleteModal.setIsOpen(false);
    onDelete();
  };

  const handleCancel = () => {
    clearErrors();
    onCancel();
  };

  const isButtonsDisabled = isLoading || selectedReason.isEditLoading;

  return (
    <>
      <FormModal
        defaultValues={{
          reasonName: reason?.title || null
        }}
        methods={methods}
        isOpen={isOpen}
        title={title}
        confirmActions={[
          {
            onClick: handleSubmit(onSubmit),
            disabled: isButtonsDisabled,
            text: isButtonsDisabled ? 'Saving...' : 'Save'
          }
        ]}
        closeAction={{ onClick: handleCancel, disabled: false }}
        secondaryAction={{
          type: 'button',
          onClick: deleteModal.toggle,
          text: 'Delete',
          disabled: isButtonsDisabled
        }}
      >
        <Box mb={40}>
          <RHFStyledInput
            isRequired
            register={methods.register}
            maxLength={MAX_REASON_LENGTH}
            error={Boolean(errors.reasonName)}
            name="reasonName"
            placeholder={reasonLabel}
            rounded
          />
        </Box>
        <FormErrors errors={errors as Record<string, FieldError>} />
      </FormModal>

      <Popup
        isOpen={deleteModal.isOpen}
        title="Delete this reason from suggestions?"
        description={`"${reason?.title}"`}
        onCancelClicked={deleteModal.toggle}
        action={{ actionText: 'Delete', actionCallback: handleDelete }}
        id={Popups.RemoveReason}
      />
    </>
  );
};

export default observer(EditReasonModal);
