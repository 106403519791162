import React, { FC, useEffect } from 'react';

import { AnalyticEventAction } from 'analytics';

import {
  getSafeTitle,
  Popups,
  trackCancelPopupAnalyticsEvent,
  trackPopupAnalyticsEvent
} from 'analytics/events/popup';

import BasePopup, { IDefaultPopupProps, PopupCancelTrigger } from 'views/Modals/BasePopup';

interface Props extends IDefaultPopupProps {
  id: Popups;
  nodeId?: string;
}

const AnalyticsPopup: FC<Props> = (props) => {
  const newTitle = getSafeTitle(props.id);

  useEffect(
    function trackPopupOpenAnalyticsEvent() {
      if (props.isOpen) {
        trackPopupAnalyticsEvent({ action: AnalyticEventAction.Open, value: newTitle });
      }
    },
    [props.isOpen, newTitle]
  );

  const properties: IDefaultPopupProps = {
    ...props,
    onCancelClicked: (cancelTrigger) => {
      trackCancelPopupAnalyticsEvent(cancelTrigger, newTitle, props.cancelText || null);
      props.onCancelClicked(cancelTrigger);
    }
  };

  if (Boolean(props.action)) {
    let action: {
      actionText: string;
      actionCallback: (event: any) => void;
      isLoading?: boolean;
    } = {
      actionText: props.action?.actionText || '',
      actionCallback: (event) => {
        trackPopupAnalyticsEvent({
          action: action?.actionText,
          value: newTitle
        });
        props.action?.actionCallback(event);
      },
      isLoading: props.action?.isLoading || false
    };

    properties.action = action;
  }

  if (Boolean(props.overrideCloseAction)) {
    properties.overrideCloseAction = () => {
      trackCancelPopupAnalyticsEvent(PopupCancelTrigger.Icon, newTitle);
      props.overrideCloseAction!(PopupCancelTrigger.Icon);
    };
  }

  return <BasePopup {...properties} />;
};

export const Popup = AnalyticsPopup;
