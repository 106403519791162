// @ts-strict-ignore
import { FunctionComponent, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { observer } from 'mobx-react';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { fromNow } from 'utils/DateUtils';

import { showToast } from 'utils/UserMessageUtils';

import { API_LABELS } from 'constants/apiUrls';

import Patient from 'models/Patient';

import { ProtocolName, ProtocolType } from 'models/ScheduledProtocol';

import { useToggleCallLogWithNav } from 'hooks/useToggleCallLog';

import RequestReportDropdown from 'views/Widgets/RequestReportDropdown';

import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';

import { OutlinedButton, OutlinedSubtextButton } from 'components/UIkit/atoms/Button';

interface Props {
  patient: Patient;
  toggleSmsModal: (shouldOpenSmsBlockedPopup: boolean, patient: Patient) => void;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

const OverdueActions: FunctionComponent<Props> = ({
  patient,
  toggleSmsModal,
  ticketIndex,
  ticketSectionCurrentPage = 0
}) => {
  const { patientPageStore } = useStores();
  const isFetchingOverdueSection = useNetworkLoading([
    API_LABELS.WQ_FETCH_OVERDUE_SECTION(patient.id)
  ]);
  const [isRequestMenuOpen, setIsRequestMenuOpen] = useState(false);
  const [isRequestingSnooze, setIsRequestingSnooze] = useState(false);
  const [isRequestingReport, setIsRequestingReport] = useState(false);
  const handleLogCallClicked = useToggleCallLogWithNav(patient.id);
  const { onRequestReport, onSnooze } = useTicketActionCallbacks();

  const getRequestDetails = () => ({
    patientId: patient.id,
    message: '',
    reportType: ProtocolType.phone,
    reportName: ProtocolName.symptom
  });

  const handlePhoneReportRequest = async () => {
    try {
      setIsRequestingReport(true);
      await patientPageStore!.sendReportRequest(patient, getRequestDetails);
      onRequestReport && onRequestReport(patient);
      showToast({ message: 'Dialing Patient Now…' });
    } finally {
      setIsRequestingReport(false);
    }
  };

  const placeCallCaption = useMemo(() => {
    if (!patient.hasPersonalRequestSent) {
      return null;
    }
    if (!patient.hasPatientCalledSinceNeededToReport) {
      return `Reminded ${fromNow(patient.lastReportRequestDate)}`;
    }
    return `Called ${fromNow(new Date(patient.lastCall?.createdAt))}`;
  }, [
    patient.hasPatientCalledSinceNeededToReport,
    patient.hasPersonalRequestSent,
    patient.lastCall?.createdAt,
    patient.lastReportRequestDate
  ]);

  const handleSnooze = async () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Snooze,
      value: 'single report',
      patient_id: patient.id,
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    setIsRequestingSnooze(true);
    try {
      await patientPageStore!.snooze(patient.id);
      onSnooze && onSnooze(patient);
    } finally {
      setIsRequestingSnooze(false);
    }
  };

  const isRequestButtonDisabled = isRequestingReport || isFetchingOverdueSection;
  const isSnoozeButtonDisabled = isRequestingSnooze || isFetchingOverdueSection;

  const requestReportAction = (
    <RequestReportDropdown
      patient={patient}
      onSmsClicked={(shouldOpenSmsBlockedPopup, isSmsOptionDisabled) => {
        toggleSmsModal(shouldOpenSmsBlockedPopup, patient);

        if (shouldOpenSmsBlockedPopup || !isSmsOptionDisabled) {
          setIsRequestMenuOpen(false);
        }
      }}
      isMobile={patient.hasMobilePhone}
      disabled={isRequestButtonDisabled}
      label={
        <OutlinedButton
          isActive={isRequestMenuOpen}
          onClick={() => {
            trackActionButtonAnalyticsEvent({
              action: AnalyticEventAction.RequestReport,
              value: 'single report',
              patient_id: patient.id,
              item_index: ticketIndex + 1,
              page_number: ticketSectionCurrentPage + 1
            });
            setIsRequestMenuOpen((prevState) => !prevState);
          }}
          disabled={isRequestButtonDisabled}
          testHook="overdue-request-button"
        >
          {isRequestButtonDisabled ? 'Sending...' : 'Request'}
        </OutlinedButton>
      }
      preferred={patient.mainScheduledProtocol && patient.scheduledProtocols[0].type}
      onPhoneClicked={handlePhoneReportRequest}
      tooltipController={{
        visible: isRequestMenuOpen,
        onClickOutside: () => setIsRequestMenuOpen(false)
      }}
    />
  );

  const shouldShowSnooze = patient.mainScheduledProtocol?.name === ProtocolName.symptom;

  const otherActions = (
    <div className="d-flex">
      {shouldShowSnooze && (
        <OutlinedSubtextButton
          onClick={(event) => {
            event.stopPropagation();
            handleSnooze();
          }}
          subText="Until Next Report"
          disabled={isSnoozeButtonDisabled}
          mr={8}
          testHook={`${patient.id}-snooze-button`}
        >
          Snooze
        </OutlinedSubtextButton>
      )}

      <OutlinedSubtextButton
        onClick={() => {
          trackActionButtonAnalyticsEvent({
            action: AnalyticEventAction.LogCall,
            value: 'single report',
            patient_id: patient.id,
            item_index: ticketIndex + 1,
            page_number: ticketSectionCurrentPage + 1
          });
          handleLogCallClicked();
        }}
        subText={placeCallCaption}
        testHook="overdueLogCallButton"
      >
        Log Call
      </OutlinedSubtextButton>
    </div>
  );

  return <>{patient.shouldSendReportReminder ? requestReportAction : otherActions}</>;
};

export default observer(OverdueActions);
